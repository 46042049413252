import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="w-full max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">Privacy Policy</CardTitle>
        </CardHeader>
        <CardContent className="prose">
          <h2 className="text-xl font-semibold mt-4">1. Introduction</h2>
          <p>
            Welcome to Flow Orchestra. This Privacy Policy explains how GAIM Network LLC ("we", "our", or "us") collects, uses, and protects your personal information when you use our business process automation app, Flow Orchestra ("the App").
          </p>

          <h2 className="text-xl font-semibold mt-4">2. Information We Collect</h2>
          <p>
            We collect information that you provide directly to us when using the App, such as your name, email address, and any other information you choose to provide.
          </p>

          <h2 className="text-xl font-semibold mt-4">3. How We Use Your Information</h2>
          <p>
            We use the information we collect to:
          </p>
          <ul className="list-disc pl-6">
            <li>Provide, maintain, and improve the App</li>
            <li>Personalize your experience</li>
            <li>Communicate with you about the App</li>
            <li>Analyze usage of the App</li>
            <li>Protect against, identify, and prevent fraud and other illegal activities</li>
          </ul>

          <h2 className="text-xl font-semibold mt-4">4. Data Sharing</h2>
          <p>
            We do not sell your personal information to third parties. We may share your information with service providers who assist us in operating the App and conducting our business.
          </p>

          <h2 className="text-xl font-semibold mt-4">5. Data Security</h2>
          <p>
            We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage.
          </p>

          <h2 className="text-xl font-semibold mt-4">6. Your Rights</h2>
          <p>
            You have the right to access, correct, or delete your personal information. To exercise these rights, please contact us at support@gaimnetwork.com.
          </p>

          <h2 className="text-xl font-semibold mt-4">7. Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
          </p>

          <h2 className="text-xl font-semibold mt-4">8. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at support@gaimnetwork.com.
          </p>
        </CardContent>
      </Card>
    </div>
  );
};

export default PrivacyPolicy;