// src/pages/FlowFormComponents/RenderFields/ManualTopicsInput.js

import React, { useState, useEffect } from "react";
import { Label } from "../../../../components/ui/label";
import { Textarea } from "../../../../components/ui/textarea";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { Tooltip } from "../../../../components/ui/tooltip";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "../../../../components/ui/collapsible";

// Import FontAwesomeIcon and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPlus,
  faTrash,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

const ManualTopicsInput = ({ topics, handleFieldChange, onStepChange }) => {
  const [localTopics, setLocalTopics] = useState(topics || []);
  const [collapsedTopics, setCollapsedTopics] = useState({});

  useEffect(() => {
    // Include topic_id in topics before passing to parent
    const topicsWithIds = localTopics.map((topic, index) => ({
      ...topic,
      topic_id: index + 1,
    }));
    handleFieldChange('courseData.topics', topicsWithIds, onStepChange);
  }, [localTopics]);

  const addTopic = () => {
    const newTopics = [
      ...localTopics,
      { topic_title: "", topic_summary: "" },
    ];
    setLocalTopics(newTopics);
  };

  const removeTopic = (index) => {
    const newTopics = [...localTopics];
    newTopics.splice(index, 1);
    setLocalTopics(newTopics);
  };

  const handleTopicChange = (index, field, value) => {
    setLocalTopics((prevTopics) => {
      // Create a new array of topics
      const newTopics = prevTopics.map((topic, i) => {
        if (i === index) {
          // Return a new topic object with the updated field
          return { ...topic, [field]: value };
        }
        return topic;
      });
      return newTopics;
    });
  };

  const toggleCollapse = (index) => {
    setCollapsedTopics({
      ...collapsedTopics,
      [index]: !collapsedTopics[index],
    });
  };

  return (
    <div className="space-y-6">
      {localTopics.map((topic, index) => {
        // Create a new topic object with the updated topic_id
        const updatedTopic = { ...topic, topic_id: index + 1 };
  
        return (
          <Collapsible
            key={index}
            open={!collapsedTopics[index]}
            className="bg-gray-200 p-4 rounded-md shadow-sm"
          >
            <CollapsibleTrigger className="flex justify-between items-center w-full py-2">
              <Label className="text-inter font-bold">
                Topic {index + 1}: ID {updatedTopic.topic_id}
              </Label>
              <div className="flex items-center space-x-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => toggleCollapse(index)}
                >
                  <FontAwesomeIcon
                    icon={collapsedTopics[index] ? faChevronDown : faChevronUp}
                  />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeTopic(index)}
                >
                  <FontAwesomeIcon icon={faTrash} className="text-red-500" />
                </Button>
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div className="space-y-4 mt-4">
                {/* Display Topic ID 
                <div>
                  <Label className="text-inter font-bold">
                    Topic ID: {updatedTopic.topic_id}
                  </Label>
                </div>*/}
                {/* Topic Title */}
                <div>
                  <Label
                    htmlFor={`topic-title-${index}`}
                    className="text-inter font-bold"
                  >
                    Topic Title
                    <Tooltip content="Enter the title of the topic">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                  <Input
                    id={`topic-title-${index}`}
                    placeholder="Enter topic title"
                    value={updatedTopic.topic_title}
                    onChange={(e) =>
                      handleTopicChange(index, 'topic_title', e.target.value)
                    }
                  />
                </div>
                {/* Topic Summary */}
                <div>
                  <Label
                    htmlFor={`topic-summary-${index}`}
                    className="text-inter font-bold"
                  >
                    Topic Summary
                    <Tooltip content="Provide a summary for the topic">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                  <Textarea
                    id={`topic-summary-${index}`}
                    placeholder="Enter topic summary"
                    value={updatedTopic.topic_summary}
                    onChange={(e) =>
                      handleTopicChange(index, 'topic_summary', e.target.value)
                    }
                  />
                </div>
                {/* Additional Fields (if any) */}
                {/* For example, Topic Description */}
                <div>
                  <Label
                    htmlFor={`topic-description-${index}`}
                    className="text-inter font-bold"
                  >
                    Topic Description
                    <Tooltip content="Provide a description for the topic">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                  <Textarea
                    id={`topic-description-${index}`}
                    placeholder="Enter topic description"
                    value={updatedTopic.topic_description || ''}
                    onChange={(e) =>
                      handleTopicChange(
                        index,
                        'topic_description',
                        e.target.value
                      )
                    }
                  />
                </div>
                {/* Include any other topic-related fields here */}
              </div>
            </CollapsibleContent>
          </Collapsible>
        );
      })}
      <Button
        type="button"
        onClick={addTopic}
        variant="outline"
        className="mt-2 bg-green-100 border-green-500 text-gray-900 hover:bg-green-200 hover:border-green-600 hover:text-green-600"
      >
        <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add Topic
      </Button>
    </div>
  );
  
  
};

export default ManualTopicsInput;
