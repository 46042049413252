import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { executionUpdated } from '../slices/executionsSlice';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Progress } from './ui/progress';
import { Card, CardContent } from './ui/card';
import { SocketContext } from '../App';

const RealTimeFlowExecution = ({ execution, isPolling, setIsPolling }) => {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepStatuses, setStepStatuses] = useState([]);

  useEffect(() => {
    if (execution && execution.stepResults) {
      setStepStatuses(execution.stepResults.map(step => ({ status: step.status, output: step.output })));
      setStatus(execution.status);
      setProgress(execution.status === 'completed' ? 100 : (execution.stepResults.filter(step => step.status === 'completed').length / execution.stepResults.length) * 100);
      setCurrentStep(execution.stepResults.findIndex(step => step.status === 'running') + 1 || execution.stepResults.length);
    }
  }, [execution]);

  useEffect(() => {
    if (!socket || !execution) {
      return;
    }

    socket.on('execution:started', (data) => {
      if (data._id === execution._id) {
        setStatus('running');
        setProgress(0);
        setCurrentStep(0);
        setStepStatuses(execution.stepResults.map(() => ({ status: 'pending' })));
        dispatch(executionUpdated({ ...data, status: 'running', progress: 0 }));
      }
    });

    socket.on('step:started', (data) => {
      if (data._id === execution._id) {
        setCurrentStep(data.stepNumber);
        setStepStatuses(prevStatuses => {
          const newStatuses = [...prevStatuses];
          newStatuses[data.stepNumber - 1] = { status: 'running' };
          return newStatuses;
        });
      }
    });

    socket.on('step:completed', (data) => {
      if (data._id === execution._id) {
        setCurrentStep(data.stepNumber);
        const newProgress = (data.stepNumber / execution.stepResults.length) * 100;
        setProgress(newProgress);
        setStepStatuses(prevStatuses => {
          const newStatuses = [...prevStatuses];
          newStatuses[data.stepNumber - 1] = { status: 'completed', output: data.output };
          return newStatuses;
        });
        dispatch(executionUpdated({ ...data, status: 'running', progress: newProgress }));
      }
    });

    socket.on('execution:completed', (data) => {
      if (data._id === execution._id) {
        setStatus('completed');
        setProgress(100);
        dispatch(executionUpdated({ ...data, status: 'completed', progress: 100 }));
        setIsPolling(false);
      }
    });

    socket.on('execution:failed', (data) => {
      if (data._id === execution._id) {
        setStatus('failed');
        setError(data.error);
        setStepStatuses(prevStatuses => {
          const newStatuses = [...prevStatuses];
          newStatuses[data.stepNumber - 1] = { status: 'failed', error: data.error };
          return newStatuses;
        });
        dispatch(executionUpdated({ ...data, status: 'failed', error: data.error }));
        setIsPolling(false);
      }
    });

    return () => {
      socket.off('execution:started');
      socket.off('step:started');
      socket.off('step:completed');
      socket.off('execution:completed');
      socket.off('execution:failed');
    };
  }, [socket, execution, dispatch, setIsPolling]);

  if (status === 'idle' || !execution) {
    return null;
  }

  return (
    <div className="my-4">
      <h2 className="text-xl font-bold mb-2">Execution Status</h2>
      <Progress value={progress} className="w-full bg-green-500" />
      <p className="mt-2 capitalize">
  Status: <span className={status === "completed" ? "font-bold text-green-500" : ""}>{status}</span>{currentStep && <span className='ml-2 blinking-text font-semibold'>{execution.flow?.steps[currentStep-1]?.name}</span>}
</p>
      <p>Current Step: {currentStep} / {execution.stepResults.length}</p>
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {/*<div className="mt-4">
        <h3 className="text-lg font-bold mb-2">Step Details</h3>
        {execution.stepResults.map((step, index) => (
          <Card key={index} className="mb-2">
            <CardContent className="p-4">
              <h4 className="font-bold">{index + 1}. {step.step.name} ({step.step.type})</h4>
              <p>Status: {stepStatuses[index]?.status || step.status}</p>
              {stepStatuses[index]?.status === 'completed' && (
                <pre className="mt-2 p-2 bg-gray-100 rounded">
                  {JSON.stringify(stepStatuses[index]?.output || step.output, null, 2)}
                </pre>
              )}
              {stepStatuses[index]?.status === 'failed' && (
                <Alert variant="destructive" className="mt-2">
                  <AlertDescription>{stepStatuses[index]?.error || step.error}</AlertDescription>
                </Alert>
              )}
            </CardContent>
          </Card>
        ))}
      </div>*/}
    </div>
  );
};

export default RealTimeFlowExecution;