import React from 'react';

const FloatingQuote = ({ quote, author, className = "" }) => {
  return (
    <div className={`p-12 px-0 lg:px-12 relative z-10 ${className}`}>
      <p className="text-lg leading-tight lg:leading-normal">{quote}</p>
      <p className="mt-2 font-semibold">{author}</p>
    </div>
  );
};

export default FloatingQuote;