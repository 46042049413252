// src/components/ui/simplified-multi-select.js

import React from 'react';
import { Badge } from "./badge";
import { X } from "lucide-react";

export const SimplifiedMultiSelect = ({ options, value, onChange, className, placeholder }) => {
  const handleChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    if (!value.includes(selectedValue)) {
      onChange([...value, selectedValue]);
    }
    e.target.value = ''; // Reset select to placeholder after selection
  };

  const handleRemove = (valueToRemove) => {
    onChange(value.filter(v => v !== valueToRemove));
  };

  const getLabel = (val) => options.find(option => option.value === val)?.label || '';

  return (
    <div className={`space-y-2 ${className}`}>
      <select
        onChange={handleChange}
        className="w-full p-2 border rounded"
        value=""
      >
        <option value="" disabled>{placeholder || "Select options"}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="flex flex-wrap gap-2">
        {value.map((val) => (
          <Badge key={val} variant="secondary" className="flex items-center gap-1">
            {getLabel(val)}
            <X 
              className="h-3 w-3 cursor-pointer" 
              onClick={() => handleRemove(val)}
            />
          </Badge>
        ))}
      </div>
    </div>
  );
};