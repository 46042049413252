import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";

export const Label = ({ children, tooltip }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <label className="block text-sm font-semibold text-gray-700 mb-1 cursor-help">
          {children}
        </label>
      </TooltipTrigger>
      <TooltipContent>
        <p>{tooltip}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export const FormHeader = ({ title, onClose }) => (
  <div className="flex justify-between items-center mb-2 lg:mb-6">
    <h3 className="text-lg font-semibold">{title}</h3>
    <Button onClick={onClose} variant="ghost" size="sm">
      <FontAwesomeIcon icon={faTimes} />
    </Button>
  </div>
);