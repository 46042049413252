//src/pages/FlowFormComponents/stepTypes.js

export const GenerateStepType = {
  API_CALL: "apiCall",
  TRANSFORMATION: "transformation",
  CONDITIONAL: "conditional",
  LOOP: "loop",
  AI_REQUEST: "aiRequest",
  AI_CHECK_AND_IMPROVE: "aiCheckAndImprove",
  AI_CONDITIONAL_CHECKER: "aiConditionalChecker",
  GENERATE_AI_IMAGE: "generateAIImage",
  ADD_CONTEXT: "addContext",
  FETCH_WORDPRESS_DETAILS: "executeFetchWordpressDetailsStep",
  CREATE_WORDPRESS_DETAILS: "executeCreateWordpressDetailsStep",
};

export const ActionStepType = {
  POST_TO_WORDPRESS: "postToWordpress",
  SAVE_ASSET: "saveAsset",
  POST_TO_INSTAGRAM: "postToInstagram",
  POST_PRODUCT_TO_WORDPRESS: "postProductToWordpress",
  POST_COURSE_TO_TUTORLMS: "postCourseToTutorLMS",
  UPLOAD_TEXT_FILE: "uploadTextFile",
  UPLOAD_PDF_FILE: "uploadPdfFile",
  GET_RECENT_ASSETS: "getRecentAssets",
};

export const getStepInstructions = (stepType) => {
  const outputKeyInstructions =
    "Provide an output key to store this step's result. This key can be used to access the step's output in subsequent steps.";

  switch (stepType) {
    case GenerateStepType.API_CALL:
      return `Make an HTTP request to an external API. Specify the URL, method (GET, POST, PUT, DELETE), headers, and body of the request.`;
    case GenerateStepType.TRANSFORMATION:
      return `Transform data using JavaScript code. You have access to the 'context' object, which contains results from previous steps. Write a function that takes 'context' as an argument and returns the transformed data.`;
    case GenerateStepType.CONDITIONAL:
      return `Execute different steps based on a condition. Specify a condition using JavaScript (returning true or false), and provide step numbers for true and false cases. The condition has access to the 'context' object.`;
    case GenerateStepType.LOOP:
      return `Repeat a series of steps a specified number of times or while a condition is true. For conditional loops, specify a condition using JavaScript (returning true or false) and the step number to jump to if the condition is true. The loop has access to the 'context' object.`;
    case GenerateStepType.GENERATE_AI_IMAGE:
      return `Generate an AI image using DALL-E. Specify the image prompt and size.`;
    case GenerateStepType.ADD_CONTEXT:
      return `Add additional context to the conversation thread. This context will be available for use in subsequent steps. Max characters: 5000.`;
    case GenerateStepType.AI_REQUEST:
      return `Send a prompt to a selected AI assistant and receive a response. Max characters: 5000.`;
    case GenerateStepType.AI_CHECK_AND_IMPROVE:
      return `This step checks the response from the previous step to ensure its correctness and make any necessary improvements. It uses an AI assistant to review and enhance the content.`;
    case GenerateStepType.AI_CONDITIONAL_CHECKER:
      return `Analyze the conversation thread to determine if the most recent response achieves the requested result. Respond with a JSON object containing an 'approved' key with a boolean value.`;
    case ActionStepType.SAVE_ASSET:
      return `Save an asset to the database. Choose the asset type and provide the necessary data.`;
    case ActionStepType.POST_TO_WORDPRESS:
      return `Post content to a WordPress site. Specify the WordPress site URL, post title, content, and other metadata like categories and tags.`;
    case ActionStepType.POST_PRODUCT_TO_WORDPRESS:
      return `Post a product to a WordPress site. Specify the WordPress site URL, product title, content, and other metadata like categories and tags.`;
    case ActionStepType.POST_TO_INSTAGRAM:
      return `Post content to Instagram. Specify the image or video file, caption, and other metadata like hashtags and mentions.`;
    case GenerateStepType.FETCH_WORDPRESS_DETAILS:
      return `Fetch details from a WordPress site. Specify the WordPress site URL, asset type, and which details to fetch (categories, tags, authors/instructors).`;
    case GenerateStepType.CREATE_WORDPRESS_DETAILS:
      return `Create new categories and/or tags on a WordPress site. Specify the WordPress site URL, asset type, and provide comma-separated lists of categories and tags to create.`;
    case ActionStepType.UPLOAD_TEXT_FILE:
      return `Upload a text file to the database and get the URL. Use a placeholder, type, or paste in the text content to be saved.`;
    case ActionStepType.UPLOAD_PDF_FILE:
      return `Upload a PDF file to the database and get the URL. Use a placeholder, type, or paste in the PDF content to be saved.`;
    case ActionStepType.POST_COURSE_TO_TUTORLMS:
      return `Post a course to TutorLMS. Specify the course title, content, and other metadata like categories and tags.`;
    case ActionStepType.GET_RECENT_ASSETS:
      return `Fetch recent assets of a specific type. Choose the asset type and the number of records to fetch.`;
    default:
      return "Instructions not available for this step type.";
  }
};
