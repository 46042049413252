import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Avatar, AvatarFallback } from "../../components/ui/avatar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faComment, faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { updateChatTitle } from '../../slices/chatSlice';
import { updateTitle } from '../../services/api';
import { toast } from "../../components/ui/use-toast";
import AssistantSelector from './AssistantSelector';
import { Menu, PencilIcon, MessageSquare } from "lucide-react";

const ChatHeader = ({
  currentChat,
  handleNewChat,
  selectedAssistant,
  handleSwitchAssistant,
  chatTitle,
  setChatTitle,
  assistants,
  toggleSidebar
}) => {
  const dispatch = useDispatch();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const getSelectedAssistant = () => assistants.find(a => a._id === selectedAssistant);

  const handleEditTitle = () => {
    setIsEditingTitle(true);
    setEditedTitle(currentChat.title);
  };

  const handleSaveTitle = async () => {
    if (editedTitle.trim() !== '') {
      try {
        await updateTitle(currentChat._id, editedTitle);
        dispatch(updateChatTitle({ chatId: currentChat._id, title: editedTitle }));
        setIsEditingTitle(false);
        toast({
          title: "Title updated",
          description: "The chat title has been successfully updated.",
          duration: 3000,
        });
      } catch (error) {
        console.error('Failed to update title:', error);
        toast({
          title: "Error",
          description: "Failed to update the chat title. Please try again.",
          variant: "destructive",
          duration: 3000,
        });
      }
    }
  };

  return (
    <div className="p-2 lg:p-4 border-b w-full lg:mx-0">
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center space-y-2 lg:space-y-0 lg:space-x-4">
        <div className="flex w-full lg:w-1/4 space-x-2">
        {currentChat && (
          <Button onClick={handleNewChat} variant="outline" size="sm" className="w-fit">
            New Chat
          </Button>
        )}
        </div>

        <div className="w-full lg:w-1/3">
          <AssistantSelector
            selectedAssistant={selectedAssistant}
            onAssistantChange={handleSwitchAssistant}
            assistants={assistants}
          />
        </div>

        {currentChat ? (
          <div className="w-full lg:w-1/3 flex items-center justify-between lg:justify-end">
            {isEditingTitle ? (
              <div className="flex w-full items-center space-x-2">
                <Input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  className="flex-1"
                />
                <Button onClick={handleSaveTitle} variant="ghost" size="icon" className="h-8 w-8 flex-shrink-0">
                  <FontAwesomeIcon icon={faSave} className="text-green-500" />
                </Button>
              </div>
            ) : (
              <div className="flex w-full items-center justify-between lg:justify-end space-x-2">
                <h2 className="text-sm lg:text-lg font-semibold truncate">{currentChat.title}</h2>
                <Button onClick={handleEditTitle} variant="ghost" size="icon" className="h-8 w-8 flex-shrink-0">
                  <FontAwesomeIcon icon={faPencilAlt} className="text-gray-500" />
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full lg:w-1/3 flex items-center space-x-2">
            <Avatar className="w-8 h-8 flex-shrink-0 flex items-center justify-center">
              <AvatarFallback className="bg-gray-900">
                <FontAwesomeIcon icon={faComment} className="text-white" />
              </AvatarFallback>
            </Avatar>
            <Input
              type="text"
              placeholder="Enter chat title"
              value={chatTitle}
              onChange={(e) => setChatTitle(e.target.value)}
              className="flex-1"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatHeader;