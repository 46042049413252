import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFlowById } from '../slices/flowsSlice';
import { startExecution, fetchExecutionStatus, clearCurrentExecution } from '../slices/executionsSlice';
import { Card, CardHeader, CardContent } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import { useToast } from "../components/ui/use-toast";

const FlowExecution = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { toast } = useToast();
  const flow = useSelector(state => state.flows.flows.find(f => f.id === id));
  const execution = useSelector(state => state.executions.currentExecution);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!flow) {
      dispatch(fetchFlowById(id));
    }
    // Clear current execution when component unmounts
    return () => dispatch(clearCurrentExecution());
  }, [dispatch, id, flow]);

  useEffect(() => {
    let interval;
    if (execution && execution.status === 'running') {
      interval = setInterval(() => {
        dispatch(fetchExecutionStatus(execution.id));
      }, 5000); // Poll every 5 seconds
    }
    return () => clearInterval(interval);
  }, [dispatch, execution]);

  const handleExecute = async () => {
    try {
      await dispatch(startExecution(id)).unwrap();
      toast({
        title: "Success",
        description: "Flow execution started",
        variant: "default",
      });
    } catch (err) {
      setError(err.message || 'An error occurred while starting the flow execution.');
      toast({
        title: "Error",
        description: "Failed to start flow execution",
        variant: "destructive",
      });
    }
  };

  /*if (!flow) {
    return <div>Loading...</div>;
  }*/

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Execute Flow: {flow.name}</h1>
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <Card>
        <CardHeader>Flow Execution</CardHeader>
        <CardContent>
          <Button onClick={handleExecute} disabled={execution && execution.status === 'running'}>
            {execution && execution.status === 'running' ? 'Executing...' : 'Execute Flow'}
          </Button>
          {execution && (
            <div className="mt-4">
              <h2 className="text-xl font-semibold">Execution Status: {execution.status}</h2>
              {execution.stepResults && execution.stepResults.map((step, index) => (
                <Card key={index} className="mt-2">
                  <CardContent>
                    <p>Step {index + 1}: {step.status}</p>
                    {step.output && <pre className="mt-2 bg-gray-100 p-2 rounded">{JSON.stringify(step.output, null, 2)}</pre>}
                    {step.error && <p className="text-red-500 mt-2">Error: {step.error}</p>}
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default FlowExecution;