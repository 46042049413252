import React from 'react';
import { Folder, Tag, Layers, Hash } from 'lucide-react';
import { Card, CardContent } from "../../../components/ui/card";
import { Badge } from "../../../components/ui/badge";

const FlowCard = ({ flow, isSelected, onClick }) => {
  return (
    <Card
      className={`cursor-pointer transition-all duration-200 hover:shadow-md ${
        isSelected ? 'border-blue-500 shadow-lg' : ''
      }`}
      onClick={() => onClick(flow._id)}
    >
      <CardContent className="pt-4">
        <h4 className="font-semibold text-lg mb-1">{flow.name}</h4>
        {flow.flowCategory && (
          <div className="flex items-center mb-2 text-sm text-gray-500">
            <Folder className="w-3 h-3 mr-1" />
            <span>{flow.flowCategory}</span>
          </div>
        )}
        <p className="text-sm text-gray-600 mb-3">
          {flow.description}
        </p>
        {flow.tags && flow.tags.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-3">
            {flow.tags.map((tag, index) => (
              <Badge
                key={index}
                variant="secondary"
                className="text-xs"
              >
                <Tag className="w-3 h-3 mr-1" />
                {tag}
              </Badge>
            ))}
          </div>
        )}
        <div className="grid grid-cols-2 gap-2 text-xs text-gray-500">
          <div className="flex items-center">
            <Layers className="w-3 h-3 mr-1" />
            <span>{(flow.steps && flow.steps.length) || 0} steps</span>
          </div>
          <div className="flex items-center">
            <Hash className="w-3 h-3 mr-1" />
            <span>{(flow.tags && flow.tags.length) || 0} tags</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default FlowCard;