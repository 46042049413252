import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { joinWaitlist } from "../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import NavigationDots from "./LoginComponents/NavigationDots";
import PricingSection from "./LoginComponents/PricingSection";
import FAQSection from "./LoginComponents/FAQSection";
import FloatingQuote from "./LoginComponents/FloatingQuote";
import ConnectSection from './LoginComponents/ConnectSection';
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [waitlistEmail, setWaitlistEmail] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [isWaitlistConfirmed, setIsWaitlistConfirmed] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isWaitlistConfirmed) {
      const videoOverlay = document.getElementById("waitlist-video-wrapper");

      if (videoOverlay) {
        // Set up fade-in effect
        videoOverlay.style.opacity = "0";
        videoOverlay.style.transition = "opacity 1s ease-in";

        // Timeout for fade-in to start immediately
        setTimeout(() => {
          videoOverlay.style.opacity = "1";
        }, 50); // Small delay to trigger CSS transition
      }
    }
  }, [isWaitlistConfirmed]);

  useEffect(() => {
    if (isWaitlistConfirmed) {
      const timer = setTimeout(() => {
        // Fade out video and fade in confirmation box
        const videoOverlay = document.getElementById("waitlist-video-wrapper");
        const confirmationBox = document.getElementById(
          "waitlist-confirmation-box"
        );

        if (videoOverlay) {
          videoOverlay.style.transition = "opacity 1s ease-out";
          videoOverlay.style.opacity = "0";
        }

        if (confirmationBox) {
          confirmationBox.style.transition = "opacity 1s ease-in";
          confirmationBox.style.opacity = "1";
        }
      }, 8500); // 10 seconds

      return () => clearTimeout(timer);
    }
  }, [isWaitlistConfirmed]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await dispatch(login({ email, password })).unwrap();
      navigate("/dashboard");
    } catch (err) {
      setError(err.message || "An error occurred");
    }
  };

  const handleWaitlistSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await joinWaitlist(waitlistEmail);
      setIsWaitlistConfirmed(true); // Set confirmation state
    } catch (err) {
      setError(err.message || "An error occurred while joining the waitlist");
    }
  };

  const handleVideoClick = () => {
    const waitlistForm = document.getElementById("waitlist-form");
    if (waitlistForm) {
      let blinkCount = 0;
      const interval = setInterval(() => {
        if (blinkCount < 4) {
          waitlistForm.classList.toggle("border-4");
          waitlistForm.classList.toggle("border-cyan-500");
          blinkCount++;
        } else {
          clearInterval(interval);
        }
      }, 200);
    }
  };

  const sections = ["home", "pricing", "faq", "connect"];

  return (
    <div className="relative">
      <div className="hidden lg:block">
        <NavigationDots sections={sections} />
      </div>

      {/* Main Content */}
      <div className="overflow-y-auto scroll-smooth h-screen">
        {/* Login Section */}
        <section
          data-section-index="0"
          className="min-h-screen relative"
        >
          <div className="flex flex-col lg:flex-row min-h-screen w-full">
            {/* Left side - Dark section */}
            <div className="flex flex-col lg:w-1/2 bg-black text-white justify-between relative h-80 md:h-screen">
              <div className="absolute inset-0 bg-black p-4 pt-12 lg:p-0">
                <video
                  className="w-full h-full object-contain"
                  src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/FO%20Main%20Page%20Vid%202.mp4"
                  autoPlay
                  loop
                  muted
                  playsInline
                  onClick={handleVideoClick} // Handles click on video
                />
              </div>

              <div className="p-4 lg:p-12 relative z-10">
                <div className="flex items-center space-x-2">
                  <img
                    src="/FlowOrchestraIcon5.png"
                    alt="Flow Orchestra Icon"
                    className="w-8 h-8 bg-white rounded-md"
                  />
                  <span className="text-xl font-semibold">Flow Orchestra</span>
                </div>
              </div>
              <FloatingQuote
                quote="The whole is greater than the sum of its parts."
                author="Aristotle"
                className="hidden lg:block text-white"
              />
            </div>

            {/* Right side - Form container */}
            <div className="flex-1 flex flex-col justify-between px-4 py-12 lg:py-0 sm:px-6 lg:px-20 xl:px-24">
              <div className="flex-1 flex flex-col justify-center">
                {/* Waitlist Form or Login Form with Fade Effect */}
                <div
                  className={`w-full max-w-sm transition-opacity duration-1000 ${
                    isWaitlistConfirmed ? "opacity-0 h-0" : "opacity-100"
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <h2 className="text-3xl font-bold text-inter">
                      {isLogin ? "Sign in" : "Join the Waitlist"}
                    </h2>
                    <button
                      onClick={() => setIsLogin(!isLogin)}
                      className="text-sm text-gray-600 hover:text-gray-900"
                    >
                      {isLogin ? "Join the Waitlist" : "Sign in"}
                    </button>
                  </div>
                  {isLogin ? (
                    <>
                      <p className="mt-2 text-sm text-gray-600">
                        Enter your credentials below to access your account
                      </p>
                      <form
                        onSubmit={handleLoginSubmit}
                        className="mt-8 space-y-6"
                      >
                        <div className="space-y-4">
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email address"
                            required
                            className="w-full"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <Input
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Password"
                            required
                            className="w-full"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <Button
                            type="submit"
                            className="w-full bg-black text-white hover:bg-gray-800"
                          >
                            Sign In
                          </Button>
                        </div>
                        {error && (
                          <div className="text-red-500 text-sm mt-2">
                            {error}
                          </div>
                        )}
                      </form>
                    </>
                  ) : (
                    <form
                      id="waitlist-form"
                      onSubmit={handleWaitlistSubmit}
                      className="mt-8 space-y-6"
                    >
                      <div className="space-y-4">
                        <Input
                          id="waitlist-email"
                          name="waitlist-email"
                          type="email"
                          placeholder="Email address"
                          required
                          className="w-full"
                          value={waitlistEmail}
                          onChange={(e) => setWaitlistEmail(e.target.value)}
                        />
                        <Button
                          type="submit"
                          className="w-full bg-black text-white hover:bg-gray-800"
                        >
                          Join The Waitlist
                        </Button>
                      </div>
                    </form>
                  )}
                  {isLogin && (
                    <p className="mt-6 text-center text-sm text-gray-500">
                      By signing in, you agree to our{" "}
                      <a
                        href="/terms-of-service"
                        className="font-medium text-gray-900 hover:underline"
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacy-policy"
                        className="font-medium text-gray-900 hover:underline"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  )}
                </div>

                {/* Confirmation Message after Successful Waitlist Signup */}
                {isWaitlistConfirmed && (
                  <>
                    <div
                      id="waitlist-video-wrapper"
                      className="absolute inset-0 z-20 flex items-center justify-center bg-white"
                    >
                      <video
                        id="waitlist-video"
                        className="w-full max-w-screen-md object-contain"
                        src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/FO%20Main%20Page%20Waitlist%20Joined%20Vid%201.mp4"
                        autoPlay
                        muted
                        playsInline
                      />
                    </div>
                    <div
                      id="waitlist-confirmation-box"
                      className="w-full max-w-sm mt-0 p-6 bg-cyan-100 rounded-lg shadow-lg transition-opacity duration-1000 opacity-0 relative z-30"
                    >
                      <h3 className="text-2xl font-bold text-black text-center">
                        Welcome to the Waitlist!
                      </h3>
                      <p className="mt-4 text-center text-black">
                        You've successfully joined the waitlist! 🎉
                        <br />
                        Keep an eye on your emails for exciting updates coming
                        soon.
                        <br />
                        Meanwhile, follow us on social media to stay in the
                        loop!
                      </p>
                      <div className="flex justify-center mt-6 space-x-4">
                        <a
                          href="https://www.facebook.com/orchestraflow"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faFacebook}
                            className="text-2xl text-blue-600 hover:text-blue-700 transition-colors duration-300"
                          />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/flow-orchestra/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            className="text-2xl text-blue-700 hover:text-blue-800 transition-colors duration-300"
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/_floworchestra/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faInstagram}
                            className="text-2xl text-blue-700 hover:text-blue-800 transition-colors duration-300"
                          />
                        </a>
                        <a
                          href="https://x.com/floworchestra"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faTwitter}
                            className="text-2xl text-blue-400 hover:text-blue-500 transition-colors duration-300"
                          />
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <FloatingQuote
                quote="AI and automation play the notes of innovation."
                author="Flow Orchestra"
                className="text-black text-right pr-4 lg:pr-12 pl-12 lg:pl-0"
              />
              <div className="lg:hidden flex flex-col items-center justify-center mt-0 space-x-2">
                More
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="scroll-chevron-glow w-6 h-6 text-primary hover:text-primary/80 transition-colors"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section
          data-section-index="1"
          className="min-h-screen relative pb-20"
        >
          <PricingSection />
        </section>

        {/* FAQ Section */}
        <section
          data-section-index="2"
          className="min-h-screen relative"
        >
          <FAQSection />
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 mt-12">
            <Button
              variant="outline"
              onClick={() => {
                const firstSection = document.querySelector(
                  '[data-section-index="0"]'
                );
                if (firstSection) {
                  firstSection.scrollIntoView({ behavior: "smooth" });
                }
              }}
              className="flex items-center gap-2 border-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4"
              >
                <path d="m18 15-6-6-6 6" />
              </svg>
              Back to Top
            </Button>
          </div>
        </section>

        {/* Connect Section */}
        <section
          data-section-index="3"
          className="min-h-screen relative pb-12 lg:pb-0"
        >
          <ConnectSection />
          {/* Back to Top Button and Copyright can go here */}
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 mt-12">
            <Button
              variant="outline"
              onClick={() => {
                const firstSection = document.querySelector(
                  '[data-section-index="0"]'
                );
                if (firstSection) {
                  firstSection.scrollIntoView({ behavior: "smooth" });
                }
              }}
              className="flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4"
              >
                <path d="m18 15-6-6-6 6" />
              </svg>
              Back to Top
            </Button>
          </div>
        </section>
      </div>
      <div className="text-sm text-gray-500 text-center mb-2">
        © {new Date().getFullYear()} Flow Orchestra. All rights reserved.
      </div>
    </div>
  );
};

export default LoginPage;
