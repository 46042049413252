// src/pages/FlowFormComponents/RenderFields/ManualLessonsInput.js

import React, { useState, useEffect } from "react";
import { Label } from "../../../../components/ui/label";
import { Textarea } from "../../../../components/ui/textarea";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { Tooltip } from "../../../../components/ui/tooltip";
import { Checkbox } from "../../../../components/ui/checkbox";
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from "../../../../components/ui/collapsible";
import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectItem,
  } from '../../../../components/ui/select';

// Import FontAwesomeIcon and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPlus,
  faTrash,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

const ManualLessonsInput = ({ lessons, topics, handleFieldChange, onStepChange }) => {
  const [localLessons, setLocalLessons] = useState(lessons || []);
  const [collapsedLessons, setCollapsedLessons] = useState({});

  useEffect(() => {
    // Include lesson_id in lessons before passing to parent
    const lessonsWithIds = localLessons.map((lesson, index) => ({
      ...lesson,
      lesson_id: index + 1,
    }));
    handleFieldChange('courseData.lessons', lessonsWithIds, onStepChange);
  }, [localLessons]);
  

  const addLesson = () => {
    const newLessons = [
      ...localLessons,
      {
        topic_id: "",
        lesson_title: "",
        lesson_content: "",
        video: {
          source_type: "",
          source: "",
          runtime: {
            hours: "",
            minutes: "",
            seconds: "",
          },
        },
        preview: false,
      },
    ];
    setLocalLessons(newLessons);
  };

  const removeLesson = (index) => {
    const newLessons = [...localLessons];
    newLessons.splice(index, 1);
    setLocalLessons(newLessons);
  };

  const handleLessonChange = (index, field, value) => {
    setLocalLessons((prevLessons) => {
      const newLessons = prevLessons.map((lesson, i) => {
        if (i === index) {
          return { ...lesson, [field]: value };
        }
        return lesson;
      });
      return newLessons;
    });
  };
  

  const handleVideoChange = (index, field, value) => {
    setLocalLessons((prevLessons) => {
      const newLessons = prevLessons.map((lesson, i) => {
        if (i === index) {
          return {
            ...lesson,
            video: {
              ...lesson.video,
              [field]: value,
            },
          };
        }
        return lesson;
      });
      return newLessons;
    });
  };
  

  const handleRuntimeChange = (index, field, value) => {
    setLocalLessons((prevLessons) => {
      const newLessons = prevLessons.map((lesson, i) => {
        if (i === index) {
          return {
            ...lesson,
            video: {
              ...lesson.video,
              runtime: {
                ...lesson.video?.runtime,
                [field]: value,
              },
            },
          };
        }
        return lesson;
      });
      return newLessons;
    });
  };
  

  const toggleCollapse = (index) => {
    setCollapsedLessons({
      ...collapsedLessons,
      [index]: !collapsedLessons[index],
    });
  };

  return (
    <div className="space-y-6">
      {localLessons.map((lesson, index) => {
        const updatedLesson = { ...lesson, lesson_id: index + 1 };
        return (
          <Collapsible
            key={index}
            open={!collapsedLessons[index]}
            className="bg-gray-200 p-4 rounded-md shadow-sm"
          >
            <CollapsibleTrigger className="flex justify-between items-center w-full py-2">
              <Label className="text-inter font-bold">
                Lesson {index + 1}: ID {updatedLesson.lesson_id}
              </Label>
              <div className="flex items-center space-x-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => toggleCollapse(index)}
                >
                  <FontAwesomeIcon
                    icon={collapsedLessons[index] ? faChevronDown : faChevronUp}
                  />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeLesson(index)}
                >
                  <FontAwesomeIcon icon={faTrash} className="text-red-500" />
                </Button>
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div className="space-y-4 mt-4">
                {/* Display Lesson ID 
                <div>
                  <Label className="text-inter font-bold">
                    Lesson ID: {updatedLesson.lesson_id}
                  </Label>
                </div>*/}
                {/* Topic ID Select and Lesson Title */}
                <div className="flex space-x-4">
                  {/* Topic ID Select */}
                  <div className="w-1/2">
                    <Label className="text-inter font-bold">
                      Topic ID
                      <Tooltip content="Select the associated topic ID">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="inline-block w-4 h-4 ml-1 text-gray-400"
                        />
                      </Tooltip>
                    </Label>
                    <Select
                      value={updatedLesson.topic_id}
                      onValueChange={(value) =>
                        handleLessonChange(index, 'topic_id', value)
                      }
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select topic ID" />
                      </SelectTrigger>
                      <SelectContent>
                        {topics.map((topic) => (
                          <SelectItem key={topic.topic_id} value={topic.topic_id}>
                            <span className="font-semibold">{topic.topic_id}</span> - {topic.topic_title}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  {/* Lesson Title */}
                  <div className="w-1/2">
                    <Label
                      htmlFor={`lesson-title-${index}`}
                      className="text-inter font-bold"
                    >
                      Lesson Title
                      <Tooltip content="Enter the title of the lesson">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="inline-block w-4 h-4 ml-1 text-gray-400"
                        />
                      </Tooltip>
                    </Label>
                    <Input
                      id={`lesson-title-${index}`}
                      placeholder="Enter lesson title"
                      value={updatedLesson.lesson_title}
                      onChange={(e) =>
                        handleLessonChange(
                          index,
                          'lesson_title',
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                {/* Lesson Description */}
                <div>
                  <Label
                    htmlFor={`lesson-content-${index}`}
                    className="text-inter font-bold"
                  >
                    Lesson Content
                    <Tooltip content="Provide the content or a placeholder for the lesson">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                  <Textarea
                    id={`lesson-content-${index}`}
                    placeholder="Enter lesson content"
                    value={updatedLesson.lesson_content}
                    onChange={(e) =>
                      handleLessonChange(
                        index,
                        'lesson_content',
                        e.target.value
                      )
                    }
                  />
                </div>
                {/* Video Source Type */}
                <div>
                  <Label className="text-inter font-bold">
                    Video Source Type
                    <Tooltip content="Select the video source type">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                  <Select
                    value={updatedLesson.video?.source_type || ''}
                    onValueChange={(value) =>
                      handleVideoChange(index, 'source_type', value)
                    }
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select video source type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="YOUTUBE">YouTube</SelectItem>
                      <SelectItem value="VIMEO">Vimeo</SelectItem>
                      <SelectItem value="SELF_HOSTED">Self Hosted</SelectItem>
                      <SelectItem value="EMBEDDED">Embedded</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                {/* Video Source */}
                <div>
                  <Label
                    htmlFor={`video-source-${index}`}
                    className="text-inter font-bold"
                  >
                    Video Source URL
                    <Tooltip content="Enter the video source URL">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                  <Input
                    id={`video-source-${index}`}
                    placeholder="Enter video source URL"
                    value={updatedLesson.video?.source || ''}
                    onChange={(e) =>
                      handleVideoChange(index, 'source', e.target.value)
                    }
                  />
                </div>
                {/* Video Runtime */}
                <div className="flex space-x-4">
                    <div>
                        <Label className="text-inter font-bold">
                            Hours
                            <Tooltip content="Enter the number of hours in the video runtime">
                                <FontAwesomeIcon icon={faInfoCircle} className="inline-block w-4 h-4 ml-1 text-gray-400" />
                            </Tooltip>
                        </Label>
                        <Input type="number" value={updatedLesson.video?.runtime.hours || ''} onChange={(e) => handleRuntimeChange(index, 'hours', e.target.value)} />
                    </div>
                    <div>
                        <Label className="text-inter font-bold">
                            Minutes
                            <Tooltip content="Enter the number of minutes in the video runtime">
                                <FontAwesomeIcon icon={faInfoCircle} className="inline-block w-4 h-4 ml-1 text-gray-400" />
                            </Tooltip>
                        </Label>
                        <Input type="number" value={updatedLesson.video?.runtime.minutes || ''} onChange={(e) => handleRuntimeChange(index, 'minutes', e.target.value)} />
                    </div>
                    <div>
                        <Label className="text-inter font-bold">
                            Seconds
                            <Tooltip content="Enter the number of seconds in the video runtime">
                                <FontAwesomeIcon icon={faInfoCircle} className="inline-block w-4 h-4 ml-1 text-gray-400" />
                            </Tooltip>
                        </Label>
                        <Input type="number" value={updatedLesson.video?.runtime.seconds || ''} onChange={(e) => handleRuntimeChange(index, 'seconds', e.target.value)} />
                    </div>
                </div>

                {/* Lesson Featured Image ID */}
                <div>
                  <Label
                    htmlFor={`lesson-thumbnail-id-${index}`}
                    className="text-inter font-bold"
                  >
                    Lesson Featured Image ID
                    <Tooltip content="Enter the ID of the lesson's featured image">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                  <Input
                    id={`lesson-thumbnail-id-${index}`}
                    placeholder="Enter image ID"
                    value={updatedLesson.thumbnail_id || ''}
                    onChange={(e) =>
                      handleLessonChange(index, 'thumbnail_id', e.target.value)
                    }
                  />
                </div>
              </div>
            </CollapsibleContent>
          </Collapsible>
        );
      })}
      <Button
        type="button"
        onClick={addLesson}
        className="mt-2 bg-green-100 border-green-500 text-gray-900 hover:bg-green-200 hover:border-green-600 hover:text-green-600"
        variant="outline"
      >
        <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add Lesson
      </Button>
    </div>
  );
  
};

export default ManualLessonsInput;
