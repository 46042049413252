import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { login, register } from '../slices/authSlice';
import { fetchUserTeams, setSelectedTeam } from '../slices/teamsSlice';
import { getInvitationByToken, acceptInvitation } from '../services/api';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Card, CardHeader, CardContent, CardTitle } from "../components/ui/card";
import { useToast } from "../components/ui/use-toast";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";

const AcceptInvitation = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user, isAuthenticated } = useSelector(state => state.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invitationDetails, setInvitationDetails] = useState(null);
  const [invitationStatus, setInvitationStatus] = useState('valid'); // 'valid', 'accepted', or 'invalid'

  useEffect(() => {
    const fetchInvitationDetails = async () => {
      try {
        const response = await getInvitationByToken(token);
        setInvitationDetails(response.data);
        setInvitationStatus('valid');
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // Invitation has already been accepted or has expired
          setInvitationStatus('accepted');
        } else {
          // Invalid token or other error
          setInvitationStatus('invalid');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchInvitationDetails();

    // If the user is already authenticated, we don't need to wait
    if (isAuthenticated) {
      setLoading(false);
    }
  }, [token, navigate, toast, isAuthenticated]);

  const handleAcceptInvitation = async () => {
    try {
      await acceptInvitation(token);
      toast({
        title: "Success",
        description: "You've successfully joined the team!",
        variant: "default",
      });
      dispatch(fetchUserTeams());
      navigate('/dashboard');
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to accept invitation",
        variant: "destructive",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isNewUser) {
        await dispatch(register({ email, password, firstName, lastName })).unwrap();
      } else {
        await dispatch(login({ email, password })).unwrap();
      }
      handleAcceptInvitation();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Authentication failed",
        variant: "destructive",
      });
    }
  };

  if (invitationStatus === 'accepted') {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle>Invitation Already Accepted</CardTitle>
          </CardHeader>
          <CardContent>
            <Alert>
              <AlertTitle>This invitation has already been accepted.</AlertTitle>
              <AlertDescription>
                If you believe this is an error, please contact your team administrator.
              </AlertDescription>
            </Alert>
            <Button onClick={() => navigate('/dashboard')} className="w-full mt-4">
              Go to Dashboard
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (invitationStatus === 'invalid') {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle>Invalid Invitation</CardTitle>
          </CardHeader>
          <CardContent>
            <Alert variant="destructive">
              <AlertTitle>This invitation is invalid or has expired.</AlertTitle>
              <AlertDescription>
                Please check the link or contact your team administrator for a new invitation.
              </AlertDescription>
            </Alert>
            <Button onClick={() => navigate('/')} className="w-full mt-4">
              Go to Home
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (isAuthenticated && invitationDetails) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle>Accept Invitation</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="mb-4">
              Hello, {user.firstName}! You're invited to join {invitationDetails.teamName}. 
              Click the button below to accept.
            </p>
            <Button onClick={handleAcceptInvitation} className="w-full">
              Accept Invitation
            </Button>
            <p className="mt-4 text-sm text-gray-600">
              Not {user.firstName}? <a href="/logout" className="text-blue-600 hover:underline">Log out</a> to use a different account.
            </p>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (invitationDetails) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle>{isNewUser ? 'Create Account' : 'Sign In'}</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="mb-4">
              You've been invited to join {invitationDetails.teamName} on Flow Orchestra!
              {isNewUser ? " Let's create your account to get started." : " Please sign in to accept the invitation."}
            </p>
            <form onSubmit={handleSubmit} className="space-y-4">
              {isNewUser && (
                <>
                  <Input
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  <Input
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </>
              )}
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button type="submit" className="w-full">
                {isNewUser ? 'Create Account & Accept' : 'Sign In & Accept'}
              </Button>
            </form>
            <p className="mt-4 text-center">
              {isNewUser ? (
                <>
                  Already have an account?{' '}
                  <button
                    onClick={() => setIsNewUser(false)}
                    className="text-blue-600 hover:underline"
                  >
                    Sign in
                  </button>
                </>
              ) : (
                <>
                  New to Flow Orchestra?{' '}
                  <button
                    onClick={() => setIsNewUser(true)}
                    className="text-blue-600 hover:underline"
                  >
                    Create an account
                  </button>
                </>
              )}
            </p>
          </CardContent>
        </Card>
      </div>
    );
  }

  // This should never happen, but just in case
  return <div>Something went wrong. Please try again later.</div>;
};

export default AcceptInvitation;