import React, { useEffect, createContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { store } from './store';
import { getCurrentUser } from './slices/authSlice';
import AuthWrapper from './components/AuthWrapper';
import Layout from './pages/Layout';
import Dashboard from './pages/Dashboard';
import FlowCreation from './pages/FlowCreation';
import FlowEdit from './pages/FlowEdit';
import FlowManagement from './pages/FlowManagement';
import FlowExecution from './pages/FlowExecution';
import Scheduling from './pages/Scheduling';
import LoginPage from './pages/LoginPage';
import ExecutionPage from './pages/ExecutionPage';
import ManageTeams from './pages/ManageTeams';
import AcceptInvitation from './pages/AcceptInvitation';
import AIImageGenerationPage from './pages/AIImageGenerationPage';
import AssetsPage from './pages/AssetsPage';
import ChatComponent from './pages/ChatComponent';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import DataDeletionRequest from './pages/DataDeletion';
import UserChatbotPage from './pages/UserChatbotPage';
import { initSocket, closeSocket } from './services/socket';
import { Toaster } from "./components/ui/toaster";

export const SocketContext = createContext(null);

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useSelector((state) => state.auth);
  
  /*if (loading) {
    return <div>xxxLoading...</div>;
  }*/
  
  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

const AppRoutes = () => {
  const { isAuthenticated, accessToken, loading, error } = useSelector((state) => state.auth);
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCurrentUser());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    console.log('AppRoutes: Auth state changed', { isAuthenticated, accessToken: !!accessToken });
    if (isAuthenticated && accessToken) {
      console.log('Initializing socket');
      const newSocket = initSocket(accessToken);
      setSocket(newSocket);
      return () => {
        console.log('Closing socket');
        closeSocket();
        setSocket(null);
      };
    }
  }, [isAuthenticated, accessToken]);

  useEffect(() => {
    console.log('Socket state in AppRoutes:', socket ? 'Connected' : 'Not connected');
  }, [socket]);


  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/accept-invitation/:token" element={<AcceptInvitation />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/data-deletion" element={<DataDeletionRequest />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  return (
    <SocketContext.Provider value={socket}>
      <Layout>
        <Routes>
          <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/image-generation" element={<ProtectedRoute><AIImageGenerationPage /></ProtectedRoute>} />
          <Route path="/assets/:assetType" element={<ProtectedRoute><AssetsPage /></ProtectedRoute>} />
          <Route path="/chat/:chatId" element={<ProtectedRoute><ChatComponent /></ProtectedRoute>} />
          <Route path="/chat" element={<ProtectedRoute><ChatComponent /></ProtectedRoute>} />
          <Route path="/create-flow" element={<ProtectedRoute><FlowCreation /></ProtectedRoute>} />
          <Route path="/edit-flow/:id" element={<ProtectedRoute><FlowEdit /></ProtectedRoute>} />
          <Route path="/manage-flows" element={<ProtectedRoute><FlowManagement /></ProtectedRoute>} />
          <Route path="/manage-teams" element={<ProtectedRoute><ManageTeams /></ProtectedRoute>} />
          <Route path="/execute-flow/:id" element={<ProtectedRoute><FlowExecution /></ProtectedRoute>} />
          <Route path="/execution/:executionId" element={<ProtectedRoute><ExecutionPage /></ProtectedRoute>} />
          <Route path="/execution" element={<ProtectedRoute><ExecutionPage /></ProtectedRoute>} />
          <Route path="/scheduling" element={<ProtectedRoute><Scheduling /></ProtectedRoute>} />
          <Route path="/scheduling/:scheduleId" element={<ProtectedRoute><Scheduling /></ProtectedRoute>} />
          <Route path="/custom-assistants" element={<ProtectedRoute><UserChatbotPage /></ProtectedRoute>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/data-deletion" element={<DataDeletionRequest />} />
          <Route path="/accept-invitation/:token" element={<AcceptInvitation />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
    </SocketContext.Provider>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <AuthWrapper>
        <Router>
          <AppRoutes />
          <Toaster />
        </Router>
      </AuthWrapper>
    </Provider>
  );
};

export default App;