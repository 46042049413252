import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Mail } from 'lucide-react';

const DataDeletionRequest = () => {
  const handleEmailClick = () => {
    window.location.href = 'mailto:support@gaimnetwork.com?subject=Data Deletion Request';
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="w-full max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">Data Deletion Request</CardTitle>
        </CardHeader>
        <CardContent className="prose">
          <p>
            At Flow Orchestra, we respect your privacy and your right to control your personal data. If you wish to request the deletion of your personal information from our systems, please follow the steps below:
          </p>

          <ol className="list-decimal pl-6 mt-4">
            <li>Send an email to support@gaimnetwork.com</li>
            <li>Use the subject line "Data Deletion Request"</li>
            <li>In the body of the email, please include:
              <ul className="list-disc pl-6">
                <li>Your full name</li>
                <li>The email address associated with your Flow Orchestra account</li>
                <li>A clear statement requesting the deletion of your data</li>
              </ul>
            </li>
          </ol>

          <p className="mt-4">
            Once we receive your request, we will process it as quickly as possible. Please note that:
          </p>

          <ul className="list-disc pl-6 mt-4">
            <li>We may need to verify your identity to protect your privacy and security</li>
            <li>Some information may be retained for legal or administrative purposes</li>
            <li>Deletion of your data may result in the termination of your Flow Orchestra account and services</li>
          </ul>

          <p className="mt-4">
            If you have any questions about the data deletion process, please don't hesitate to contact us.
          </p>

          <div className="mt-6">
            <Button onClick={handleEmailClick} className="flex items-center">
              <Mail className="mr-2 h-4 w-4" />
              Send Data Deletion Request
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default DataDeletionRequest;