import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../services/api';

export const fetchUserChatbots = createAsyncThunk(
  'userChatbots/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getUserChatbots();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserChatbot = createAsyncThunk(
  'userChatbots/fetchOne',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.getUserChatbot(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUserChatbot = createAsyncThunk(
  'userChatbots/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.createUserChatbot(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserChatbot = createAsyncThunk(
  'userChatbots/update',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await api.updateUserChatbot(id, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserChatbot = createAsyncThunk(
  'userChatbots/delete',
  async (id, { rejectWithValue }) => {
    try {
      await api.deleteUserChatbot(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userChatbotSlice = createSlice({
  name: 'userChatbots',
  initialState: {
    chatbots: [],
    activeChatbot: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setActiveChatbot: (state, action) => {
      state.activeChatbot = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserChatbots.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserChatbots.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.chatbots = action.payload;
      })
      .addCase(fetchUserChatbots.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchUserChatbot.fulfilled, (state, action) => {
        state.activeChatbot = action.payload;
      })
      .addCase(createUserChatbot.fulfilled, (state, action) => {
        state.chatbots.push(action.payload);
      })
      .addCase(updateUserChatbot.fulfilled, (state, action) => {
        const index = state.chatbots.findIndex((chatbot) => chatbot.id === action.payload.id);
        if (index !== -1) {
          state.chatbots[index] = action.payload;
        }
        if (state.activeChatbot && state.activeChatbot.id === action.payload.id) {
          state.activeChatbot = action.payload;
        }
      })
      .addCase(deleteUserChatbot.fulfilled, (state, action) => {
        state.chatbots = state.chatbots.filter((chatbot) => chatbot.id !== action.payload);
        if (state.activeChatbot && state.activeChatbot.id === action.payload) {
          state.activeChatbot = null;
        }
      });
  },
});

export const { setActiveChatbot } = userChatbotSlice.actions;

export default userChatbotSlice.reducer;