export const authors = [
    { value: "3", label: "Helena Hargrove" },
    { value: "4", label: "Jason Thompson" },
    { value: "5", label: "Amy Tannenbaum" },
  ];
  
  export const categories = [
    { value: 39, label: "Education" },
    { value: 40, label: "Entertainment" },
    { value: 78, label: "Entertainment > Self-Care" },
  ];
  
  export const savableAssets = [
    { value: "generalImage", label: "General Image" },
    { value: "generalText", label: "General Text" },
    { value: "blogPost", label: "Blog Post" },
    { value: "course", label: "Course" },
    { value: "product", label: "Product" },
    { value: "socialPost", label: "Social Post" },
  ];
  
  export const tags = [
    { value: 105, label: "Gifts For Nurses" },
    { value: 45, label: "Health Promotion" },
    { value: 84, label: "Holidays" },
    { value: 108, label: "NCLEX Tips" },
    { value: 106, label: "Nurse Fashion" },
    { value: 90, label: "Nurse Hacks" },
    { value: 79, label: "Nurse Humor" },
    { value: 81, label: "Nurse Life" },
    { value: 75, label: "Nurse Self-Care" },
    { value: 107, label: "Nurse Shirts" },
    { value: 76, label: "Nursing Lifestyle" },
    { value: 88, label: "Nursing Rituals" },
    { value: 89, label: "Nursing Tips" },
  ];