// src/pages/FlowFormComponents/RenderFields/ManualQuizQuestionsInput.js

import React, { useState, useEffect } from 'react';
import { Label } from '../../../../components/ui/label';
import { Textarea } from '../../../../components/ui/textarea';
import { Input } from '../../../../components/ui/input';
import { Button } from '../../../../components/ui/button';
import { Tooltip } from '../../../../components/ui/tooltip';
import { Checkbox } from '../../../../components/ui/checkbox';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '../../../../components/ui/select';
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from '../../../../components/ui/collapsible';
import {
  RadioGroup,
  RadioGroupItem,
} from '../../../../components/ui/radio-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faPlus,
  faTrash,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';

const questionTypeOptions = [
    { value: 'true_false', label: 'True/False' },
    { value: 'single_choice', label: 'Single Choice' },
    { value: 'multiple_choice', label: 'Multiple Choice' },
    { value: 'open_ended', label: 'Open-Ended' },
    { value: 'short_answer', label: 'Short Answer' },
    { value: 'fill_in_the_blank', label: 'Fill in the Blanks' },
  ];

const ManualQuizQuestionsInput = ({ quizQuestions, quizzes, handleFieldChange, onStepChange }) => {
  const [localQuizQuestions, setLocalQuizQuestions] = useState(quizQuestions || []);
  const [collapsedQuestions, setCollapsedQuestions] = useState({});

  useEffect(() => {
    // Include question_id in quizQuestions before passing to parent
    const questionsWithIds = localQuizQuestions.map((question, index) => ({
      ...question,
      question_id: index + 1,
    }));
    handleFieldChange('courseData.quizQuestions', questionsWithIds, onStepChange);
  }, [localQuizQuestions]);
  

  const addQuestion = () => {
    const newQuestions = [
      ...localQuizQuestions,
      {
        quiz_id: '',
        question_title: '',
        question_description: '',
        question_type: 'true_false',
        options: [], // Initialize options array
        correct_answer: null,
        answer_required: false,
        randomize_question: false,
        question_mark: '',
        show_question_mark: false,
        answer_explanation: '',
      },
    ];
    setLocalQuizQuestions(newQuestions);
  };

  const removeQuestion = (index) => {
    const newQuestions = [...localQuizQuestions];
    newQuestions.splice(index, 1);
    setLocalQuizQuestions(newQuestions);
  };

  const handleQuestionChange = (index, field, value) => {
    setLocalQuizQuestions((prevQuestions) => {
      const newQuestions = prevQuestions.map((question, i) => {
        if (i === index) {
          let updatedQuestion = { ...question, [field]: value };
  
          if (field === 'question_type') {
            const previousType = question.question_type;
            const newType = value;
  
            // Check if switching between single_choice and multiple_choice
            const switchingBetweenChoices =
              (previousType === 'single_choice' && newType === 'multiple_choice') ||
              (previousType === 'multiple_choice' && newType === 'single_choice');
  
            if (!switchingBetweenChoices) {
              // Reset correct_answer and options based on new question type
              switch (newType) {
                case 'true_false':
                  updatedQuestion.correct_answer = false; // default to false
                  updatedQuestion.options = [];
                  break;
                case 'single_choice':
                  updatedQuestion.correct_answer = null;
                  updatedQuestion.options = [{ value: '' }];
                  break;
                case 'multiple_choice':
                  updatedQuestion.correct_answer = [];
                  updatedQuestion.options = [{ value: '' }];
                  break;
                case 'open_ended':
                case 'short_answer':
                case 'fill_in_the_blank':
                  updatedQuestion.correct_answer = null;
                  updatedQuestion.options = [];
                  break;
                // Add other question types as needed
                default:
                  updatedQuestion.correct_answer = null;
                  updatedQuestion.options = [];
              }
            } else {
              // If switching between single_choice and multiple_choice, keep options and correct_answer
              // Adjust correct_answer data structure if needed
              if (newType === 'multiple_choice' && !Array.isArray(updatedQuestion.correct_answer)) {
                // Convert correct_answer to array
                updatedQuestion.correct_answer =
                  updatedQuestion.correct_answer !== null
                    ? [String(updatedQuestion.correct_answer)]
                    : [];
              } else if (newType === 'single_choice' && Array.isArray(updatedQuestion.correct_answer)) {
                // Convert correct_answer to single value
                updatedQuestion.correct_answer =
                  updatedQuestion.correct_answer.length > 0
                    ? updatedQuestion.correct_answer[0]
                    : null;
              }
              // Keep options as is
            }
          }
  
          return updatedQuestion;
        }
        return question;
      });
      return newQuestions;
    });
  };
  

  const handleOptionChange = (questionIndex, optionIndex, field, value) => {
    const newQuestions = [...localQuizQuestions];
    newQuestions[questionIndex].options[optionIndex][field] = value;
    setLocalQuizQuestions(newQuestions);
  };

  const addOption = (questionIndex) => {
    setLocalQuizQuestions((prevQuestions) =>
      prevQuestions.map((question, i) => {
        if (i === questionIndex) {
          // Determine which fields to include based on question_type
          let newOption = {};
          switch (question.question_type) {
            case 'single_choice':
            case 'multiple_choice':
            case 'ORDERING':
              newOption = { value: '' };
              break;
            case 'MATCHING':
              newOption = { prompt: '', answer: '' };
              break;
            case 'IMAGE_MATCHING':
              newOption = { prompt: '', answer: '', image_url: '' };
              break;
            default:
              newOption = {};
          }
          return {
            ...question,
            options: [...(question.options || []), newOption],
          };
        }
        return question;
      })
    );
  };
  

  const removeOption = (questionIndex, optionIndex) => {
    const newQuestions = [...localQuizQuestions];
    newQuestions[questionIndex].options.splice(optionIndex, 1);
    setLocalQuizQuestions(newQuestions);
  };

  const handleCorrectAnswerChange = (questionIndex, value) => {
    setLocalQuizQuestions((prevQuestions) => {
      const newQuestions = prevQuestions.map((question, i) => {
        if (i === questionIndex) {
          return {
            ...question,
            correct_answer: value,
          };
        }
        return question;
      });
      return newQuestions;
    });
  };
  

  const handleCorrectAnswersChange = (questionIndex, value) => {
    setLocalQuizQuestions((prevQuestions) => {
      return prevQuestions.map((question, i) => {
        if (i === questionIndex) {
          // Create a copy of the correct_answer array or initialize it
          const correctAnswers = question.correct_answer ? [...question.correct_answer] : [];
          let newCorrectAnswers;
  
          if (correctAnswers.includes(value)) {
            // Remove the value if it's already in the array
            newCorrectAnswers = correctAnswers.filter((v) => v !== value);
          } else {
            // Add the value to the array
            newCorrectAnswers = [...correctAnswers, value];
          }
  
          // Return a new question object with the updated correct_answer
          return {
            ...question,
            correct_answer: newCorrectAnswers,
          };
        }
        return question;
      });
    });
  };
  

  const toggleCollapse = (index) => {
    setCollapsedQuestions({
      ...collapsedQuestions,
      [index]: !collapsedQuestions[index],
    });
  };

  return (
    <div className="space-y-6">
      {localQuizQuestions.map((question, index) => {
        // Create a new question object with the updated question_id
        const updatedQuestion = { ...question, question_id: index + 1 };
  
        return (
          <Collapsible
            key={index}
            open={!collapsedQuestions[index]}
            className="bg-gray-200 p-4 rounded-md shadow-sm"
          >
            <CollapsibleTrigger className="flex justify-between items-center w-full py-2">
              <Label className="text-inter font-bold">
                Question {index + 1}: ID {updatedQuestion.question_id}
              </Label>
              <div className="flex items-center space-x-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => toggleCollapse(index)}
                >
                  <FontAwesomeIcon
                    icon={collapsedQuestions[index] ? faChevronDown : faChevronUp}
                  />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeQuestion(index)}
                >
                  <FontAwesomeIcon icon={faTrash} className="text-red-500" />
                </Button>
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div className="space-y-4 mt-4">
                  {/* Quiz ID Select */}
                  <div className="flex space-x-4">
  {/* Quiz ID Select */}
  <div className="w-2/6">
    <Label className="text-inter font-bold">
      Quiz ID
      <Tooltip content="Select the associated quiz ID">
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="inline-block w-4 h-4 ml-1 text-gray-400"
        />
      </Tooltip>
    </Label>
    <Select
      value={updatedQuestion.quiz_id}
      onValueChange={(value) =>
        handleQuestionChange(index, 'quiz_id', value)
      }
    >
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Select quiz ID" />
      </SelectTrigger>
      <SelectContent>
        {quizzes.map((quiz) => (
          <SelectItem key={quiz.quiz_id} value={quiz.quiz_id}>
            {quiz.quiz_id} - {quiz.quiz_title}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>
  {/* Question Type Select */}
  <div className="w-2/6">
    <Label className="text-inter font-bold">
      Question Type
      <Tooltip content="Select the question type">
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="inline-block w-4 h-4 ml-1 text-gray-400"
        />
      </Tooltip>
    </Label>
    <Select
      value={updatedQuestion.question_type}
      onValueChange={(value) =>
        handleQuestionChange(index, 'question_type', value)
      }
    >
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Select question type" />
      </SelectTrigger>
      <SelectContent>
        {questionTypeOptions.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>
  
                {/* Question Mark */}
                <div className="w-2/6">
                  <Label
                    htmlFor={`question-mark-${index}`}
                    className="text-inter font-bold"
                  >
                    Point Value
                    <Tooltip content="Assign a point value for this question">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                  <Input
                    id={`question-mark-${index}`}
                    placeholder="Enter question point value. (e.g. 1)"
                    value={updatedQuestion.question_mark}
                    onChange={(e) =>
                      handleQuestionChange(index, 'question_mark', e.target.value)
                    }
                  />
                </div>
</div>
{/* Question */}
<div>
  <Label
    htmlFor={`question-title-${index}`}
    className="text-inter font-bold"
  >
    Question
    <Tooltip content="Enter the question text">
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="inline-block w-4 h-4 ml-1 text-gray-400"
      />
    </Tooltip>
  </Label>
  <Textarea
    id={`question-title-${index}`}
    placeholder="Enter question"
    value={updatedQuestion.question_title}
    onChange={(e) =>
      handleQuestionChange(
        index,
        'question_title',
        e.target.value
      )
    }
  />
</div>
{/* Question Description */}
<div>
  <Label
    htmlFor={`question-description-${index}`}
    className="text-inter font-bold"
  >
    Question Description
    <Tooltip content="Provide a description for the question">
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="inline-block w-4 h-4 ml-1 text-gray-400"
      />
    </Tooltip>
  </Label>
  <Input
    id={`question-description-${index}`}
    placeholder="Enter question description"
    value={updatedQuestion.question_description}
    onChange={(e) =>
      handleQuestionChange(
        index,
        'question_description',
        e.target.value
      )
    }
  />
</div>

                {/* Render Answer Options Based on Question Type */}
                {(() => {
                  switch (updatedQuestion.question_type) {
                    case 'true_false':
  return (
    <div>
      <Label className="text-inter font-bold mt-4">
        Select Correct Answer
      </Label>
      <RadioGroup
        value={updatedQuestion.correct_answer ? 'true' : 'false'}
        onValueChange={(value) =>
          handleQuestionChange(
            index,
            'correct_answer',
            value === 'true'
          )
        }
        className="flex space-x-6 mt-2"
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="true" id={`true-${index}`} />
          <Label htmlFor={`true-${index}`}>True</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="false" id={`false-${index}`} />
          <Label htmlFor={`false-${index}`}>False</Label>
        </div>
      </RadioGroup>
    </div>
  );

  case 'single_choice':
    return (
      <div>
        <Label className="text-inter font-bold mt-4">
          Answer Options
        </Label>
        <RadioGroup
          value={updatedQuestion.correct_answer}
          onValueChange={(value) =>
            handleQuestionChange(
              index,
              'correct_answer',
              value
            )
          }
        >
          {updatedQuestion.options.map((option, optIndex) => (
            <div
              key={optIndex}
              className="flex items-center space-x-2 mt-2"
            >
              <RadioGroupItem
                value={String(optIndex)}
                id={`option-${index}-${optIndex}`}
              />
              <Label htmlFor={`option-${index}-${optIndex}`} className="w-full">
                <Input
                  placeholder={`Option ${optIndex + 1}`}
                  value={option.value}
                  onChange={(e) =>
                    handleOptionChange(
                      index,
                      optIndex,
                      'value',
                      e.target.value
                    )
                  }
                  className="w-full"
                />
              </Label>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => removeOption(index, optIndex)}
                className="ml-2"
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className="text-red-500"
                />
              </Button>
            </div>
          ))}
        </RadioGroup>
        <Button
          variant="outline"
          size="sm"
          className="mt-2"
          onClick={() => addOption(index)}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add Option
        </Button>
      </div>
    );
  
                    case 'multiple_choice':
                      return (
                        <div>
                          <Label className="text-inter font-bold mt-4">
                            Answer Options
                          </Label>
                          {updatedQuestion.options.map((option, optIndex) => (
                            <div
                              key={optIndex}
                              className="flex items-center space-x-2 mt-2"
                            >
                              <Checkbox
                                checked={updatedQuestion.correct_answer?.includes(
                                  String(optIndex)
                                )}
                                onCheckedChange={() =>
                                  handleCorrectAnswersChange(
                                    index,
                                    String(optIndex)
                                  )
                                }
                              />
                              <Input
                                placeholder={`Option ${optIndex + 1}`}
                                value={option.value}
                                onChange={(e) =>
                                  handleOptionChange(
                                    index,
                                    optIndex,
                                    'value',
                                    e.target.value
                                  )
                                }
                              />
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => removeOption(index, optIndex)}
                                className="ml-2"
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="text-red-500"
                                />
                              </Button>
                            </div>
                          ))}
                          <Button
                            variant="outline"
                            size="sm"
                            className="mt-2"
                            onClick={() => addOption(index)}
                          >
                            <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add
                            Option
                          </Button>
                        </div>
                      );
                    // Include other question types as per your previous code
                    default:
                      return null;
                  }
                })()}
                {/* Answer Required Checkbox */}
                <div className="flex items-center space-x-2 mt-4">
                  <Checkbox
                    id={`answer-required-${index}`}
                    checked={updatedQuestion.answer_required}
                    onCheckedChange={(checked) =>
                      handleQuestionChange(index, 'answer_required', checked)
                    }
                  />
                  <Label
                    htmlFor={`answer-required-${index}`}
                    className="text-inter"
                  >
                    Answer Required
                    <Tooltip content="Check if an answer is required for this question">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                </div>
                {/* Randomize Question Checkbox */}
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id={`randomize-question-${index}`}
                    checked={updatedQuestion.randomize_question}
                    onCheckedChange={(checked) =>
                      handleQuestionChange(index, 'randomize_question', checked)
                    }
                  />
                  <Label
                    htmlFor={`randomize-question-${index}`}
                    className="text-inter"
                  >
                    Randomize Question
                    <Tooltip content="Check to randomize the options for this question">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                </div>
                {/* Answer Explanation */}
                <div>
                  <Label
                    htmlFor={`answer-explanation-${index}`}
                    className="text-inter font-bold"
                  >
                    Answer Explanation
                    <Tooltip content="Provide an explanation for the answer">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                  <Textarea
                    id={`answer-explanation-${index}`}
                    placeholder="Enter answer explanation"
                    value={updatedQuestion.answer_explanation}
                    onChange={(e) =>
                      handleQuestionChange(
                        index,
                        'answer_explanation',
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </CollapsibleContent>
          </Collapsible>
        );
      })}
      <Button
        type="button"
        onClick={addQuestion}
        className="mt-2 bg-green-100 border-green-500 text-gray-900 hover:bg-green-200 hover:border-green-600 hover:text-green-600"
        variant="outline"
      >
        <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add Question
      </Button>
    </div>
  );
  
  
  
};

export default ManualQuizQuestionsInput;
