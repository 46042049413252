// src/pages/FlowFormComponents/RenderFields/SavedCourseFields.js

import React, { useState } from "react";
import { Label } from "../../../../components/ui/label";
import { Textarea } from "../../../../components/ui/textarea";
import { Input } from "../../../../components/ui/input";
import { Tooltip, TooltipProvider } from "../../../../components/ui/tooltip";
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from "../../../../components/ui/collapsible";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../components/ui/radio-group";
import { Button } from "../../../../components/ui/button";
import { Checkbox } from "../../../../components/ui/checkbox";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../../../../components/ui/select";

// Import FontAwesomeIcon and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faChevronDown,
  faChevronUp,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

// Subcomponents for manual input
import ManualTopicsInput from "./ManualTopicsInput";
import ManualLessonsInput from "./ManualLessonsInput";
import ManualQuizzesInput from "./ManualQuizzesInput";
import ManualQuizQuestionsInput from "./ManualQuizQuestionsInput";

const SavedCourseFields = ({
  step,
  handleFieldChange,
  index,
  onStepChange,
}) => {
  const [useJsonTopics, setUseJsonTopics] = useState(true);
  const [useJsonLessons, setUseJsonLessons] = useState(true);
  const [useJsonQuizzes, setUseJsonQuizzes] = useState(true);
  const [useJsonQuizQuestions, setUseJsonQuizQuestions] = useState(true);

  const [topicsCollapsed, setTopicsCollapsed] = useState(false);
  const [lessonsCollapsed, setLessonsCollapsed] = useState(false);
  const [quizzesCollapsed, setQuizzesCollapsed] = useState(false);
  const [quizQuestionsCollapsed, setQuizQuestionsCollapsed] = useState(false);

  // Add this function above the return statement in your component
  const handleJsonInputChange = (field, value) => {
    let parsedData;
  
    try {
      // Try to parse the input value
      parsedData = JSON.parse(value);
  
      // If parsedData is not an array, wrap it in an array
      if (!Array.isArray(parsedData)) {
        parsedData = [parsedData];
      }
    } catch (e) {
      // If parsing fails, attempt to fix common issues
  
      // Remove any trailing commas and whitespace
      let fixedValue = value.trim().replace(/,\s*$/, '');
  
      // Remove any existing square brackets at the start or end
      fixedValue = fixedValue.replace(/^\[|\]$/g, '');
  
      // Wrap the value in square brackets
      fixedValue = `[${fixedValue}]`;
  
      try {
        parsedData = JSON.parse(fixedValue);
      } catch (e2) {
        // If parsing still fails, keep the original value as a string
        console.error('Invalid JSON input:', e2);
        parsedData = value; // You may choose to handle this differently
      }
    }
  
    // Call handleFieldChange with the parsed data (object or array)
    handleFieldChange(field, parsedData, onStepChange);
  };
  
  
  

  const updateCourseField = (field, value) => {
    handleFieldChange(`courseData.course.${field}`, value, onStepChange);
  };

  const handleCategoriesChange = (e) => {
    const categoriesString = e.target.value;
    const categoriesArray = categoriesString.split(",").map((id) => id.trim());
    updateCourseField("course_categories", categoriesArray);
  };

  // Add this function alongside your existing functions
const handleTagsChange = (e) => {
    const tagsString = e.target.value;
    const tagsArray = tagsString.split(",").map((id) => id.trim());
    updateCourseField("course_tags", tagsArray);
  };  

  return (
    <TooltipProvider>
      <div className="space-y-6 bg-gray-100 border p-6 rounded-md">
        {/* Course Fields */}
        <div>
          <Label
            htmlFor={`course-title-${index}`}
            className="text-inter font-bold"
          >
            Course Title
            <Tooltip content="Enter the title of the course">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="inline-block w-4 h-4 ml-1 text-gray-400"
              />
            </Tooltip>
          </Label>
          <Input
            id={`course-title-${index}`}
            placeholder="Enter course title"
            value={step.courseData?.course?.post_title || ""}
            onChange={(e) => updateCourseField("post_title", e.target.value)}
          />
        </div>
        <div>
          <Label
            htmlFor={`course-description-${index}`}
            className="text-inter font-bold"
          >
            Course Description
            <Tooltip content="Provide a description for the course">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="inline-block w-4 h-4 ml-1 text-gray-400"
              />
            </Tooltip>
          </Label>
          <Textarea
            id={`course-description-${index}`}
            placeholder="Enter course description"
            value={step.courseData?.course?.post_content || ""}
            onChange={(e) => updateCourseField("post_content", e.target.value)}
          />
        </div>
        <div>
          <Label
            htmlFor={`course-author-${index}`}
            className="text-inter font-bold"
          >
            Course Author ID
            <Tooltip content="Enter the ID of the course author">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="inline-block w-4 h-4 ml-1 text-gray-400"
              />
            </Tooltip>
          </Label>
          <Input
            id={`course-author-${index}`}
            placeholder="Enter author ID"
            type="number"
            value={step.courseData?.course?.post_author || ""}
            onChange={(e) => updateCourseField("post_author", e.target.value)}
          />
        </div>
        <div>
          <Label
            htmlFor={`course-excerpt-${index}`}
            className="text-inter font-bold"
          >
            Course Excerpt
            <Tooltip content="Enter a brief excerpt for the course">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="inline-block w-4 h-4 ml-1 text-gray-400"
              />
            </Tooltip>
          </Label>
          <Textarea
            id={`course-excerpt-${index}`}
            placeholder="Enter course excerpt"
            value={step.courseData?.course?.post_excerpt || ""}
            onChange={(e) => updateCourseField("post_excerpt", e.target.value)}
          />
        </div>
        <div>
  <Label htmlFor={`course-thumbnail-id-${index}`} className="text-inter font-bold">
    Course Featured Image URL
    <Tooltip content="Enter the URL of the course's featured image">
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="inline-block w-4 h-4 ml-1 text-gray-400"
      />
    </Tooltip>
  </Label>
  <Input
    id={`course-thumbnail-id-${index}`}
    placeholder="Enter image URL"
    value={step.courseData?.course?.thumbnail_id || ''}
    onChange={(e) => updateCourseField('thumbnail_id', e.target.value)}
  />
</div>

        <div>
  <Label className="text-inter font-bold">
    Course Video Source Type
    <Tooltip content="Select the video source type for the course">
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="inline-block w-4 h-4 ml-1 text-gray-400"
      />
    </Tooltip>
  </Label>
  <Select
    value={step.courseData?.course?.video?.source_type || ''}
    onValueChange={(value) =>
      handleFieldChange('courseData.course.video.source_type', value, onStepChange)
    }
  >
    <SelectTrigger className="w-full">
      <SelectValue placeholder="Select video source type" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value="YOUTUBE">YouTube</SelectItem>
      <SelectItem value="VIMEO">Vimeo</SelectItem>
      <SelectItem value="SELF_HOSTED">Self Hosted</SelectItem>
      <SelectItem value="EMBEDDED">Embedded</SelectItem>
    </SelectContent>
  </Select>
</div>
<div>
  <Label htmlFor={`course-video-source-${index}`} className="text-inter font-bold">
    Course Video Source URL
    <Tooltip content="Enter the video source URL for the course">
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="inline-block w-4 h-4 ml-1 text-gray-400"
      />
    </Tooltip>
  </Label>
  <Input
    id={`course-video-source-${index}`}
    placeholder="Enter video source URL"
    value={step.courseData?.course?.video?.source || ''}
    onChange={(e) =>
      handleFieldChange('courseData.course.video.source', e.target.value, onStepChange)
    }
  />
</div>

        {/* Categories, Tags, and Course Level */}
<div className="flex space-x-4">
  {/* Course Categories */}
  <div className="w-1/3">
    <Label
      htmlFor={`course-categories-${index}`}
      className="text-inter font-bold"
    >
      Categories
      <Tooltip content="Enter category IDs separated by commas">
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="inline-block w-4 h-4 ml-1 text-gray-400"
        />
      </Tooltip>
    </Label>
    <Input
      id={`course-categories-${index}`}
      placeholder="Enter category IDs separated by commas"
      value={
        Array.isArray(step.courseData?.course?.course_categories)
          ? step.courseData.course.course_categories.join(", ")
          : ""
      }
      onChange={handleCategoriesChange}
    />
  </div>

  {/* Course Tags */}
  <div className="w-1/3">
    <Label
      htmlFor={`course-tags-${index}`}
      className="text-inter font-bold"
    >
      Tags
      <Tooltip content="Enter tag IDs separated by commas">
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="inline-block w-4 h-4 ml-1 text-gray-400"
        />
      </Tooltip>
    </Label>
    <Input
      id={`course-tags-${index}`}
      placeholder="Enter tag IDs separated by commas"
      value={
        Array.isArray(step.courseData?.course?.course_tags)
          ? step.courseData.course.course_tags.join(", ")
          : ""
      }
      onChange={handleTagsChange}
    />
  </div>

  {/* Course Level */}
  <div className="w-1/3">
    <Label className="text-inter font-bold">
      Course Level
      <Tooltip content="Select the difficulty level of the course">
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="inline-block w-4 h-4 ml-1 text-gray-400"
        />
      </Tooltip>
    </Label>
    <Select
      value={step.courseData?.course?.additional_content?.course_level || ""}
      onValueChange={(value) =>
        handleFieldChange(
          "courseData.course.additional_content.course_level",
          value,
          onStepChange
        )
      }
    >
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Select course level" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all_levels">All Levels</SelectItem>
        <SelectItem value="beginner">Beginner</SelectItem>
        <SelectItem value="intermediate">Intermediate</SelectItem>
        <SelectItem value="expert">Expert</SelectItem>
      </SelectContent>
    </Select>
  </div>
</div>

        <div>
  <Label htmlFor={`course-benefits-${index}`} className="text-inter font-bold">
    Course Benefits
    <Tooltip content="Enter the benefits of the course">
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="inline-block w-4 h-4 ml-1 text-gray-400"
      />
    </Tooltip>
  </Label>
  <Textarea
    id={`course-benefits-${index}`}
    placeholder="Enter course benefits"
    value={step.courseData?.course?.additional_content?.course_benefits || ''}
    onChange={(e) =>
      handleFieldChange(
        'courseData.course.additional_content.course_benefits',
        e.target.value,
        onStepChange
      )
    }
  />
</div>
<div>
  <Label htmlFor={`course-target-audience-${index}`} className="text-inter font-bold">
    Course Target Audience
    <Tooltip content="Enter the target audience for the course">
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="inline-block w-4 h-4 ml-1 text-gray-400"
      />
    </Tooltip>
  </Label>
  <Textarea
    id={`course-target-audience-${index}`}
    placeholder="Enter target audience"
    value={step.courseData?.course?.additional_content?.course_target_audience || ''}
    onChange={(e) =>
      handleFieldChange(
        'courseData.course.additional_content.course_target_audience',
        e.target.value,
        onStepChange
      )
    }
  />
</div>
<div>
  <Label className="text-inter font-bold">
    Course Duration
    <Tooltip content="Enter the duration of the course in hours and minutes">
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="inline-block w-4 h-4 ml-1 text-gray-400"
      />
    </Tooltip>
  </Label>
  <div className="flex space-x-2 mt-1">
    <Input
      type="number"
      placeholder="Hours"
      value={step.courseData?.course?.additional_content?.course_duration?.hours || ''}
      onChange={(e) =>
        handleFieldChange(
          'courseData.course.additional_content.course_duration.hours',
          e.target.value,
          onStepChange
        )
      }
    />
    <Input
      type="number"
      placeholder="Minutes"
      value={step.courseData?.course?.additional_content?.course_duration?.minutes || ''}
      onChange={(e) =>
        handleFieldChange(
          'courseData.course.additional_content.course_duration.minutes',
          e.target.value,
          onStepChange
        )
      }
    />
  </div>
</div>
<div>
  <Label htmlFor={`course-material-includes-${index}`} className="text-inter font-bold">
    Course Material Includes
    <Tooltip content="List the materials included in the course">
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="inline-block w-4 h-4 ml-1 text-gray-400"
      />
    </Tooltip>
  </Label>
  <Textarea
    id={`course-material-includes-${index}`}
    placeholder="Enter course materials"
    value={step.courseData?.course?.additional_content?.course_material_includes || ''}
    onChange={(e) =>
      handleFieldChange(
        'courseData.course.additional_content.course_material_includes',
        e.target.value,
        onStepChange
      )
    }
  />
</div>



        {/* Topics Section */}
        <Collapsible
          open={!topicsCollapsed}
          onOpenChange={() => setTopicsCollapsed(!topicsCollapsed)}
          className="bg-blue-50 p-4 rounded-md shadow-sm border"
        >
          <CollapsibleTrigger className="flex justify-between items-center w-full py-2">
            <Label className="text-inter font-bold">Topics</Label>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setTopicsCollapsed(!topicsCollapsed)}
            >
              <FontAwesomeIcon
                icon={topicsCollapsed ? faChevronDown : faChevronUp}
              />
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="mt-4">
              <Label className="text-inter font-semibold mb-2">
                Input Method
              </Label>
              <RadioGroup
                value={useJsonTopics ? "json" : "manual"}
                onValueChange={(value) => setUseJsonTopics(value === "json")}
                className="flex space-x-6 mb-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="json" id={`topics-json-${index}`} />
                  <Label htmlFor={`topics-json-${index}`}>Enter JSON</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="manual"
                    id={`topics-manual-${index}`}
                  />
                  <Label htmlFor={`topics-manual-${index}`}>
                    Manually Enter Topics
                  </Label>
                </div>
              </RadioGroup>
              {useJsonTopics ? (
                <Textarea
                id={`course-topics-json-${index}`}
                placeholder="Enter topics JSON"
                value={
                    Array.isArray(step.courseData?.topicsJson)
                      ? JSON.stringify(step.courseData.topicsJson, null, 2)
                      : step.courseData?.topicsJson || ''
                  }
                onChange={(e) =>
                  handleJsonInputChange(
                    "courseData.topicsJson",
                    e.target.value
                  )
                }
              />
              ) : (
                <ManualTopicsInput
                  topics={step.courseData?.topics || []}
                  handleFieldChange={handleFieldChange}
                  onStepChange={onStepChange}
                />
              )}
            </div>
          </CollapsibleContent>
        </Collapsible>

        {/* Lessons Section */}
        <Collapsible
          open={!lessonsCollapsed}
          onOpenChange={() => setLessonsCollapsed(!lessonsCollapsed)}
          className="bg-blue-50 border p-4 rounded-md shadow-sm"
        >
          <CollapsibleTrigger className="flex justify-between items-center w-full py-2">
            <Label className="text-inter font-bold">Lessons</Label>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setLessonsCollapsed(!lessonsCollapsed)}
            >
              <FontAwesomeIcon
                icon={lessonsCollapsed ? faChevronDown : faChevronUp}
              />
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="mt-4">
              <Label className="text-inter font-semibold mb-2">
                Input Method
              </Label>
              <RadioGroup
                value={useJsonLessons ? "json" : "manual"}
                onValueChange={(value) => setUseJsonLessons(value === "json")}
                className="flex space-x-6 mb-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="json" id={`lessons-json-${index}`} />
                  <Label htmlFor={`lessons-json-${index}`}>Enter JSON</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="manual"
                    id={`lessons-manual-${index}`}
                  />
                  <Label htmlFor={`lessons-manual-${index}`}>
                    Manually Enter Lessons
                  </Label>
                </div>
              </RadioGroup>
              {useJsonLessons ? (
                <Textarea
                  id={`course-lessons-json-${index}`}
                  placeholder="Enter lessons JSON"
                  value={
                    Array.isArray(step.courseData?.lessonsJson)
                      ? JSON.stringify(step.courseData.lessonsJson, null, 2)
                      : step.courseData?.lessonsJson || ''
                  }
                  onChange={(e) =>
                    handleJsonInputChange(
                      "courseData.lessonsJson",
                      e.target.value
                    )
                  }
                />
              ) : (
                <ManualLessonsInput
                  lessons={step.courseData?.lessons || []}
                  topics={step.courseData?.topics || []}
                  handleFieldChange={handleFieldChange}
                  onStepChange={onStepChange}
                />
              )}
            </div>
          </CollapsibleContent>
        </Collapsible>

        {/* Quizzes Section */}
        <Collapsible
          open={!quizzesCollapsed}
          onOpenChange={() => setQuizzesCollapsed(!quizzesCollapsed)}
          className="bg-blue-50 border p-4 rounded-md shadow-sm"
        >
          <CollapsibleTrigger className="flex justify-between items-center w-full py-2">
            <Label className="text-inter font-bold">Quizzes</Label>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setQuizzesCollapsed(!quizzesCollapsed)}
            >
              <FontAwesomeIcon
                icon={quizzesCollapsed ? faChevronDown : faChevronUp}
              />
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="mt-4">
              <Label className="text-inter font-semibold mb-2">
                Input Method
              </Label>
              <RadioGroup
                value={useJsonQuizzes ? "json" : "manual"}
                onValueChange={(value) => setUseJsonQuizzes(value === "json")}
                className="flex space-x-6 mb-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="json" id={`quizzes-json-${index}`} />
                  <Label htmlFor={`quizzes-json-${index}`}>Enter JSON</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="manual"
                    id={`quizzes-manual-${index}`}
                  />
                  <Label htmlFor={`quizzes-manual-${index}`}>
                    Manually Enter Quizzes
                  </Label>
                </div>
              </RadioGroup>
              {useJsonQuizzes ? (
                <Textarea
                id={`course-quizzes-json-${index}`}
                placeholder="Enter quizzes JSON"
                value={
                    Array.isArray(step.courseData?.quizzesJson)
                      ? JSON.stringify(step.courseData.quizzesJson, null, 2)
                      : step.courseData?.quizzesJson || ''
                  }
                onChange={(e) =>
                  handleJsonInputChange(
                    "courseData.quizzesJson",
                    e.target.value
                  )
                }
              />
              ) : (
                <ManualQuizzesInput
                  quizzes={step.courseData?.quizzes || []}
                  topics={step.courseData?.topics || []}
                  handleFieldChange={handleFieldChange}
                  onStepChange={onStepChange}
                />
              )}
            </div>
          </CollapsibleContent>
        </Collapsible>

        {/* Quiz Questions Section */}
        <Collapsible
          open={!quizQuestionsCollapsed}
          onOpenChange={() =>
            setQuizQuestionsCollapsed(!quizQuestionsCollapsed)
          }
          className="bg-blue-50 border p-4 rounded-md shadow-sm"
        >
          <CollapsibleTrigger className="flex justify-between items-center w-full py-2">
            <Label className="text-inter font-bold">Quiz Questions</Label>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setQuizQuestionsCollapsed(!quizQuestionsCollapsed)}
            >
              <FontAwesomeIcon
                icon={quizQuestionsCollapsed ? faChevronDown : faChevronUp}
              />
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="mt-4">
              <Label className="text-inter font-semibold mb-2">
                Input Method
              </Label>
              <RadioGroup
                value={useJsonQuizQuestions ? "json" : "manual"}
                onValueChange={(value) =>
                  setUseJsonQuizQuestions(value === "json")
                }
                className="flex space-x-6 mb-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="json"
                    id={`quiz-questions-json-${index}`}
                  />
                  <Label htmlFor={`quiz-questions-json-${index}`}>
                    Enter JSON
                  </Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="manual"
                    id={`quiz-questions-manual-${index}`}
                  />
                  <Label htmlFor={`quiz-questions-manual-${index}`}>
                    Manually Enter Quiz Questions
                  </Label>
                </div>
              </RadioGroup>
              {useJsonQuizQuestions ? (
                <Textarea
                id={`quiz-questions-json-${index}`}
                placeholder="Enter quiz questions JSON"
                value={
                    Array.isArray(step.courseData?.quizQuestionsJson)
                      ? JSON.stringify(step.courseData.quizQuestionsJson, null, 2)
                      : step.courseData?.quizQuestionsJson || ''
                  }                  
                onChange={(e) =>
                  handleJsonInputChange(
                    "courseData.quizQuestionsJson",
                    e.target.value
                  )
                }
              />
              ) : (
                <ManualQuizQuestionsInput
                  quizQuestions={step.courseData?.quizQuestions || []}
                quizzes={step.courseData?.quizzes || []}
                  handleFieldChange={handleFieldChange}
                  onStepChange={onStepChange}
                />
              )}
            </div>
          </CollapsibleContent>
        </Collapsible>
      </div>
    </TooltipProvider>
  );
};

export default SavedCourseFields;
