import React from 'react';
import { Label } from "../../../components/ui/label";
import { Textarea } from "../../../components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Checkbox } from "../../../components/ui/checkbox";

export const WordpressDetailsFields = ({ step, index, onStepChange, onRequestedDetailsChange, wordpressSites }) => (
    <>
      <div>
        <Label htmlFor={`wordpressSiteUrl-${index}`}>WordPress Site URL</Label>
        <Select
          value={step.wordpressSiteUrl || ''}
          onValueChange={(value) => onStepChange('wordpressSiteUrl', value)}
        >
          <SelectTrigger id={`wordpressSiteUrl-${index}`}>
            <SelectValue placeholder="Select WordPress Site" />
          </SelectTrigger>
          <SelectContent>
            {wordpressSites.map(site => (
              <SelectItem key={site.url} value={site.url}>{site.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label htmlFor={`assetType-${index}`}>Asset Type</Label>
        <Select
          value={step.requestedDetails?.assetType || ''}
          onValueChange={(value) => onRequestedDetailsChange('assetType', value)}
        >
          <SelectTrigger id={`assetType-${index}`}>
            <SelectValue placeholder="Select Asset Type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="product">Product</SelectItem>
            <SelectItem value="post">Blog Post</SelectItem>
            <SelectItem value="course">Course</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </>
  );
  
  export const FetchDetailsFields = ({ step, index, onStepChange, onRequestedDetailsChange, wordpressSites }) => (
    <>
      <WordpressDetailsFields 
        step={step} 
        index={index} 
        onStepChange={onStepChange}
        onRequestedDetailsChange={onRequestedDetailsChange}
        wordpressSites={wordpressSites} 
      />
      <div className="space-y-2">
        <Label>Details to Fetch</Label>
        <div className="flex space-x-4">
          <Checkbox 
            id={`fetchCategories-${index}`}
            checked={step.requestedDetails?.fetchCategories || false}
            onCheckedChange={(checked) => onRequestedDetailsChange('fetchCategories', checked)}
          />
          <Label htmlFor={`fetchCategories-${index}`}>Categories</Label>
        </div>
        <div className="flex space-x-4">
          <Checkbox 
            id={`fetchTags-${index}`}
            checked={step.requestedDetails?.fetchTags || false}
            onCheckedChange={(checked) => onRequestedDetailsChange('fetchTags', checked)}
          />
          <Label htmlFor={`fetchTags-${index}`}>Tags</Label>
        </div>
        {(step.requestedDetails?.assetType === 'post' || step.requestedDetails?.assetType === 'course') && (
          <div className="flex space-x-4">
            <Checkbox 
              id={`fetchAuthors-${index}`}
              checked={step.requestedDetails?.fetchAuthors || false}
              onCheckedChange={(checked) => onRequestedDetailsChange('fetchAuthors', checked)}
            />
            <Label htmlFor={`fetchAuthors-${index}`}>
              {step.requestedDetails?.assetType === 'post' ? 'Authors' : 'Instructors'}
            </Label>
          </div>
        )}
      </div>
    </>
  );
  
  export const CreateDetailsFields = ({ step, index, onStepChange, onRequestedDetailsChange, wordpressSites }) => (
    <>
      <WordpressDetailsFields 
        step={step} 
        index={index} 
        onStepChange={onStepChange}
        onRequestedDetailsChange={onRequestedDetailsChange}
        wordpressSites={wordpressSites} 
      />
      <div>
        <Label htmlFor={`assetCategories-${index}`}>Categories (comma-separated)</Label>
        <Textarea
          id={`assetCategories-${index}`}
          placeholder="Enter categories separated by commas"
          value={step.assetCategories?.join(', ') || ''}
          onChange={(e) => onStepChange('assetCategories', e.target.value.split(',').map(item => item.trim()))}
        />
      </div>
      <div>
        <Label htmlFor={`assetTags-${index}`}>Tags (comma-separated)</Label>
        <Textarea
          id={`assetTags-${index}`}
          placeholder="Enter tags separated by commas"
          value={step.assetTags?.join(', ') || ''}
          onChange={(e) => onStepChange('assetTags', e.target.value.split(',').map(item => item.trim()))}
        />
      </div>
    </>
  );