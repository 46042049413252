// src/slices/chatSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentChat: null,
  isLoading: false,
  error: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setCurrentChat: (state, action) => {
      state.currentChat = action.payload;
    },
    addMessage: (state, action) => {
      if (state.currentChat) {
        if (!state.currentChat.messages) {
          state.currentChat.messages = [];
        }
        state.currentChat.messages.push(action.payload);
      } else {
        console.warn('Attempted to add message to non-existent chat');
      }
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    updateChatTitle: (state, action) => {
      if (state.currentChat && state.currentChat._id === action.payload.chatId) {
        state.currentChat.title = action.payload.title;
      }
    },
    setConvertedChat: (state, action) => {
      state.currentChat = action.payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const { setCurrentChat, addMessage, setLoading, setError, updateChatTitle, setConvertedChat } = chatSlice.actions;

export default chatSlice.reducer;