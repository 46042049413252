import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'http://localhost:5000';
let socket;

export const initSocket = (accessToken) => {
  console.log('Initializing socket connection');
  console.log('SOCKET_URL:', SOCKET_URL);
  
  socket = io(SOCKET_URL, {
    auth: { token: accessToken },
    transports: ['websocket'],
    forceNew: true
  });

  socket.on('connect', () => {
    console.log('Connected to socket server');
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from socket server');
  });

  socket.on('connect_error', (error) => {
    console.error('Socket connection error:', error);
    console.error('Error details:', error.message);
  });

  return socket;
};

export const getSocket = () => {
  if (!socket) {
    console.warn('Socket not initialized. Call initSocket first.');
    return null;
  }
  return socket;
};

export const closeSocket = () => {
  if (socket) {
    console.log('Closing socket connection');
    socket.disconnect();
    socket = null;
  } else {
    console.warn('No socket connection to close');
  }
};