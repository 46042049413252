import React, { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover";
import { Button } from "../../components/ui/button";
import { Avatar, AvatarFallback } from "../../components/ui/avatar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const AssistantSelector = ({ 
  selectedAssistant, 
  onAssistantChange, 
  assistants 
}) => {
  const [open, setOpen] = useState(false);
  const [activeGroup, setActiveGroup] = useState(null);

  // Group assistants by type
  const groupedAssistants = React.useMemo(() => {
    const userChatbots = assistants.filter(a => a.type === 'UserChatbot');
    const openAIAssistants = assistants.filter(a => a.type === 'OpenAIAssistant');
    
    const groups = {
      'My Chatbots': userChatbots,
      'Personal Assistants': openAIAssistants.filter(a => a.assistantType === 'personal'),
      'General Assistants': openAIAssistants.filter(a => a.assistantType === 'general'),
      'Specialist Assistants': openAIAssistants.filter(a => a.assistantType === 'specialist'),
      'Other Assistants': openAIAssistants.filter(a => !a.assistantType || a.assistantType === 'other')
    };

    return Object.fromEntries(
      Object.entries(groups).filter(([_, items]) => items.length > 0)
    );
  }, [assistants]);

  const selectedAssistantDetails = assistants.find(a => a._id === selectedAssistant);

  const handleAssistantSelect = (assistantId) => {
    onAssistantChange(assistantId);
    setOpen(false);
    setActiveGroup(null);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {selectedAssistantDetails ? (
            <div className="flex items-center">
              <Avatar className="w-6 h-6 mr-2">
                {selectedAssistantDetails.type === 'OpenAIAssistant' ? (
                  <img 
                    src={selectedAssistantDetails.avatarUrl} 
                    alt={selectedAssistantDetails.name}
                    className="object-cover"
                  />
                ) : (
                  <AvatarFallback className="bg-gray-900">
                    <FontAwesomeIcon icon={faRobot} className="text-white text-sm" />
                  </AvatarFallback>
                )}
              </Avatar>
              <span className="truncate">{selectedAssistantDetails.name}</span>
            </div>
          ) : (
            "Select an assistant..."
          )}
          <FontAwesomeIcon icon={faChevronDown} className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        {Object.entries(groupedAssistants).map(([groupName, groupAssistants]) => (
          <div key={groupName} className="relative">
            <Button 
              variant="ghost" 
              className="w-full justify-between px-3 py-2 text-sm font-medium hover:bg-accent"
              onMouseEnter={() => setActiveGroup(groupName)}
            >
              {groupName}
              <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4 opacity-50" />
            </Button>
            {activeGroup === groupName && (
              <div 
                className="fixed ml-[200px] -mt-[32px] w-[350px] bg-popover border rounded-md shadow-md"
                style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'auto' }}
                onMouseLeave={() => setActiveGroup(null)}
              >
                {groupAssistants.map((assistant) => (
                  <div
                    key={assistant._id}
                    className="p-2 hover:bg-accent cursor-pointer"
                    onClick={() => handleAssistantSelect(assistant._id)}
                  >
                    <div className="flex items-start gap-2">
                      <Avatar className="w-6 h-6 mt-1">
                        {assistant.type === 'OpenAIAssistant' ? (
                          <img 
                            src={assistant.avatarUrl} 
                            alt={assistant.name}
                            className="object-cover"
                          />
                        ) : (
                          <AvatarFallback className="bg-gray-900">
                            <FontAwesomeIcon icon={faRobot} className="text-white text-sm" />
                          </AvatarFallback>
                        )}
                      </Avatar>
                      <div className="flex-1 min-w-0">
                        <div className="font-medium text-sm">{assistant.name}</div>
                        <div className="text-xs text-muted-foreground mt-0.5">
                          {assistant.description || 'No description available'}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default AssistantSelector;