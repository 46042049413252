import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import { Checkbox } from "../ui/checkbox";
import { Button } from "../ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faMinus,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import { FormHeader, Label } from "./SharedComponents";

const WordPressProductForm = ({
  productWordpressSiteUrl,
  setProductWordpressSiteUrl,
  productName,
  setProductName,
  status,
  setStatus,
  regularPrice,
  setRegularPrice,
  salePrice,
  setSalePrice,
  longDescription,
  setLongDescription,
  shortDescription,
  setShortDescription,
  downloadable,
  setDownloadable,
  virtual,
  setVirtual,
    forTutorLMS,
    setForTutorLMS,
  externalUrl,
  setExternalUrl,
  isMoreDetailsOpen,
  setIsMoreDetailsOpen,
  productType,
  setProductType,
  manageStock,
  setManageStock,
  stockQuantity,
  setStockQuantity,
  handleProductWordPressPost,
  config,
  setIsExpanded,
  images,
  setImages,
  categories,
  setCategories,
  tags,
  setTags,
  upsellIds,
  setUpsellIds,
  crossSellIds,
  setCrossSellIds,
  downloads,
  setDownloads,
}) => {
  const addDownload = () => {
    setDownloads([...downloads, { name: "", file: "" }]);
  };

  const removeDownload = (index) => {
    const newDownloads = [...downloads];
    newDownloads.splice(index, 1);
    setDownloads(newDownloads);
  };

  const updateDownload = (index, field, value) => {
    const newDownloads = [...downloads];
    newDownloads[index][field] = value;
    setDownloads(newDownloads);
  };
  return (
    <div className="bg-gray-100 p-2 lg:p-6 rounded-lg shadow-md mb-4">
      <FormHeader
        title="WordPress Product Settings"
        onClose={() => setIsExpanded(false)}
      />
      <div className="space-y-2 lg:space-y-6">
      <Button
          onClick={handleProductWordPressPost}
          className={`${config.color} w-full font-semibold`}
        >
          <FontAwesomeIcon icon={faShoppingBasket} className="mr-2" />Submit Product to WordPress
        </Button>
        <div>
          <Label tooltip="Select the WordPress site where you want to post this product">
            WordPress Site
          </Label>
          <Select
            value={productWordpressSiteUrl}
            onValueChange={setProductWordpressSiteUrl}
          >
            <SelectTrigger className="w-full bg-white">
              <SelectValue placeholder="Select WordPress site" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="https://aheadofthegap.com">
                Ahead of the Gap
              </SelectItem>
              <SelectItem value="https://thatnurselife.com">
                That Nurse Life
              </SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label tooltip="Enter the name of your product">Product Name</Label>
            <Input
              type="text"
              value={productName}
              placeholder="Enter a clear, concise name (e.g., 'Ergonomic Office Chair')"
              onChange={(e) => setProductName(e.target.value)}
              className="bg-white"
            />
          </div>
          <div>
            <Label tooltip="Set the product status">Status</Label>
            <Select value={status} onValueChange={setStatus}>
              <SelectTrigger className="w-full bg-white">
                <SelectValue placeholder="Select status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="draft">Draft</SelectItem>
                <SelectItem value="publish">Publish</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label tooltip="Set the regular price of the product">
              Regular Price
            </Label>
            <Input
              type="text"
              value={regularPrice}
              onChange={(e) => setRegularPrice(e.target.value)}
              className="bg-white"
            />
          </div>
          <div>
            <Label tooltip="Set the sale price, if applicable">
              Sale Price
            </Label>
            <Input
              type="text"
              value={salePrice}
              onChange={(e) => setSalePrice(e.target.value)}
              className="bg-white"
            />
          </div>
        </div>

        <div>
          <Label tooltip="Provide a detailed description of the product">
            Description
          </Label>
          <Textarea
            value={longDescription}
            placeholder="Provide detailed info: features, benefits, specs, use cases. Be thorough!"
            onChange={(e) => setLongDescription(e.target.value)}
            className="bg-white"
          />
        </div>

        <div>
          <Label tooltip="Enter a brief summary of the product">
            Short Description
          </Label>
          <Textarea
            value={shortDescription}
            placeholder="Summarize key features in 2-3 sentences (150-250 characters)"
            onChange={(e) => setShortDescription(e.target.value)}
            className="bg-white"
          />
        </div>

        <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-4">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="virtual"
              checked={virtual}
              onCheckedChange={setVirtual}
            />
            <Label
              htmlFor="virtual"
              tooltip="Mark if the product is virtual (non-physical)"
            >
              Virtual
            </Label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="downloadable"
              checked={downloadable}
              onCheckedChange={setDownloadable}
            />
            <Label
              htmlFor="downloadable"
              tooltip="Mark if the product is downloadable"
            >
              Downloadable
            </Label>
          </div>
          <div className="flex items-center space-x-2">
          <Checkbox
            id="forTutorLMS"
            checked={forTutorLMS}
            onCheckedChange={setForTutorLMS}
          />
          <Label
            htmlFor="forTutorLMS"
            tooltip="Mark if the product is for Tutor LMS"
          >
            For Tutor LMS
          </Label>
        </div>
        </div>

        {/* New fields */}
        <div>
          <Label tooltip="Enter image URLs separated by commas">
            Product Images URLs (Separate multiple URLs with commas. First image will be set as featured image.)
          </Label>
          <Input
            type="text"
            value={images.join(",")}
            placeholder="https://example.com/image1.jpg, https://example.com/image2.jpg"
            onChange={(e) =>
              setImages(e.target.value.split(",").map((url) => url.trim()))
            }
            className="bg-white"
          />
        </div>

        <div>
          <Label tooltip="Add downloadable files for the product">
            Downloads
          </Label>
          {downloads.map((download, index) => (
            <div key={index} className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-4 mb-2">
              <Input
                type="text"
                value={download.name}
                onChange={(e) => updateDownload(index, "name", e.target.value)}
                placeholder="File name"
                className="bg-white flex-1"
              />
              <Input
                type="text"
                value={download.file}
                onChange={(e) => updateDownload(index, "file", e.target.value)}
                placeholder="File URL"
                className="bg-white flex-1"
              />
              <Button
                onClick={() => removeDownload(index)}
                variant="outline"
                size="icon"
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            </div>
          ))}
          <Button onClick={addDownload} variant="outline" className="mt-2">
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add Download
          </Button>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label tooltip="Enter category IDs separated by commas">
              Categories
            </Label>
            <Input
              type="text"
              value={categories.join(",")}
              placeholder="1, 3"
              onChange={(e) =>
                setCategories(
                  e.target.value.split(",").map((id) => parseInt(id.trim()))
                )
              }
              className="bg-white w-full"
            />
          </div>

          <div>
            <Label tooltip="Enter tag IDs separated by commas">Tags</Label>
            <Input
              type="text"
              value={tags.join(",")}
              placeholder="2, 3"
              onChange={(e) =>
                setTags(
                  e.target.value.split(",").map((id) => parseInt(id.trim()))
                )
              }
              className="bg-white w-full"
            />
          </div>

          <div>
            <Label tooltip="Enter upsell product IDs separated by commas">
              Upsell Products
            </Label>
            <Input
              type="text"
              value={upsellIds.join(",")}
              placeholder="120, 369"
              onChange={(e) =>
                setUpsellIds(
                  e.target.value.split(",").map((id) => parseInt(id.trim()))
                )
              }
              className="bg-white w-full"
            />
          </div>

          <div>
            <Label tooltip="Enter cross-sell product IDs separated by commas">
              Cross-sell Products
            </Label>
            <Input
              type="text"
              value={crossSellIds.join(",")}
              placeholder="66, 639"
              onChange={(e) =>
                setCrossSellIds(
                  e.target.value.split(",").map((id) => parseInt(id.trim()))
                )
              }
              className="bg-white w-full"
            />
          </div>
        </div>

        <Collapsible
          open={isMoreDetailsOpen}
          onOpenChange={setIsMoreDetailsOpen}
        >
          <CollapsibleTrigger className="flex items-center cursor-pointer">
            <FontAwesomeIcon
              icon={isMoreDetailsOpen ? faChevronUp : faChevronDown}
              className="mr-2"
            />
            <span className="font-semibold">More Details</span>
          </CollapsibleTrigger>
          <CollapsibleContent className="space-y-4 mt-4">
            <div>
              <Label tooltip="Select the type of product">Product Type</Label>
              <Select value={productType} onValueChange={setProductType}>
                <SelectTrigger className="w-full bg-white">
                  <SelectValue placeholder="Select product type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="simple">Simple</SelectItem>
                  <SelectItem value="variable">Variable</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="manageStock"
                checked={manageStock}
                onCheckedChange={setManageStock}
              />
              <Label
                htmlFor="manageStock"
                tooltip="Enable stock management for this product"
              >
                Manage Stock
              </Label>
            </div>

            {manageStock && (
              <div>
                <Label tooltip="Enter the current stock quantity">
                  Stock Quantity
                </Label>
                <Input
                  type="number"
                  value={stockQuantity}
                  onChange={(e) => setStockQuantity(e.target.value)}
                  className="bg-white"
                />
              </div>
            )}
          </CollapsibleContent>
        </Collapsible>
        <Button
          onClick={handleProductWordPressPost}
          className={`${config.color} w-full font-semibold`}
        >
          <FontAwesomeIcon icon={faShoppingBasket} className="mr-2" />Submit Product to WordPress
        </Button>
      </div>
    </div>
  );
};

export default WordPressProductForm;
