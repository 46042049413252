import React from "react";
import { Label } from "../../../components/ui/label";
import { Textarea } from "../../../components/ui/textarea";
import { Card, CardContent } from "../../../components/ui/card";
import { Input } from "../../../components/ui/input";

const UploadTextFileFields = ({ step, handleFieldChange, index }) => {
  return (
    <Card>
      <CardContent className="pt-6 space-y-4">
        <div>
          <Label
            htmlFor={`asset-data-${index}`}
            className="text-inter font-bold"
          >
            Text Content*
          </Label>
          <Textarea
            id={`asset-data-${index}`}
            placeholder="Enter the text content to be uploaded"
            value={step.assetData || ""}
            onChange={(e) => handleFieldChange("assetData", e.target.value)}
            className="min-h-[200px]"
            required
          />
          <p className="mt-1 text-xs text-gray-500">
            Enter the text content to be uploaded. You can use placeholders
            like &#123;&#123;stepX_outputKey&#125;&#125; to reference outputs
            from previous steps.
          </p>
        </div>
        <div>
          <Label
            htmlFor={`output-key-${index}`}
            className="text-inter font-bold"
          >
            Output Key
          </Label>
          <Input
            id={`output-key-${index}`}
            placeholder="e.g., uploadedTextFileUrl"
            value={step.outputKey || ""}
            onChange={(e) => handleFieldChange("outputKey", e.target.value)}
            required
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default UploadTextFileFields;