export const timezones = [
    { name: 'Pacific/Kiritimati (UTC+14:00)', value: 'Pacific/Kiritimati' },
    { name: 'Pacific/Apia (UTC+13:00)', value: 'Pacific/Apia' },
    { name: 'Pacific/Auckland (UTC+13:00)', value: 'Pacific/Auckland' },
    { name: 'Pacific/Fiji (UTC+12:00)', value: 'Pacific/Fiji' },
    { name: 'Pacific/Noumea (UTC+11:00)', value: 'Pacific/Noumea' },
    { name: 'Australia/Sydney (UTC+10:00)', value: 'Australia/Sydney' },
    { name: 'Asia/Tokyo (UTC+09:00)', value: 'Asia/Tokyo' },
    { name: 'Asia/Seoul (UTC+09:00)', value: 'Asia/Seoul' },
    { name: 'Asia/Shanghai (UTC+08:00)', value: 'Asia/Shanghai' },
    { name: 'Asia/Singapore (UTC+08:00)', value: 'Asia/Singapore' },
    { name: 'Asia/Hong_Kong (UTC+08:00)', value: 'Asia/Hong_Kong' },
    { name: 'Asia/Bangkok (UTC+07:00)', value: 'Asia/Bangkok' },
    { name: 'Asia/Jakarta (UTC+07:00)', value: 'Asia/Jakarta' },
    { name: 'Asia/Kolkata (UTC+05:30)', value: 'Asia/Kolkata' },
    { name: 'Asia/Dubai (UTC+04:00)', value: 'Asia/Dubai' },
    { name: 'Europe/Moscow (UTC+03:00)', value: 'Europe/Moscow' },
    { name: 'Africa/Nairobi (UTC+03:00)', value: 'Africa/Nairobi' },
    { name: 'Europe/Istanbul (UTC+03:00)', value: 'Europe/Istanbul' },
    { name: 'Europe/Athens (UTC+03:00)', value: 'Europe/Athens' },
    { name: 'Europe/Berlin (UTC+02:00)', value: 'Europe/Berlin' },
    { name: 'Europe/Paris (UTC+02:00)', value: 'Europe/Paris' },
    { name: 'Europe/Rome (UTC+02:00)', value: 'Europe/Rome' },
    { name: 'Europe/London (UTC+01:00)', value: 'Europe/London' },
    { name: 'UTC (UTC+00:00)', value: 'UTC' },
    { name: 'Atlantic/Reykjavik (UTC+00:00)', value: 'Atlantic/Reykjavik' },
    { name: 'America/Sao_Paulo (UTC-03:00)', value: 'America/Sao_Paulo' },
    { name: 'America/New_York (UTC-04:00)', value: 'America/New_York' },
    { name: 'America/Toronto (UTC-04:00)', value: 'America/Toronto' },
    { name: 'America/Chicago (UTC-05:00)', value: 'America/Chicago' },
    { name: 'America/Mexico_City (UTC-05:00)', value: 'America/Mexico_City' },
    { name: 'America/Denver (UTC-06:00)', value: 'America/Denver' },
    { name: 'America/Phoenix (UTC-07:00)', value: 'America/Phoenix' },
    { name: 'America/Los_Angeles (UTC-07:00)', value: 'America/Los_Angeles' },
    { name: 'America/Vancouver (UTC-07:00)', value: 'America/Vancouver' },
    { name: 'America/Anchorage (UTC-08:00)', value: 'America/Anchorage' },
    { name: 'Pacific/Honolulu (UTC-10:00)', value: 'Pacific/Honolulu' }
  ];
  