// src/pages/FlowFormComponents/RenderFields/SavedBlogPostFields.js

import React from "react";
import { Label } from "../../../../components/ui/label";
import { Textarea } from "../../../../components/ui/textarea";
import { Input } from "../../../../components/ui/input";

const SavedBlogPostFields = ({ step, handleFieldChange, index }) => {
  const handleCategoriesChange = (e) => {
    const categoriesString = e.target.value;
    const categoriesArray = categoriesString.split(",").map((id) => id.trim());
    handleFieldChange("categories", categoriesArray);
  };

  return (
    <div className="space-y-4">
      <div>
        <Label
          htmlFor={`blogpost-status-${index}`}
          className="text-inter font-bold"
        >
          Status
        </Label>
        <select
          id={`blogpost-status-${index}`}
          value={step.status || "draft"}
          onChange={(e) => handleFieldChange("status", e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="draft">Draft</option>
          <option value="publish">Publish</option>
          <option value="private">Private</option>
        </select>
      </div>
      <div>
        <Label
          htmlFor={`blogpost-excerpt-${index}`}
          className="text-inter font-bold"
        >
          Excerpt
        </Label>
        <Textarea
          id={`blogpost-excerpt-${index}`}
          placeholder="Enter excerpt"
          value={step.excerpt || ""}
          onChange={(e) => handleFieldChange("excerpt", e.target.value)}
        />
      </div>
      <div>
        <Label
          htmlFor={`blogpost-meta-description-${index}`}
          className="text-inter font-bold"
        >
          Meta Description
        </Label>
        <Textarea
          id={`blogpost-meta-description-${index}`}
          placeholder="Enter meta description for SEO"
          value={step.meta_description || ""}
          onChange={(e) =>
            handleFieldChange("meta_description", e.target.value)
          }
          className="h-24"
        />
      </div>
      <div>
        <Label
          htmlFor={`blogpost-author-${index}`}
          className="text-inter font-bold"
        >
          Author ID
        </Label>
        <Input
          id={`blogpost-author-${index}`}
          placeholder="Enter author ID"
          value={step.author || ""}
          onChange={(e) => handleFieldChange("author", e.target.value)}
        />
      </div>
      <div>
        <Label
          htmlFor={`blogpost-categories-${index}`}
          className="text-inter font-bold"
        >
          Categories <span className="text-sm font-normal">(Provide Wordpress category ID number [ex: 110]. Separate multiple categories with commas.)</span>
        </Label>
        <Input
          id={`blogpost-categories-${index}`}
          placeholder="Enter category IDs separated by commas"
          value={
            Array.isArray(step.categories)
              ? step.categories.join(", ")
              : step.categories || ""
          }
          onChange={handleCategoriesChange}
        />
      </div>
      <div>
        <Label
          htmlFor={`blogpost-featured-image-url-${index}`}
          className="text-inter font-bold"
        >
          Featured Image URL
        </Label>
        <Input
          id={`blogpost-featured-image-url-${index}`}
          placeholder="Enter featured image URL"
          value={step.featuredImageUrl || ""}
          onChange={(e) =>
            handleFieldChange("featuredImageUrl", e.target.value)
          }
        />
      </div>
    </div>
  );
};

export default SavedBlogPostFields;
