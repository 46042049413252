import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createSchedule } from '../../slices/schedulesSlice';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import ScheduleConfigForm from './ScheduleConfigForm';
import { timezones } from '../../services/timezones';

const CreateScheduleForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flows } = useSelector((state) => state.flows);
  const [formData, setFormData] = useState({
    flowId: '',
    type: 'hourly',
    config: {
        minutes: [],
        hours: [],
        daysOfMonth: [],
        daysOfWeek: [],
    },
    timezone: 'UTC',
  });
  const [error, setError] = useState(null);

  const handleInputChange = useCallback((e) => {
    setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }));
  }, []);

  const handleSelectChange = useCallback((name, value) => {
    setFormData(prevData => {
      if (name === 'type') {
        return { ...prevData, [name]: value, config: {} };
      }
      return { ...prevData, [name]: value };
    });
  }, []);

  const handleConfigChange = useCallback((config) => {
    setFormData(prevData => ({ ...prevData, config }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(createSchedule(formData)).unwrap();
      navigate('/scheduling');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h2 className="text-inter font-bold text-2xl mb-4">Create New Schedule</h2>
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <div className="flex space-x-4">
        <div className="w-1/2">
          <label htmlFor="flowId" className="block text-sm font-medium text-gray-700">Flow</label>
          <Select name="flowId" onValueChange={(value) => handleSelectChange('flowId', value)} value={formData.flowId}>
            <SelectTrigger>
              <SelectValue placeholder="Select a flow" />
            </SelectTrigger>
            <SelectContent>
              {flows.map((flow) => (
                <SelectItem key={flow._id} value={flow._id}>{flow.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="w-1/2">
          <label htmlFor="timezone" className="block text-sm font-medium text-gray-700">Timezone</label>
          <Select 
            name="timezone" 
            onValueChange={(value) => handleSelectChange('timezone', value)} 
            value={formData.timezone}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select a timezone" />
            </SelectTrigger>
            <SelectContent>
            {timezones.map((tz) => (
              <SelectItem key={tz.value} value={tz.value}>{tz.name}</SelectItem>
            ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div>
        <label htmlFor="type" className="block text-sm font-medium text-gray-700">Schedule Type</label>
        <Select 
          name="type" 
          onValueChange={(value) => handleSelectChange('type', value)} 
          value={formData.type}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select schedule type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="hourly">Hourly</SelectItem>
            <SelectItem value="daily">Daily</SelectItem>
            <SelectItem value="twice_daily">Twice Daily</SelectItem>
            <SelectItem value="weekly">Weekly</SelectItem>
            <SelectItem value="monthly">Monthly</SelectItem>
            <SelectItem value="custom">Custom</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <ScheduleConfigForm
        type={formData.type}
        config={formData.config}
        onChange={handleConfigChange}
      />
      <div className="flex justify-end space-x-2">
        <Button type="button" variant="outline" onClick={() => navigate('/scheduling')}>Cancel</Button>
        <Button type="submit">Create Schedule</Button>
      </div>
    </form>
  );
};

export default CreateScheduleForm;