import React from "react";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Textarea } from "../../../components/ui/textarea";
import { Card, CardContent } from "../../../components/ui/card";
import { RadioGroup, RadioGroupItem } from "../../../components/ui/radio-group";
import { SimplifiedMultiSelect } from "../../../components/ui/simplified-multi-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import { authors, categories, tags } from './constants';

const PostToWordPressFields = ({ step, handleFieldChange, index }) => {
  const handleDateChange = (date) => {
    if (date) {
      handleFieldChange("scheduledAt", format(date, "yyyy-MM-dd'T'HH:mm:ss"));
    } else {
      handleFieldChange("scheduledAt", null);
    }
  };

  const scheduledDate = step.scheduledAt
    ? parse(step.scheduledAt, "yyyy-MM-dd'T'HH:mm:ss", new Date())
    : null;

  return (
    <Card>
      <CardContent className="pt-6 space-y-4">
        <div>
          <Label htmlFor="wordpress-site-url">WordPress Site URL</Label>
          <select
            id="wordpress-site-url"
            value={step.wordpressSiteUrl || ""}
            onChange={(e) =>
              handleFieldChange("wordpressSiteUrl", e.target.value)
            }
            className="w-full p-2 border rounded"
          >
            <option value="">Select WordPress Site URL</option>
            <option value="https://thatnurselife.com">
              https://thatnurselife.com
            </option>
          </select>
        </div>

        <div>
          <Label htmlFor="output-key">Output Key</Label>
          <Input
            id="output-key"
            placeholder="e.g., wordpressPostId"
            value={step.outputKey || ""}
            onChange={(e) => handleFieldChange("outputKey", e.target.value)}
            required
          />
        </div>

        <div>
          <Label>Input Mode</Label>
          <RadioGroup
            value={step.inputMode || "standard"}
            onValueChange={(value) => handleFieldChange("inputMode", value)}
            className="flex space-x-4"
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="standard" id="standard" />
              <Label htmlFor="standard">Standard</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="json" id="json" />
              <Label htmlFor="json">JSON</Label>
            </div>
          </RadioGroup>
        </div>

        {(step.inputMode || "standard") === "standard" ? (
          <>
            <div>
              <Label htmlFor="post-title">Post Title</Label>
              <Input
                id="post-title"
                placeholder="Enter post title"
                value={step.postTitle || ""}
                onChange={(e) =>
                  handleFieldChange("postTitle", e.target.value)
                }
                required
              />
            </div>

            <div>
              <Label htmlFor="post-content">Post Content</Label>
              <Textarea
                id="post-content"
                placeholder="Enter post content (can use placeholders like {{previousStep}})"
                value={step.postContent || ""}
                onChange={(e) =>
                  handleFieldChange("postContent", e.target.value)
                }
                className="min-h-[200px]"
                required
              />
            </div>

            <div>
              <Label htmlFor="excerpt">Excerpt</Label>
              <Textarea
                id="excerpt"
                placeholder="Enter post excerpt"
                value={step.excerpt || ""}
                onChange={(e) => handleFieldChange("excerpt", e.target.value)}
              />
            </div>

            <div>
              <Label htmlFor="author">Author</Label>
              <select
                id="author"
                value={step.author || ""}
                onChange={(e) => handleFieldChange("author", e.target.value)}
                className="w-full p-2 border rounded"
                required
              >
                <option value="">Select Author</option>
                {authors.map((author) => (
                  <option key={author.value} value={author.value}>
                    {author.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <Label htmlFor="categories">Categories</Label>
              <SimplifiedMultiSelect
                options={categories}
                value={step.categories || []}
                onChange={(value) => handleFieldChange("categories", value)}
                placeholder="Select Categories"
              />
            </div>

            <div>
              <Label htmlFor="tags">Tags</Label>
              <SimplifiedMultiSelect
                options={tags}
                value={step.tags || []}
                onChange={(value) => handleFieldChange("tags", value)}
                placeholder="Select Tags"
              />
            </div>
          </>
        ) : (
          <div>
            <Label htmlFor="json-reference">JSON Reference</Label>
            <Input
              id="json-reference"
              placeholder="JSON Reference (e.g., {{previousStepOutput}})"
              value={step.jsonReference || ""}
              onChange={(e) =>
                handleFieldChange("jsonReference", e.target.value)
              }
              required
            />
          </div>
        )}

        <div>
          <Label htmlFor="featured-image">Featured Image URL</Label>
          <Input
            id="featured-image"
            placeholder="Enter featured image URL"
            value={step.featuredImage || ""}
            onChange={(e) =>
              handleFieldChange("featuredImage", e.target.value)
            }
          />
          <p className="mt-1 text-xs text-gray-500">
            Enter the URL of the image you want to set as the featured image
            for this post.
          </p>
        </div>

        <div>
          <Label htmlFor="datePicker">Schedule Post (optional)</Label>
          <DatePicker
            selected={scheduledDate}
            onChange={handleDateChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="MMMM d, yyyy h:mm aa"
            className="w-full p-2 border rounded ml-2"
            placeholderText="Select date and time"
          />
        </div>

        <div>
          <Label htmlFor="post-status">Post Status</Label>
          <select
            id="post-status"
            value={step.postStatus || "draft"}
            onChange={(e) => handleFieldChange("postStatus", e.target.value)}
            className="w-full p-2 border rounded"
            required
          >
            <option value="draft">Draft</option>
            <option value="publish">Publish</option>
          </select>
        </div>
      </CardContent>
    </Card>
  );
};

export default PostToWordPressFields;