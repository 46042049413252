import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSchedule } from '../../slices/schedulesSlice';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import { timezones } from '../../services/timezones';

const EditScheduleForm = ({ schedule, onCancel }) => {
  const dispatch = useDispatch();
  const { flows } = useSelector((state) => state.flows);
  const [formData, setFormData] = useState({
    type: '',
    config: {},
    timezone: '',
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (schedule) {
      setFormData({
        flowId: schedule.flow._id,
        type: schedule.type,
        config: schedule.config,
        timezone: schedule.timezone,
      });
    }
  }, [schedule]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(updateSchedule({ id: schedule._id, scheduleData: formData })).unwrap();
      onCancel(); // Close the edit form
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h2 className="text-inter font-bold text-2xl mb-4">Edit Schedule</h2>
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {/*<div>
        <label htmlFor="flowId" className="block text-sm font-medium text-gray-700">Flow</label>
        <Select 
          name="flowId" 
          onValueChange={(value) => handleSelectChange('flowId', value)} 
          value={formData.flowId} // This ensures the current flow is selected
          defaultValue={schedule.flow._id} // This sets the initial selected value
        >
          <SelectTrigger>
            <SelectValue placeholder="Select a flow" />
          </SelectTrigger>
          <SelectContent>
            {flows.map((flow) => (
              <SelectItem key={flow._id} value={flow._id}>{flow.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>*/}
      <div>
        <label htmlFor="type" className="block text-sm font-medium text-gray-700">Schedule Type</label>
        <Select name="type" onValueChange={(value) => handleSelectChange('type', value)} value={formData.type}>
          <SelectTrigger>
            <SelectValue placeholder="Select schedule type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="hourly">Hourly</SelectItem>
            <SelectItem value="daily">Daily</SelectItem>
            <SelectItem value="twice_daily">Twice Daily</SelectItem>
            <SelectItem value="weekly">Weekly</SelectItem>
            <SelectItem value="monthly">Monthly</SelectItem>
            <SelectItem value="custom">Custom</SelectItem>
          </SelectContent>
        </Select>
      </div>
      {/* Add more input fields for config based on the selected type */}
      <div>
        <label htmlFor="timezone" className="block text-sm font-medium text-gray-700">Timezone</label>
        <Select 
          name="timezone" 
          onValueChange={(value) => handleSelectChange('timezone', value)} 
          value={formData.timezone}
          defaultValue={schedule.timezone}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select a timezone" />
          </SelectTrigger>
          <SelectContent>
            {timezones.map((tz) => (
              <SelectItem key={tz.value} value={tz.value}>{tz.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="flex justify-end space-x-2">
        <Button type="button" variant="outline" onClick={onCancel}>Cancel</Button>
        <Button type="submit">Update Schedule</Button>
      </div>
    </form>
  );
};

export default EditScheduleForm;