import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserTeams, setSelectedTeam } from '../slices/teamsSlice';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';

const TeamSelector = () => {
  const dispatch = useDispatch();
  const { teams, selectedTeam } = useSelector((state) => state.teams);

  useEffect(() => {
    dispatch(fetchUserTeams());
  }, [dispatch]);

  const handleTeamChange = (value) => {
    dispatch(setSelectedTeam(value));
  };

  return (
    <Select onValueChange={handleTeamChange} value={selectedTeam}>
      <SelectTrigger className="w-full text-gray-100 bg-gray-900">
        <SelectValue placeholder="Select Team" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all">All Teams</SelectItem>
        <SelectItem value="personal">Personal</SelectItem>
        {teams.map((team) => (
          <SelectItem key={team._id} value={team._id}>
            {team.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default TeamSelector;