import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications } from "../slices/notificationsSlice";
import { logout } from "../slices/authSlice";
import { cn } from "../lib/utils";
import { Button } from "../components/ui/button";
import {
  Home,
  Globe,
  Plus,
  List,
  Clock,
  LogOut,
  Users,
  ChevronLeft,
  ChevronRight,
  Inbox,
  FileText,
  Share2,
  Settings,
  Image,
  BotMessageSquare,
  ImagePlus,
  GitBranchPlus,
  ChevronDown,
  ChevronUp,
  ShoppingBasket,
  GraduationCap,
  Blinds,
  CaseSensitive,
  MonitorX,
  Menu,
  X,
} from "lucide-react";
import TeamSelector from "../components/TeamSelector";
import { fetchAppFeatures } from "../slices/appFeaturesSlice";
import { MagicWandIcon } from "@radix-ui/react-icons";

const Layout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sectionStates, setSectionStates] = useState({
    management: true,
    workspace: true,
    savedAssets: false,
    admin: false,
  });
  const [isHoverExpanded, setIsHoverExpanded] = useState(false);
  // Add ref for sidebar
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notifications } = useSelector((state) => state.notifications);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleMouseEnter = useCallback(() => {
    // Only expand on hover if the sidebar is collapsed and we're on desktop
    if (isCollapsed && window.innerWidth >= 768) {
      setIsHoverExpanded(true);
    }
  }, [isCollapsed]);

  const handleMouseLeave = useCallback(() => {
    setIsHoverExpanded(false);
  }, []);

  const fetchNotificationsCallback = useCallback(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchNotificationsCallback();
      // Uncomment the next line if you want to fetch app features as well
      // dispatch(fetchAppFeatures());
    }
  }, [isAuthenticated, fetchNotificationsCallback]);

  const unreadCount = notifications.filter(
    (n) => !n.isRead && !n.isArchived
  ).length;

  const handleLogout = async () => {
    try {
      await dispatch(logout()).unwrap();
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const toggleSidebar = useCallback(() => {
    setIsCollapsed(prev => !prev);
    setIsHoverExpanded(false); // Reset hover state when manually toggling
  }, []);

  const toggleSection = (section) => {
    setSectionStates((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const SectionHeader = ({ text, section, isOpen }) => (
    <button
        onClick={() => toggleSection(section)}
        className={cn(
            "w-full flex items-center justify-between px-3 md:px-2 py-3 md:py-1", // Increased padding on mobile
            "text-sm md:text-xs font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wider hover:bg-gray-100 dark:hover:bg-gray-700",
            isCollapsed && "justify-between"
        )}
    >
        {isCollapsed && !isHoverExpanded ? (
      isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />
    ) : (
      <>
        {text}
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </>
    )}
    </button>
);

  const SectionContent = ({ children, isOpen }) =>
    isOpen ? <div className="p-2">{children}</div> : null;

  return (
    <div className="flex h-screen overflow-hidden">
      <div className="md:hidden fixed top-0 left-0 right-0 h-12 bg-gray-900 border-b border-gray-700 flex items-center justify-between px-4 z-40">
        <div className="flex items-center">
          <img
            src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/FlowOrchestraIcon4.png"
            alt="Flow Orchestra"
            className="h-8 w-8 mr-2"
          />
          <h1 className="text-lg font-semibold text-white">Flow Orchestra</h1>
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={toggleSidebar}
          className="text-gray-300 hover:text-gray-800"
        >
          {!isCollapsed ? (
            <X className="h-6 w-6" />
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </Button>
      </div>
      <aside
    ref={sidebarRef}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    className={cn(
      "flex flex-col bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 transition-all duration-300",
      (isCollapsed && !isHoverExpanded) ? "w-16 hidden md:flex" : "w-64",
      "fixed md:relative",
      "z-50",
      "h-full",
      (!isCollapsed || isHoverExpanded) && "w-[85%] md:w-64"
    )}
  >
        {(!isCollapsed || isHoverExpanded) && <TeamSelector />}
        <div className="flex-1 overflow-y-auto">
          <SectionHeader
            text="Management"
            section="management"
            isOpen={sectionStates.management}
          />
          <SectionContent isOpen={sectionStates.management}>
            <nav className="flex flex-col gap-1" onClick={toggleSidebar}>
              <NavLink
                to="/"
                icon={<Home className="h-4 w-4 text-blue-600" />}
                text="Dashboard"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/create-flow"
                icon={<Plus className="h-4 w-4 text-yellow-600" />}
                text="Create Flow"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/execution"
                icon={<GitBranchPlus className="h-4 w-4 text-green-600" />}
                text="Executions"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/manage-flows"
                icon={<List className="h-4 w-4 text-orange-600" />}
                text="Manage Flows"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/scheduling"
                icon={<Clock className="h-4 w-4 text-purple-600" />}
                text="Scheduling"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
            </nav>
          </SectionContent>

          <div className="mx-2 my-2 border-t border-gray-200 dark:border-gray-700" />
          <SectionHeader
            text="Workspace"
            section="workspace"
            isOpen={sectionStates.workspace}
          />
          <SectionContent isOpen={sectionStates.workspace}>
            <nav className="flex flex-col gap-1" onClick={toggleSidebar}>
              <NavLink
                to="/chat"
                icon={<BotMessageSquare className="h-4 w-4 text-rose-600" />}
                text="Chat"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/custom-assistants"
                icon={<MagicWandIcon className="h-4 w-4 text-violet-600" />}
                text="Custom Assistants"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/image-generation"
                icon={<ImagePlus className="h-4 w-4 text-amber-600" />}
                text="Generate Images"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
            </nav>
          </SectionContent>

          <div className="mx-2 my-2 border-t border-gray-200 dark:border-gray-700" />
          <SectionHeader
            text="Saved Assets"
            section="savedAssets"
            isOpen={sectionStates.savedAssets}
          />
          <SectionContent isOpen={sectionStates.savedAssets}>
            <nav className="flex flex-col gap-1" onClick={toggleSidebar}>
              <NavLink
                to="/assets/inbox"
                icon={<Inbox className="h-4 w-4" />}
                text="Inbox"
                isCollapsed={isCollapsed && !isHoverExpanded}
                badge={unreadCount > 0 ? unreadCount : null}
              />
              <NavLink
                to="/assets/blog-posts"
                icon={<FileText className="h-4 w-4 text-lime-600" />}
                text="Blog Posts"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/assets/courses"
                icon={<GraduationCap className="h-4 w-4 text-blue-600" />}
                text="Courses"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/assets/general-assets"
                icon={<Globe className="h-4 w-4 text-emerald-600" />}
                text="General"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/assets/general-images"
                icon={<Image className="h-4 w-4 text-pink-600" />}
                text="Images"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/assets/products"
                icon={<ShoppingBasket className="h-4 w-4 text-indigo-600" />}
                text="Products"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/assets/social-posts"
                icon={<Share2 className="h-4 w-4 text-fuchsia-600" />}
                text="Social Posts"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
            </nav>
          </SectionContent>

          <div className="mx-2 my-2 border-t border-gray-200 dark:border-gray-700" />
          <SectionHeader
            text="Admin"
            section="admin"
            isOpen={sectionStates.admin}
          />
          <SectionContent isOpen={sectionStates.admin}>
            <nav className="flex flex-col gap-1" onClick={toggleSidebar}>
              <NavLink
                to="/manage-teams"
                icon={<Users className="h-4 w-4 text-yellow-600" />}
                text="Manage Teams"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/privacy-policy"
                icon={<Blinds className="h-4 w-4 text-sky-600" />}
                text="Privacy Policy"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/terms-of-service"
                icon={<CaseSensitive className="h-4 w-4 text-teal-600" />}
                text="Terms of Service"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
              <NavLink
                to="/data-deletion"
                icon={<MonitorX className="h-4 w-4 text-rose-600" />}
                text="Data Deletion"
                isCollapsed={isCollapsed && !isHoverExpanded}
              />
            </nav>
          </SectionContent>
        </div>
        {/* Replace the separate logout button and collapse section with this combined version */}
        <div className="border-t border-gray-400 dark:border-gray-700">
          <div
            className={cn(
              "flex",
              (isCollapsed && !isHoverExpanded) ? "flex-col" : "gap-10" // Stack vertically when collapsed, horizontal with gap when expanded
            )}
          >
            <Button
              variant="ghost"
              onClick={handleLogout}
              className={cn(
                "p-2 font-semibold text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700",
                (isCollapsed && !isHoverExpanded) ? "justify-center" : "flex-1 justify-start"
              )}
            >
              <LogOut className={cn("h-5 w-5", (isCollapsed && !isHoverExpanded) ? "" : "mr-2")} />
              {(!isCollapsed || isHoverExpanded) && "Logout"}
            </Button>
            <Button
              variant="ghost"
              onClick={toggleSidebar}
              className="p-2 text-gray-500 hover:bg-gray-200 dark:hover:bg-gray-700 justify-center"
            >
              {isCollapsed ? (
                <ChevronRight size={20} />
              ) : (
                <ChevronLeft size={20} />
              )}
            </Button>
          </div>
        </div>
        <div className="flex items-center h-10 px-4 pl-3 bg-gray-900 text-white rounded-md border border-gray-200">
          <img
            src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/FlowOrchestraIcon4.png"
            alt="Flow Orchestra"
            className={cn("h-8 w-8", (!isCollapsed || isHoverExpanded) && "mr-2")}
          />
          {(!isCollapsed || isHoverExpanded) && (
            <h1 className="text-lg font-semibold">Flow Orchestra</h1>
          )}
        </div>
      </aside>
      {!isCollapsed && (
        <div
          className="fixed inset-0 bg-black/50 md:hidden" // Dark overlay visible only on mobile
          onClick={toggleSidebar} // Allows clicking outside to collapse
        />
      )}
      <main
        className={cn(
          "flex-1 overflow-y-auto bg-gray-100 dark:bg-gray-900",
          !isCollapsed && "hidden md:block",
          "md:pt-0 pt-16" // Add padding-top on mobile for the header
        )}
      >
        <div className="p-6 pt-0 pb-0">{children}</div>
      </main>
    </div>
  );
};

const NavLink = ({ to, icon, text, isCollapsed, badge }) => {
    const location = useLocation();
    const isActive = 
        (to === '/chat' && location.pathname.startsWith('/chat')) ||
        (to === '/execution' && location.pathname.startsWith('/execution')) ||
        location.pathname === to;

    return (
        <Button
            asChild
            variant="ghost"
            className={cn(
                "w-full justify-start font-semibold text-gray-700 dark:text-gray-300",
                "md:py-2 py-4", // Increased padding on mobile
                "text-base md:text-sm", // Larger text on mobile
                isActive 
                    ? "bg-gray-900 text-white hover:bg-gray-800 hover:text-white" 
                    : "hover:bg-gray-200 dark:hover:bg-gray-700",
                isCollapsed && "justify-center"
            )}
        >
            <Link to={to} className="flex items-center w-full">
                <span className={cn("flex-shrink-0", isCollapsed ? "mr-0" : "mr-3")}>
                    {icon} {/* Keep original icon without modifying it */}
                </span>
                {!isCollapsed && (
                    <>
                        <span className="whitespace-nowrap">{text}</span>
                        {badge && (
                            <span className="ml-auto bg-gray-400 text-white text-xs font-bold px-2 py-1 rounded-full">
                                {badge}
                            </span>
                        )}
                    </>
                )}
            </Link>
        </Button>
    );
};

export default Layout;