import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { formatDistanceToNow } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faChevronLeft, faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../components/ui/button';
import { Pagination } from '../../components/ui/pagination';
import { Input } from '../../components/ui/input';
import { fetchChatHistory } from '../../services/api';
import { setError } from '../../slices/chatSlice';

const ChatHistorySidebar = ({ isOpen, toggleSidebar }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [chatHistory, setChatHistory] = useState([]);
    const [filteredChatHistory, setFilteredChatHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25); // Assuming 10 items per page
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
 
    useEffect(() => {
      loadChatHistory(currentPage);
    }, [currentPage]);
 
    useEffect(() => {
      filterChatHistory();
    }, [searchTerm, chatHistory]);

    const loadChatHistory = async (page) => {
      try {
        const response = await fetchChatHistory(page, itemsPerPage);
        console.log('Chat history:', response.data);
        setChatHistory(response.data.chats);
        setTotalItems(response.data.total);
      } catch (err) {
        dispatch(setError('Failed to load chat history'));
      }
    };
 
    const filterChatHistory = () => {
      const filtered = chatHistory.filter(chat => 
        chat.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredChatHistory(filtered);
    };

    const handleChatSelect = (chatId) => {
      navigate(`/chat/${chatId}`);
      toggleSidebar();
    };
 
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };
 
    return (
      <div className={`bg-white shadow-lg transition-all duration-300 ${isOpen ? 'w-96' : 'w-12'} flex flex-col`}>
        <Button
          onClick={toggleSidebar}
          className="w-full"
        >
          <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
        </Button>
       
        {isOpen ? (
          <div className="flex-grow flex flex-col overflow-hidden">
            <h2 className="text-inter font-bold text-xl p-4">Chat History</h2>
            <div className="px-4 mb-4">
              <div className="relative">
                <Input
                  type="text"
                  placeholder="Search chats..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="pl-10 pr-4 py-2 w-full"
                />
                <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            <div className="flex-grow overflow-y-auto px-4">
              {filteredChatHistory && filteredChatHistory.length > 0 ? (
                filteredChatHistory.map((chat) => (
                  <div
                    key={chat._id}
                    onClick={() => handleChatSelect(chat._id)}
                    className="cursor-pointer hover:bg-gray-100 p-2 rounded mb-0 border-gray-200"
                  >
                    <h3 className="text-sm font-semibold truncate">{chat.title}</h3>
                    <p className="text-sm text-gray-500">
                      {formatDistanceToNow(new Date(chat.lastMessageDate), { addSuffix: true })}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No matching chats found.</p>
              )}
            </div>
            <div className="p-4">
            <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={handlePageChange}
          />
            </div>
          </div>
        ) : (
          <div className="flex-grow flex items-center justify-center cursor-pointer lg:mt-2 lg:py-2" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faHistory} className="text-xl" />
          </div>
        )}
      </div>
    );
  };
 
  export default ChatHistorySidebar;