import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { ArrowUpDown } from "lucide-react";

const SortDropdown = ({ sortBy, onSortChange }) => (
  <div className="flex items-center gap-2">
    <div className="flex items-center text-sm text-muted-foreground">
      <ArrowUpDown className="w-4 h-4 mr-1" />
      Order by
    </div>
    <Select value={sortBy} onValueChange={onSortChange}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Sort by..." />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="name">Name</SelectItem>
        <SelectItem value="steps">Number of Steps</SelectItem>
        <SelectItem value="newest">Newest First</SelectItem>
      </SelectContent>
    </Select>
  </div>
);

export default SortDropdown;
