import React, { useCallback, memo, useEffect, useState } from "react";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { X, Info } from "lucide-react";
import { Textarea } from "../../components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Card, CardContent } from "../../components/ui/card";
import { GenerateStepType, getStepInstructions } from "./stepTypes";
import AssistantDetails from "../../components/AssistantDetails";
import ActionStepFields from "./ActionStepFields";
import {
  FetchDetailsFields,
  CreateDetailsFields,
} from "./RenderFields/WordpressDetailsFields";
import { useDispatch, useSelector } from "react-redux";
import { fetchItems } from "../../slices/genericItemsSlice";

const SPECIAL_ASSISTANT_IDS = [
  "66c5218f27162b27c4445360",
  "66c5238d27162b27c4445362",
  "66d50dfacd9b0f4837effcc8",
];
const CHARACTER_LIMIT = 5000;

const CharacterCounter = ({ currentLength, limit }) => {
  const remaining = limit - currentLength;
  const isCloseToLimit = remaining < 500; // Highlight when near the limit
  return (
    <p
      className={`mt-1 text-xs ${
        isCloseToLimit ? "text-red-500" : "text-gray-500"
      }`}
      aria-live="polite"
    >
      {remaining >= 0
        ? `${remaining} characters remaining`
        : `Character limit exceeded by ${-remaining}`}
    </p>
  );
};

const GenerateStepFields = ({
  step,
  index,
  onStepChange,
  assistants,
  generatePreviousStepPlaceholders,
  isFirstStep,
}) => {
  const dispatch = useDispatch();
  const writingStyles = useSelector(
    (state) => state.genericItems.items["WritingStyle"] || []
  );
  const isLoadingWritingStyles = useSelector(
    (state) => state.genericItems.isLoading["WritingStyle"] || false
  );
  const writingStylesError = useSelector(
    (state) => state.genericItems.error["WritingStyle"]
  );

  const [textValue, setTextValue] = useState(step.prompt || "");

  const selectedAssistant =
    assistants && assistants.find((a) => a._id === step.assistantId);
  const previousStepPlaceholders = generatePreviousStepPlaceholders(index);
  const isSpecialAssistant = SPECIAL_ASSISTANT_IDS.includes(step.assistantId);
  const isAssetTypeAssistant = step.assistantId === "66d50dfacd9b0f4837effcc8";

  useEffect(() => {
    if (!writingStyles.length) {
      dispatch(fetchItems("WritingStyle"));
    }
  }, [dispatch]);

  const handleTextChange = (field, value) => {
    if (value.length <= CHARACTER_LIMIT) {
      onStepChange(field, value);
      setTextValue(value);
    }
  };

  const wordpressSites = [
    { name: "Ahead of the Gap", url: "https://aheadofthegap.com" },
    { name: "That Nurse Life", url: "https://thatnurselife.com" },
  ];

  const handleAssistantChange = (assistantId) => {
    onStepChange("assistantId", assistantId);

    // Find the new selected assistant
    const selectedAssistant = assistants.find((a) => a._id === assistantId);

    if (selectedAssistant) {
      if (!selectedAssistant.styleSelector) {
        // The new assistant does not allow style selection
        onStepChange("writingStyleId", null);
      }
    }

    // Existing logic for special assistants
    if (SPECIAL_ASSISTANT_IDS.includes(assistantId)) {
      onStepChange("prompt", "Work your magic!");
    }
    if (assistantId === "66d50dfacd9b0f4837effcc8") {
      onStepChange("assetType", "");
    } else {
      onStepChange("assetType", undefined);
    }
  };

  const handleAssetTypeChange = (assetType) => {
    onStepChange("assetType", assetType);
    onStepChange("prompt", `Work your magic! Asset type: ${assetType}`);
  };

  const handleRequestedDetailsChange = (field, value) => {
    onStepChange("requestedDetails", {
      ...step.requestedDetails,
      [field]: value,
    });
  };

  const handleClearTextarea = useCallback(
    (field) => {
      onStepChange(field, "");
    },
    [onStepChange]
  );

  const TextareaWithCounter = ({ id, placeholder, value, onChange, field }) => {
    const handleChange = (e) => {
      onChange(field, e.target.value);
    };
  
    return (
      <div className="relative">
        <Textarea
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          aria-describedby={`${id}-counter`}
          className="pr-10"
        />
        <CharacterCounter
          currentLength={value.length}
          limit={CHARACTER_LIMIT}
        />
      </div>
    );
  };

  TextareaWithCounter.displayName = "TextareaWithCounter";

  const TextareaWithClearButton = ({ id, placeholder, value, onChange, field }) => {
    const handleChange = (e) => {
      onChange(field, e.target.value);
    };
  
    const handleClear = () => {
      onChange(field, "");
    };
  
    return (
      <div className="relative">
        <Textarea
          id={id}
          placeholder={placeholder}
          value={value || ""}
          onChange={handleChange}
          className="pr-10"
        />
        {value && (
          <Button
            type="button"
            variant="ghost"
            size="sm"
            className="absolute right-2 top-2 text-gray-400 hover:text-gray-600"
            onClick={handleClear}
          >
            <X className="h-4 w-4" />
          </Button>
        )}
      </div>
    );
  };

  TextareaWithClearButton.displayName = "TextareaWithClearButton";

  if (step.category === "action") {
    return (
      <ActionStepFields step={step} onStepChange={onStepChange} index={index} />
    );
  }

  return (
    <div className="space-y-4">
      <Card>
        <CardContent className="p-2 lg:p-4 lg:pt-2">
          <div className="flex flex-col lg:flex-row items-start space-x-2 mb-2 lg:mb-4">
            <Info className="hidden lg:flex w-5 h-5 text-gray-500 mt-0.5 flex-shrink-0" />
            <p className="text-sm text-gray-500">
              {getStepInstructions(step.type)}
            </p>
          </div>
          <div className="space-y-2 lg:space-y-4">
            <div>
              <Label htmlFor={`outputKey-${index}`}>Output Key</Label>
              <Input
                id={`outputKey-${index}`}
                placeholder="e.g., userData, processedData"
                value={step.outputKey || ""}
                onChange={(e) => onStepChange("outputKey", e.target.value)}
                required
              />
              <p className="mt-1 text-xs text-gray-500">
                This key will be used to access this step's output in later
                steps.
              </p>
            </div>

            {step.type === GenerateStepType.API_CALL && (
              <>
                <div>
                  <Label htmlFor={`url-${index}`}>URL</Label>
                  <Input
                    id={`url-${index}`}
                    placeholder="Enter API URL"
                    value={step.url || ""}
                    onChange={(e) => onStepChange("url", e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor={`method-${index}`}>Method</Label>
                  <Select
                    value={step.method || "GET"}
                    onValueChange={(value) => onStepChange("method", value)}
                  >
                    <SelectTrigger id={`method-${index}`}>
                      <SelectValue placeholder="Select method" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="GET">GET</SelectItem>
                      <SelectItem value="POST">POST</SelectItem>
                      <SelectItem value="PUT">PUT</SelectItem>
                      <SelectItem value="DELETE">DELETE</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </>
            )}

            {step.type === GenerateStepType.TRANSFORMATION && (
              <div>
                <Label htmlFor={`transformation-${index}`}>
                  Transformation Code
                </Label>
                <Textarea
                  id={`transformation-${index}`}
                  placeholder="Enter transformation code"
                  value={step.transformationCode || ""}
                  onChange={(e) =>
                    onStepChange("transformationCode", e.target.value)
                  }
                  required
                />
              </div>
            )}

            {step.type === GenerateStepType.CONDITIONAL && (
              <>
                <div>
                  <Label htmlFor={`condition-${index}`}>Condition</Label>
                  <Input
                    id={`condition-${index}`}
                    placeholder="Enter condition"
                    value={step.condition || ""}
                    onChange={(e) => onStepChange("condition", e.target.value)}
                    required
                  />
                  <p className="text-sm text-gray-500 mt-1">
                    {`Use {{placeholders}} to reference previous step outputs. For string comparisons, use === or !==.
    For numerical comparisons, use >, <, >=, <=, ==, or !=.`}
                  </p>
                </div>
                <div>
                  <Label htmlFor={`trueStep-${index}`}>True Step</Label>
                  <Input
                    id={`trueStep-${index}`}
                    placeholder="Enter true step number"
                    type="number"
                    value={step.trueStep || ""}
                    onChange={(e) => onStepChange("trueStep", e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor={`falseStep-${index}`}>False Step</Label>
                  <Input
                    id={`falseStep-${index}`}
                    placeholder="Enter false step number"
                    type="number"
                    value={step.falseStep || ""}
                    onChange={(e) => onStepChange("falseStep", e.target.value)}
                    required
                  />
                </div>
              </>
            )}

            {step.type === GenerateStepType.LOOP && (
              <>
                <div>
                  <Label htmlFor={`loopType-${index}`}>Loop Type</Label>
                  <Select
                    value={step.loopType || "count"}
                    onValueChange={(value) => onStepChange("loopType", value)}
                  >
                    <SelectTrigger id={`loopType-${index}`}>
                      <SelectValue placeholder="Select loop type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="count">
                        Loop specified number of times
                      </SelectItem>
                      <SelectItem value="condition">
                        Loop until condition is met
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                {step.loopType === "count" ? (
                  <div>
                    <Label htmlFor={`loopCount-${index}`}>Loop Count</Label>
                    <Input
                      id={`loopCount-${index}`}
                      placeholder="Enter number of loops"
                      type="number"
                      min="1"
                      value={step.loopCount || "1"}
                      onChange={(e) =>
                        onStepChange("loopCount", e.target.value)
                      }
                      required
                    />
                  </div>
                ) : (
                  <div>
                    <Label htmlFor={`loopCondition-${index}`}>
                      Loop Condition
                    </Label>
                    <Input
                      id={`loopCondition-${index}`}
                      placeholder="Enter loop condition"
                      value={step.loopCondition || ""}
                      onChange={(e) =>
                        onStepChange("loopCondition", e.target.value)
                      }
                      required
                    />
                  </div>
                )}
                <div>
                  <Label htmlFor={`loopStep-${index}`}>Loop Step</Label>
                  <Input
                    id={`loopStep-${index}`}
                    placeholder="Enter loop step number"
                    type="number"
                    value={step.loopStep || ""}
                    onChange={(e) => onStepChange("loopStep", e.target.value)}
                    required
                  />
                </div>
              </>
            )}

            {(step.type === GenerateStepType.AI_REQUEST ||
              step.type === GenerateStepType.AI_CHECK_AND_IMPROVE ||
              step.type === GenerateStepType.AI_CONDITIONAL_CHECKER) && (
              <>
                {step.type === GenerateStepType.AI_REQUEST &&
                  assistants &&
                  assistants.length > 0 && (
                    <div>
                      <Label htmlFor={`assistant-${index}`}>AI Assistant</Label>
                      <Select
                        value={step.assistantId || ""}
                        onValueChange={handleAssistantChange}
                      >
                        <SelectTrigger id={`assistant-${index}`}>
                          <SelectValue placeholder="Select AI Assistant" />
                        </SelectTrigger>
                        <SelectContent>
                          {assistants.map((assistant) => (
                            <SelectItem
                              key={assistant._id}
                              value={assistant._id}
                            >
                              <div className="flex items-center">
                                <img
                                  src={assistant.avatarUrl}
                                  alt={assistant.name}
                                  className="w-6 h-6 rounded-full mr-2"
                                />
                                {assistant.name}
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      {/* Conditionally Render Writing Styles Dropdown */}
                      {step.type === GenerateStepType.AI_REQUEST &&
                        selectedAssistant &&
                        selectedAssistant.styleSelector &&
                        writingStyles &&
                        writingStyles.length > 0 && (
                          <div className="mt-4">
                            <Label htmlFor={`writingStyle-${index}`}>
                              Writing Style
                            </Label>
                            <Select
                              value={step.writingStyleId || ""}
                              onValueChange={(value) =>
                                onStepChange("writingStyleId", value)
                              }
                            >
                              <SelectTrigger id={`writingStyle-${index}`}>
                                <SelectValue placeholder="Select Writing Style" />
                              </SelectTrigger>
                              <SelectContent className="max-h-60 overflow-auto">
                                {writingStyles.map((style) => (
                                  <SelectItem key={style._id} value={style._id}>
                                    <div className="flex items-start text-left">
                                      <img
                                        src={style.avatarUrl}
                                        alt={style.name}
                                        className="w-8 h-8 rounded-full mr-2 flex-shrink-0"
                                      />
                                      <div>
                                        <div className="font-semibold">
                                          {style.name}
                                        </div>
                                        <div
                                          className="text-sm text-gray-500 truncate"
                                          title={style.description}
                                        >
                                          {style.description.length > 50
                                            ? `${style.description.substring(
                                                0,
                                                30
                                              )}...`
                                            : style.description}
                                        </div>
                                      </div>
                                    </div>
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>
                        )}
                    </div>
                  )}
                {(step.type === GenerateStepType.AI_CHECK_AND_IMPROVE ||
                  step.type === GenerateStepType.AI_CONDITIONAL_CHECKER) && (
                  <div className="text-sm text-gray-500">
                    Using{" "}
                    {step.type === GenerateStepType.AI_CHECK_AND_IMPROVE
                      ? "AI Check and Improve"
                      : "AI Conditional Checker"}{" "}
                    Assistant
                  </div>
                )}
                {step.type === GenerateStepType.AI_REQUEST && (
                  <div>
                    {isSpecialAssistant ? (
                      <>
                        <p className="text-sm text-gray-500 mt-1">
                          Static prompt: "Work your magic!" will be used for
                          this assistant.
                        </p>
                        {isAssetTypeAssistant && (
                          <div className="mt-4">
                            <Label htmlFor={`assetType-${index}`}>
                              Asset Type
                            </Label>
                            <Select
                              value={step.assetType || ""}
                              onValueChange={handleAssetTypeChange}
                            >
                              <SelectTrigger id={`assetType-${index}`}>
                                <SelectValue placeholder="Select Asset Type" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Blog Post">
                                  Blog Post
                                </SelectItem>
                                <SelectItem value="Course">Course</SelectItem>
                                <SelectItem value="Product">Product</SelectItem>
                                <SelectItem value="Social Post">
                                  Social Post
                                </SelectItem>
                              </SelectContent>
                            </Select>
                            {!step.assetType && (
                              <p className="text-xs text-red-500 mt-1">
                                Asset type is required for this assistant.
                              </p>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <Label htmlFor={`prompt-${index}`}>Prompt</Label>
                        <Textarea
                          id={`prompt-${index}`}
                          placeholder={
                            isFirstStep
                              ? "Enter prompt"
                              : `Enter prompt (optional). Use placeholders like ${previousStepPlaceholders.split(', ').slice(-3).join(', ')} to reference previous steps' outputs.`
                          }
                          value={step.prompt || ''}
                        onChange={(e) => onStepChange('prompt', e.target.value)}
                        />
                        {!isFirstStep && (
                          <p className="text-xs text-gray-500 mt-1">
                            Tip: You can use placeholders like{" "}
                            {previousStepPlaceholders.split(', ').slice(-3).join(', ')} to include outputs from
                            previous steps in your prompt.
                          </p>
                        )}
                      </>
                    )}
                  </div>
                )}
                <AssistantDetails assistant={selectedAssistant} />
              </>
            )}
            {step.type === GenerateStepType.GENERATE_AI_IMAGE && (
              <>
                <div>
                  <Label htmlFor={`imagePrompt-${index}`}>Image Prompt</Label>
                  <Textarea
                    id={`imagePrompt-${index}`}
                    placeholder="Enter image prompt"
                    value={step.imagePrompt || ""}
                    onChange={(e) =>
                      onStepChange("imagePrompt", e.target.value)
                    }
                    required
                  />
                  {!isFirstStep && (
                    <p className="text-xs text-gray-500 mt-1">
                      Tip: You can use placeholders like{" "}
                      {previousStepPlaceholders} to include outputs from
                      previous steps in your image prompt.
                    </p>
                  )}
                </div>
                <div>
                  <Label htmlFor={`imageSize-${index}`}>Image Size</Label>
                  <Select
                    value={step.imageSize || "1024x1024"}
                    onValueChange={(value) => onStepChange("imageSize", value)}
                  >
                    <SelectTrigger id={`imageSize-${index}`}>
                      <SelectValue placeholder="Select image size" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="1024x1024">
                        1024x1024 (square)
                      </SelectItem>
                      <SelectItem value="1024x1792">
                        1024x1792 (portrait)
                      </SelectItem>
                      <SelectItem value="1792x1024">
                        1792x1024 (landscape)
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </>
            )}
            {step.type === GenerateStepType.ADD_CONTEXT && (
              <div>
                <Label htmlFor={`additionalContext-${index}`}>
                  Additional Context
                </Label>
                <Textarea
                  id={`additionalContext-${index}`}
                  placeholder="Add any additional context or details you want the flow to leverage"
                  value={step.prompt || ""}
                  onChange={(e) => onStepChange("prompt", e.target.value)}
                  className="min-h-[150px]"
                />
                <p className="text-xs text-gray-500 mt-1">
                  This context will be added to the conversation thread for use
                  in subsequent steps.
                </p>
              </div>
            )}
            {step.type === GenerateStepType.FETCH_WORDPRESS_DETAILS && (
              <FetchDetailsFields
                step={step}
                index={index}
                onStepChange={onStepChange}
                onRequestedDetailsChange={handleRequestedDetailsChange}
                wordpressSites={wordpressSites}
              />
            )}
            {step.type === GenerateStepType.CREATE_WORDPRESS_DETAILS && (
              <CreateDetailsFields
                step={step}
                index={index}
                onStepChange={onStepChange}
                onRequestedDetailsChange={handleRequestedDetailsChange}
                wordpressSites={wordpressSites}
              />
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default GenerateStepFields;
