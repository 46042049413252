import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../components/ui/alert-dialog";
import { Trash2 } from 'lucide-react';
import { GenerateStepType, ActionStepType } from './stepTypes';
import GenerateStepFields from './GenerateStepFields';

const StepCard = React.memo(({ 
  step, 
  index, 
  handleStepChange, 
  handleRemoveStep,
  assistants, 
  generatePreviousStepPlaceholders,
  isFirstStep
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const onStepChange = (fieldOrUpdates, value) => {
    if (typeof fieldOrUpdates === 'object') {
      handleStepChange(index, fieldOrUpdates);
    } else {
      handleStepChange(index, fieldOrUpdates, value);
    }
  };

  const onRemoveStep = () => {
    setIsAlertOpen(true);
  };

  const confirmRemoveStep = () => {
    handleRemoveStep(index);
    setIsAlertOpen(false);
  };

  useEffect(() => {
    if (!step.category) {
      onStepChange('category', 'generate');
    }
    if (step.category === 'generate' && (!step.type || !Object.values(GenerateStepType).includes(step.type))) {
      onStepChange('type', GenerateStepType.AI_REQUEST);
    } else if (step.category === 'action' && (!step.type || !Object.values(ActionStepType).includes(step.type))) {
      onStepChange('type', ActionStepType.SAVE_ASSET);
    }
  }, [step.category, step.type]);

  return (
    <Card className="mb-6">
      <CardHeader className="flex flex-row items-center justify-between pb-0 pt-2 lg:px-6">
        <CardTitle className="text-md lg:text-lg font-semibold leading-none mb-2">
          Step {index + 1}: {step.name}
        </CardTitle>
        <AlertDialog open={isAlertOpen} onOpenChange={setIsAlertOpen}>
          <AlertDialogTrigger asChild>
            <Button
              type="button"
              variant="ghost"
              size="sm"
              onClick={onRemoveStep}
            >
              <Trash2 className="h-4 w-4 text-red-500" />
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent className="z-[80]">
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure you want to remove this step?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete the step and all its contents.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={confirmRemoveStep}>Remove</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </CardHeader>
      <CardContent className="p-3 pt-0 lg:pt-2">
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <Label htmlFor={`step-category-${index}`}>Category</Label>
            <Select
              value={step.category}
              onValueChange={(value) => onStepChange('category', value)}
            >
              <SelectTrigger id={`step-category-${index}`}>
                <SelectValue placeholder="Select step category" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="generate">Generate</SelectItem>
                <SelectItem value="action">Action</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div>
            <Label htmlFor={`step-type-${index}`}>Type</Label>
            <Select
              value={step.type}
              onValueChange={(value) => onStepChange('type', value)}
            >
              <SelectTrigger id={`step-type-${index}`}>
                <SelectValue placeholder="Select step type" />
              </SelectTrigger>
              <SelectContent>
                {step.category === 'generate' ? (
                  <>
                    <SelectItem value={GenerateStepType.ADD_CONTEXT}>Add Additional Context</SelectItem>
                    <SelectItem value={GenerateStepType.AI_REQUEST}>AI Request</SelectItem>
                    <SelectItem value={GenerateStepType.GENERATE_AI_IMAGE}>Generate AI Image</SelectItem>
                    <SelectItem value={GenerateStepType.AI_CHECK_AND_IMPROVE}>AI Check and Improve</SelectItem>
                    <SelectItem value={GenerateStepType.FETCH_WORDPRESS_DETAILS}>Fetch Wordpress Details</SelectItem>
                    <SelectItem value={GenerateStepType.CREATE_WORDPRESS_DETAILS}>Create Wordpress Details</SelectItem>
                    <SelectItem value={GenerateStepType.AI_CONDITIONAL_CHECKER}>AI Conditional Checker</SelectItem>
                    <SelectItem value={GenerateStepType.API_CALL}>API Call</SelectItem>
                    <SelectItem value={GenerateStepType.TRANSFORMATION}>Transformation</SelectItem>
                    <SelectItem value={GenerateStepType.CONDITIONAL}>Conditional</SelectItem>
                    <SelectItem value={GenerateStepType.LOOP}>Loop</SelectItem>
                  </>
                ) : (
                  <>
                    <SelectItem value={ActionStepType.GET_RECENT_ASSETS}>Get Recent Assets</SelectItem>
                    <SelectItem value={ActionStepType.POST_TO_WORDPRESS}>Post to WordPress</SelectItem>
                    <SelectItem value={ActionStepType.SAVE_ASSET}>Save Asset</SelectItem>
                    <SelectItem value={ActionStepType.UPLOAD_PDF_FILE}>Generate PDF/Image File</SelectItem>
                    <SelectItem value={ActionStepType.UPLOAD_TEXT_FILE}>Upload Text File</SelectItem>
                  </>
                )}
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
      <div>
        <Label htmlFor={`step-name-${index}`} className="text-inter font-bold mb-2 block">
          Step Name
        </Label>
        <Input
          id={`step-name-${index}`}
          placeholder="Enter step name"
          value={step.name}
          onChange={(e) => onStepChange('name', e.target.value)}
          className="w-full"
        />
      </div>
      <div>
        <Label htmlFor={`step-description-${index}`} className="text-inter font-bold mb-2 block">
          Step Description
        </Label>
        <Input
          id={`step-description-${index}`}
          placeholder="Enter step description"
          value={step.description}
          onChange={(e) => onStepChange('description', e.target.value)}
          className="w-full"
        />
      </div>
    </div>
        <div className="mt-4">
          <GenerateStepFields 
            step={step} 
            onStepChange={onStepChange}
            assistants={assistants}
            generatePreviousStepPlaceholders={generatePreviousStepPlaceholders}
            isFirstStep={isFirstStep}
            index={index}
          />
        </div>
      </CardContent>
    </Card>
  );
});

export default StepCard;