import { configureStore } from '@reduxjs/toolkit';
import flowsReducer from './slices/flowsSlice';
import executionsReducer from './slices/executionsSlice';
import savedAssetsReducer from './slices/savedAssetsSlice';
import schedulesReducer from './slices/schedulesSlice';
import authReducer from './slices/authSlice';
import teamsReducer from './slices/teamsSlice';
import notificationsReducer from './slices/notificationsSlice';
import chatReducer from './slices/chatSlice';
import imageGenerationReducer from './slices/imageGenerationSlice';
import genericItemsReducer from './slices/genericItemsSlice';
import appFeaturesReducer from './slices/appFeaturesSlice';
import userChatbotReducer from './slices/userChatbotSlice';

export const store = configureStore({
  reducer: {
    chat: chatReducer,
    flows: flowsReducer,
    executions: executionsReducer,
    savedAssets: savedAssetsReducer,
    schedules: schedulesReducer,
    auth: authReducer,
    teams: teamsReducer,
    notifications: notificationsReducer,
    imageGeneration: imageGenerationReducer,
    genericItems: genericItemsReducer,
    appFeatures: appFeaturesReducer,
    userChatbots: userChatbotReducer,
  },
});