import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/button';
import { Calendar, Clock } from 'lucide-react';

const ScheduleList = ({ schedules, selectedScheduleId, onSelect }) => {
  const navigate = useNavigate();

  const handleSelectSchedule = (scheduleId) => {
    navigate(`/scheduling/${scheduleId}`);
    if (onSelect) onSelect();
  };

  return (
    <div className="space-y-2">
      {schedules.map((schedule) => (
        <Button
          key={schedule._id}
          className={`w-full justify-start ${schedule.isActive ? '' : 'opacity-50'}
            ${schedule._id === selectedScheduleId ? 'bg-gray-900 text-white hover:bg-gray-700' : 'bg-white text-gray-900 hover:bg-gray-100'}`}
          onClick={() => handleSelectSchedule(schedule._id)}
        >
          <div className="flex items-center space-x-2">
            {schedule.type === 'custom' ? (
              <Clock className="h-4 w-4" />
            ) : (
              <Calendar className="h-4 w-4" />
            )}
            <span className="truncate">{schedule.flow?.name || 'Loading...'}</span>
          </div>
        </Button>
      ))}
    </div>
  );
};

export default ScheduleList;