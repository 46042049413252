// File: /src/services/api.js

import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { store } from '../store';
import { refreshAuthToken, setLoading, clearAuth } from '../slices/authSlice';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  
  failedQueue = [];
};

api.interceptors.request.use(async (config) => {
  if (config.data && typeof config.data !== 'string' && !config.skipStringify) {
    config.data = JSON.stringify(config.data);
    config.headers['Content-Type'] = 'application/json';
  }
  
  if (!config.url.includes('/auth/refresh-token')) {
    const accessToken = localStorage.getItem('accessToken');
    
    if (accessToken) {
      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      
      if (decoded.exp < currentTime + 300) { // Refresh if token expires in less than 5 minutes
        if (!isRefreshing) {
          isRefreshing = true;
          store.dispatch(setLoading(true));
          
          try {
            await store.dispatch(refreshAuthToken()).unwrap();
            const newAccessToken = localStorage.getItem('accessToken');
            config.headers['Authorization'] = `Bearer ${newAccessToken}`;
            processQueue(null, newAccessToken);
          } catch (error) {
            processQueue(error, null);
            throw error;
          } finally {
            isRefreshing = false;
            store.dispatch(setLoading(false));
          }
        } else {
          // Wait for the refresh to complete
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          }).then(token => {
            config.headers['Authorization'] = `Bearer ${token}`;
            return config;
          }).catch(error => {
            return Promise.reject(error);
          });
        }
      } else {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }
  }
  
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;
        
        try {
          await store.dispatch(refreshAuthToken()).unwrap();
          const newAccessToken = localStorage.getItem('accessToken');
          api.defaults.headers['Authorization'] = `Bearer ${newAccessToken}`;
          processQueue(null, newAccessToken);
          return api(originalRequest);
        } catch (refreshError) {
          processQueue(refreshError, null);
          store.dispatch(clearAuth());
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      } else {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return api(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }
    }
    
    return Promise.reject(error);
  }
);

  export const fetchExecutionStatus = (executionId) => api.get(`/executions/${executionId}/status`);
  export const refreshToken = async (refreshToken) => {
    try {
      console.log('Sending refresh token:', refreshToken); // Log the token being sent
      const response = await api.post('/auth/refresh-token', { refreshToken });
      return response.data;
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };
  
  export const login = (credentials) => api.post('/auth/login', credentials);
  export const getCurrentUser = () => api.get('/auth/me');
  export const updateCurrentUser = (userData) => api.put('/auth/me', userData);
  export const changePassword = (passwordData) => api.put('/auth/change-password', passwordData);
  export const logout = () => api.post('/auth/logout');
  export const register = (userData) => api.post('/auth/register', userData);
  export const joinWaitlist = (email) => api.post('/subscribeUser', { email });

  // New API calls for action steps
export const postToInstagram = (savedPostId) => api.post(`/actions/post-to-instagram/${savedPostId}`);
export const postToWordpress = (savedBlogpostId, { wordpressSiteUrl, postStatus, date }) => 
  api.post(`/actions/postToWordpress/${savedBlogpostId}`, { wordpressSiteUrl, postStatus, date });
export const postProductToWordpress = (savedProductId, { wordpressSiteUrl, listingDetails }) =>
  api.post(`/actions/postProductToWordpress/${savedProductId}`, { wordpressSiteUrl, listingDetails });
export const postCourseToTutorLMS = (savedCourseId, { wordpressSiteUrl, courseDetails }) =>
  api.post(`/actions/postCourseToTutorLMS/${savedCourseId}`, { wordpressSiteUrl, courseDetails });
export const saveAsset = (assetData) => api.post('/actions/save-asset', assetData);

  export const fetchItems = async (modelName) => {
    const response = await api.get(`/items/${modelName}`);
    return response.data;
  };

// New image generation API calls
export const generateImage = (prompt, size, styleId, styleModelName) => {
  return api.post('/images/generate', { prompt, size, styleId, styleModelName }).then(response => {
    // Strip out non-serializable data
    const { headers, config, request, ...serializableData } = response.data;
    return serializableData;
  });
};

export const fetchUserImages = (page = 1, limit = 10) => {
  return api.get(`/images/history?page=${page}&limit=${limit}`).then(response => {
    // Strip out non-serializable data
    const { headers, config, request, ...serializableData } = response.data;
    return serializableData;
  });
};

export const deleteImage = (imageId) => {
  return api.delete(`/images/${imageId}`);
};

// Chat-related API calls
export const fetchChatHistory = (page = 1, limit = 10) => api.get(`/chats?page=${page}&limit=${limit}`);

export const fetchChatById = (chatId) => {
  if (!chatId) {
    return Promise.reject(new Error('Chat ID is required'));
  }
  return api.get(`/chats/${chatId}/messages`).then(response => {
    // Only for chat-related responses, strip out non-serializable data
    const { headers, config, request, ...serializableData } = response.data;
    return serializableData;
  });
};
export const createChat = (assistantId, title) => api.post('/chats', { assistantId, title });
export const convertExecutionToChat = (executionId) => api.post(`chats/execution/${executionId}/convert`);
export const sendMessage = (chatId, message, fileIds = []) => 
  api.post(`/chats/${chatId}/messages`, { message, fileIds });
//export const sendMessage = (chatId, message) => api.post(`/chats/${chatId}/messages`, { message });
export const uploadFile = (chatId, file) => {
  const formData = new FormData();
  formData.append('files', file);
  return api.post(`/chats/${chatId}/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    skipStringify: true
  });
};
export const switchAssistant = (chatId, assistantId, assistantType) => 
  api.put(`/chats/${chatId}/assistant`, { assistantId, assistantType });
export const updateTitle = (chatId, title) => api.put(`/chats/${chatId}/title`, { title });

// UserChatbot API calls
export const getUserChatbots = () => api.get('/user-chatbots');
export const getUserChatbot = (id) => api.get(`/user-chatbots/${id}`);
export const createUserChatbot = (data) => api.post('/user-chatbots', data);
export const updateUserChatbot = (id, data) => api.put(`/user-chatbots/${id}`, data);
export const deleteUserChatbot = (id) => api.delete(`/user-chatbots/${id}`);

// Flows
export const fetchFlows = ({ page = 1, limit = 10, search = '', teamId = 'all' }) =>
  api.get('/flows', { 
    params: { 
      page, 
      limit, 
      search,
      ...(teamId !== 'all' && { teamId })  // Only include teamId if it's not 'all'
    } 
  });
  export const fetchFlowById = (id) => api.get(`/flows/${id}`);
  export const createFlow = (flowData) => api.post('/flows', flowData);
  export const updateFlow = (id, flowData) => api.put(`/flows/${id}`, flowData);
  export const deleteFlow = (id) => api.delete(`/flows/${id}`);
  export const cloneFlow = (id) => api.post(`/flows/${id}/clone`);
  export const fetchGlobalFlows = () => api.get('/flows/global');

// Executions
export const fetchExecutions = ({ page = 1, limit = 50, teamId = 'all', search = '', status = 'all', sortBy = 'startTime', sortOrder = 'desc' } = {}) =>
  api.get('/executions', {
    params: {
      page,
      limit,
      ...(teamId !== 'all' && { teamId }),
      ...(search && { search }),
      ...(status !== 'all' && { status }),
      sortBy,
      sortOrder
    }
  });
export const fetchExecutionById = (id) => api.get(`/executions/${id}`);
export const startExecution = (flowId) => api.post(`/executions/start/${flowId}`);
export const cancelExecution = (id) => api.post(`/executions/${id}/cancel`);

// Schedules
export const fetchSchedules = () => api.get('/schedules');
export const createSchedule = (scheduleData) => api.post('/schedules', scheduleData);
export const updateSchedule = (id, scheduleData) => api.put(`/schedules/${id}`, scheduleData);
export const deleteSchedule = (id) => api.delete(`/schedules/${id}`);
export const activateSchedule = (id) => api.put(`/schedules/${id}/activate`);
export const deactivateSchedule = (id) => api.put(`/schedules/${id}/deactivate`);
export const fetchSchedulesByFlow = (flowId) => api.get(`/schedules/flow/${flowId}`);

// New API calls for saved assets
export const fetchSavedAssets = (assetType) => {
  if (!assetType) {
    throw new Error('Asset type is required');
  }
  return api.get(`/saved-assets/${assetType}`);
};
export const createSavedAsset = (assetType, assetData) => api.post(`/saved-assets/${assetType}`, assetData);
export const updateSavedAsset = (assetType, id, assetData) => api.put(`/saved-assets/${assetType}/${id}`, assetData);
export const deleteSavedAsset = (assetType, id) => api.delete(`/saved-assets/${assetType}/${id}`);

export const addTeamToRecord = (modelName, id, teamId) => 
  api.post(`/saved-assets/addTeamToRecord/${modelName}/${id}`, { teamId });

export const removeTeamFromRecord = (modelName, id, teamId) => 
  api.delete(`/saved-assets/removeTeamFromRecord/${modelName}/${id}/${teamId}`);

export const removeAllTeamsFromRecord = (modelName, id) => 
  api.delete(`/saved-assets/removeAllTeamsFromRecord/${modelName}/${id}`);

// New API calls for notifications
export const fetchNotifications = () => api.get('/notifications');
export const markNotificationAsRead = (id) => api.put(`/notifications/${id}/read`);
export const markNotificationAsUnread = (id) => api.put(`/notifications/${id}/unread`);
export const archiveNotification = (id) => api.put(`/notifications/${id}/archive`);
export const deleteNotification = (id) => api.delete(`/notifications/${id}`);
export const markAllNotificationsAsRead = () => api.put('/notifications/read-all');
export const archiveAllNotifications = () => api.put('/notifications/archive-all');
export const getUnreadNotificationCount = () => api.get('/notifications/unread-count');

// Teams
export const fetchUserTeams = () => api.get('/teams');
export const createTeam = (teamData) => api.post('/teams', teamData);
export const joinTeamByCode = (teamCode) => api.post('/teams/join-by-code', { teamCode });
export const joinTeam = (joinData) => api.post('/teams/join', joinData);
export const leaveTeam = (teamId) => api.delete(`/teams/${teamId}/leave`);
export const inviteUserToTeam = (teamId, inviteData) => api.post(`/teams/${teamId}/invite`, inviteData);
export const createInvitation = (teamId, inviteData) => api.post(`/teams/${teamId}/invite`, inviteData);
export const getInvitationByToken = (token) => api.get(`/invitations/${token}`);
export const acceptInvitation = (token) => api.post(`/teams/invitations/${token}/accept`);
export const declineInvitation = (token) => api.post(`/teams/invitations/${token}/decline`);
export const revokeInvitation = (teamId, invitationId) => api.delete(`/teams/${teamId}/invitations/${invitationId}`);
export const updateMemberRole = (teamId, userId, role) => api.put(`/teams/${teamId}/members/${userId}`, { role });
export const deleteTeam = (teamId) => api.delete(`/teams/${teamId}`);

// New function to fetch OpenAI Assistants
export const fetchAssistants = () => api.get('/flows/assistants');

export default api;