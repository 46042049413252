import React from 'react';
import { Label } from '../../../components/ui/label';
import { Card, CardContent } from '../../../components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select';
import { Input } from '../../../components/ui/input';

const GetRecentAssetsFields = ({ step, handleFieldChange, index }) => {
  const assetTypes = [
    { value: 'blogPost', label: 'Blog Post' },
    { value: 'course', label: 'Course' },
    { value: 'generalImage', label: 'General Image' },
    { value: 'generalText', label: 'General Text' },
    { value: 'product', label: 'Product' },
    { value: 'socialPost', label: 'Social Post' },
  ];

  const quantityOptions = [1, 3, 5, 10, 25];

  return (
    <Card>
      <CardContent className="pt-6 space-y-4">
        <div>
          <Label htmlFor={`asset-type-${index}`} className="text-inter font-bold">
            Asset Type
          </Label>
          <Select
            value={step.assetType || ''}
            onValueChange={(value) => handleFieldChange('assetType', value)}
          >
            <SelectTrigger id={`asset-type-${index}`}>
              <SelectValue placeholder="Select Asset Type" />
            </SelectTrigger>
            <SelectContent>
              {assetTypes.map((type) => (
                <SelectItem key={type.value} value={type.value}>
                  {type.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <Label htmlFor={`quantity-${index}`} className="text-inter font-bold">
            Number of Records
          </Label>
          <Select
            value={step.quantity?.toString() || '3'}
            onValueChange={(value) => handleFieldChange('quantity', parseInt(value))}
          >
            <SelectTrigger id={`quantity-${index}`}>
              <SelectValue placeholder="Select Quantity" />
            </SelectTrigger>
            <SelectContent>
              {quantityOptions.map((option) => (
                <SelectItem key={option} value={option.toString()}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <Label htmlFor={`output-key-${index}`} className="text-inter font-bold">
            Output Key
          </Label>
          <Input
            id={`output-key-${index}`}
            placeholder="e.g., recentAssets"
            value={step.outputKey || ''}
            onChange={(e) => handleFieldChange('outputKey', e.target.value)}
            required
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default GetRecentAssetsFields;