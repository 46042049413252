import React, { useState } from 'react';
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FileUpload } from '../../components/FileUpload';
import { FilePreview } from '../../components/FilePreview';

const ChatInput = ({
  message,
  setMessage,
  handleSendMessage,
  currentChat,
  selectedAssistant,
  chatTitle,
  files,
  setFiles,
  sentFiles
}) => {
  const [showFiles, setShowFiles] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const handleFileUpload = (uploadedFiles) => {
    setFiles(prevFiles => [...prevFiles, ...uploadedFiles].slice(0, 5));
  };

  const removeFile = (fileToRemove) => {
    setFiles(files.filter(file => file !== fileToRemove));
  };

  return (
    <form onSubmit={handleSendMessage} className="p-2 border-t absolute lg:relative bottom-0 w-11/12 lg:w-2/3 lg:mx-auto">
      <div className="flex flex-col md:flex-row md:items-center gap-2 -ml-2 lg:ml-0">
        {/* Input and Send Button */}
        <div className="flex items-center gap-2 w-full">
          <Textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type your message..."
            className="flex-grow"
            rows={1}
          />
          <Button
            type="submit"
            disabled={(!currentChat && (!selectedAssistant || !chatTitle)) || (!message.trim() && files.length === 0)}
            className="rounded-full w-10 h-10 flex items-center justify-center p-0"
          >
            <FontAwesomeIcon icon={faPaperPlane} className="h-4 w-4" />
            <span className="sr-only">Send</span>
          </Button>
        </div>

        {/* File Upload and Toggle */}
        <div className="hidden lg:flex items-center justify-end lg:justify-between w-full md:w-auto">
          <FileUpload onFileUpload={handleFileUpload} />
          {files.length > 0 && (
            <button
              type="button"
              onClick={() => setShowFiles(!showFiles)}
              className="text-sm text-gray-600 underline ml-2"
            >
              {showFiles ? 'Hide Files' : `Show Files (${files.length})`}
            </button>
          )}
        </div>
      </div>

      {/* File Previews */}
      {showFiles && files.length > 0 && (
        <div className="mt-2">
          {files.map((file, index) => (
            <FilePreview key={index} file={file} onRemove={removeFile} />
          ))}
        </div>
      )}

      {/* Sent Files Section */}
      {sentFiles && sentFiles.length > 0 && (
        <div className="mt-2">
          <h4 className="text-sm font-medium mb-1">Previously Attached Files:</h4>
          <div className="flex flex-wrap gap-2">
            {sentFiles.map((file, index) => (
              <FilePreview key={index} file={file} />
            ))}
          </div>
        </div>
      )}
    </form>
  );
};

export default ChatInput;
