import React, { useEffect } from "react";
import { ActionStepType } from "./stepTypes";
import {
  PostToWordPressFields,
  UploadPdfFileFields,
  UploadTextFileFields,
  SaveAssetFields,
  handleFieldChange as commonHandleFieldChange,
  GetRecentAssetsFields,
} from "./RenderFields";

const ActionStepFields = React.memo(({ step, onStepChange, index }) => {
  useEffect(() => {
    // Set default values if they're not already set
    if (step.type === ActionStepType.POST_TO_WORDPRESS) {
      if (!step.author) {
        onStepChange("author", "3"); // Default to Helena Hargrove
      }
      if (!step.postStatus) {
        onStepChange("postStatus", "draft");
      }
      if (!step.inputMode) {
        onStepChange("inputMode", "standard");
      }
    } else if (step.type === ActionStepType.SAVE_ASSET) {
      if (!step.assetType) {
        onStepChange("assetType", "generalText");
      }
    }
  }, [step.type, onStepChange]);

  const handleFieldChange = (field, value) => {
    commonHandleFieldChange(field, value, onStepChange);
  };

  switch (step.type) {
    case ActionStepType.POST_TO_WORDPRESS:
      return (
        <PostToWordPressFields
          step={step}
          handleFieldChange={handleFieldChange}
          index={index}
        />
      );
    case ActionStepType.SAVE_ASSET:
      return (
        <SaveAssetFields
          step={step}
          handleFieldChange={handleFieldChange}
          index={index}
        />
      );
    case ActionStepType.UPLOAD_TEXT_FILE:
      return (
        <UploadTextFileFields
          step={step}
          handleFieldChange={handleFieldChange}
          index={index}
        />
      );
    case ActionStepType.UPLOAD_PDF_FILE:
      return (
        <UploadPdfFileFields
          step={step}
          handleFieldChange={handleFieldChange}
          index={index}
        />
      );
    case ActionStepType.GET_RECENT_ASSETS:
      return (
        <GetRecentAssetsFields
          step={step}
          handleFieldChange={handleFieldChange}
          index={index}
        />
      );
    default:
      return null;
  }
});

export default ActionStepFields;
