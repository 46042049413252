import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from './ui/button';
import { convertExecutionToChat } from '../services/api';
import { setCurrentChat } from '../slices/chatSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';

const TakeToChatButton = ({ executionId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConvertToChat = async () => {
    try {
      const response = await convertExecutionToChat(executionId);
      const newChat = response.data;
      dispatch(setCurrentChat(newChat));
      navigate(`/chat/${newChat._id}`);
    } catch (error) {
      console.error('Error converting execution to chat:', error);
      // Handle error (e.g., show a toast notification)
    }
  };

  return (
    <Button
  onClick={handleConvertToChat}
  className="bg-gray-100 bg-opacity-50 text-black border-2 hover:bg-black hover:text-white"
>
  <FontAwesomeIcon icon={faMessage} className="mr-2 text-gray-700" />
  Take This To Chat
</Button>
  );
};

export default TakeToChatButton;