import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../services/api';

export const fetchUserTeams = createAsyncThunk(
  'teams/fetchUserTeams',
  async () => {
    const response = await api.fetchUserTeams();
    return response.data;
  }
);

export const createTeam = createAsyncThunk(
    'teams/createTeam',
    async (teamData) => {
      const response = await api.createTeam(teamData);
      return response.data;
    }
  );
  
  export const joinTeamByCode = createAsyncThunk(
    'teams/joinTeamByCode',
    async (teamCode) => {
      const response = await api.joinTeamByCode(teamCode);
      return response.data;
    }
  );

export const joinTeam = createAsyncThunk(
  'teams/joinTeam',
  async (joinData) => {
    const response = await api.joinTeam(joinData);
    return response.data;
  }
);

export const leaveTeam = createAsyncThunk(
  'teams/leaveTeam',
  async (teamId) => {
    await api.leaveTeam(teamId);
    return teamId;
  }
);

export const inviteUserToTeam = createAsyncThunk(
  'teams/inviteUserToTeam',
  async ({ teamId, email, role }) => {
    const response = await api.inviteUserToTeam(teamId, { email, role });
    return response.data;
  }
);

export const acceptInvitation = createAsyncThunk(
  'teams/acceptInvitation',
  async (token) => {
    const response = await api.acceptInvitation(token);
    return response.data;
  }
);

// New action for revoking invitations
export const revokeInvitation = createAsyncThunk(
  'teams/revokeInvitation',
  async ({ teamId, invitationId }) => {
    const response = await api.revokeInvitation(teamId, invitationId);
    return { teamId, invitationId };
  }
);

// New action for updating member roles
export const updateMemberRole = createAsyncThunk(
  'teams/updateMemberRole',
  async ({ teamId, userId, role }) => {
    const response = await api.updateMemberRole(teamId, userId, role);
    return { teamId, userId, role };
  }
);

export const deleteTeam = createAsyncThunk(
    'teams/deleteTeam',
    async (teamId) => {
      await api.deleteTeam(teamId);
      return teamId;
    }
  );

const teamsSlice = createSlice({
  name: 'teams',
  initialState: {
    teams: [],
    status: 'idle',
    error: null,
    selectedTeam: 'all',
  },
  reducers: {
    setSelectedTeam: (state, action) => {
        state.selectedTeam = action.payload;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserTeams.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserTeams.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.teams = action.payload;
      })
      .addCase(fetchUserTeams.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        state.teams.push(action.payload);
      })
      .addCase(joinTeamByCode.fulfilled, (state, action) => {
        state.teams.push(action.payload.team);
      })
      .addCase(joinTeam.fulfilled, (state, action) => {
        state.teams.push(action.payload);
      })
      .addCase(leaveTeam.fulfilled, (state, action) => {
        state.teams = state.teams.filter(team => team._id !== action.payload);
      })
      .addCase(inviteUserToTeam.fulfilled, (state, action) => {
        const team = state.teams.find(t => t._id === action.payload.teamId);
        if (team) {
          team.invitations.push(action.payload.invitation);
        }
      })
      .addCase(acceptInvitation.fulfilled, (state, action) => {
        state.teams.push(action.payload);
      })
      .addCase(revokeInvitation.fulfilled, (state, action) => {
        const { teamId, invitationId } = action.payload;
        const team = state.teams.find(t => t._id === teamId);
        if (team) {
          team.invitations = team.invitations.filter(inv => inv._id !== invitationId);
        }
      })
      .addCase(updateMemberRole.fulfilled, (state, action) => {
        const { teamId, userId, role } = action.payload;
        const team = state.teams.find(t => t._id === teamId);
        if (team) {
          const member = team.members.find(m => m._id === userId);
          if (member) {
            member.role = role;
          }
        }
      })
      .addCase(deleteTeam.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.teams = state.teams.filter(team => team._id !== action.payload);
      })
      .addCase(deleteTeam.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setSelectedTeam } = teamsSlice.actions;
export default teamsSlice.reducer;