import React from 'react';
import { Folder, Tag, X } from 'lucide-react';
import { Badge } from "../../../components/ui/badge";

const ActiveFilters = ({
  selectedCategories,
  selectedTags,
  onRemoveCategory,
  onRemoveTag
}) => {
  if (selectedCategories.length === 0 && selectedTags.length === 0) return null;

  return (
    <div className="flex flex-wrap gap-2 mb-4">
      {selectedCategories.map(category => (
        <Badge
          key={category}
          variant="secondary"
          className="px-2 py-1 cursor-pointer hover:bg-secondary/80"
          onClick={() => onRemoveCategory(category)}
        >
          <Folder className="w-3 h-3 mr-1" />
          {category}
          <X className="w-3 h-3 ml-1" />
        </Badge>
      ))}
      {selectedTags.map(tag => (
        <Badge
          key={tag}
          variant="secondary"
          className="px-2 py-1 cursor-pointer hover:bg-secondary/80"
          onClick={() => onRemoveTag(tag)}
        >
          <Tag className="w-3 h-3 mr-1" />
          {tag}
          <X className="w-3 h-3 ml-1" />
        </Badge>
      ))}
    </div>
  );
};

export default ActiveFilters;