import React, { useEffect, useState, useRef } from 'react';
import DOMPurify from 'dompurify';

const TemplatePreview = ({ templateCode, pdfData, templateWidth, templateHeight }) => {
  const [renderedHtml, setRenderedHtml] = useState('');
  const [scale, setScale] = useState(1);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!templateCode) {
        return;
    }
    let html = templateCode;
  
    // Function to merge styles
    const mergeStyles = (existingStyle, newStyle) => {
      const styles = [existingStyle, newStyle].filter(Boolean);
      return styles.join('; ');
    };
  
    // Function to update or add style attribute
    const updateStyle = (match, tag, existingStyle, classes, newStyle) => {
      const updatedStyle = mergeStyles(existingStyle, newStyle);
      return `${tag} style="${updatedStyle}" class="${classes.trim()}"`;
    };
  
    // Handle w-screen and h-screen
    html = html.replace(/(<\w+)\s*(style="([^"]*)")?\s*class="([^"]*)(w-screen)([^"]*)(h-screen)([^"]*)"/g, 
      (match, tag, styleAttr, existingStyle, beforeClass, w, middleClass, h, afterClass) => {
        return updateStyle(match, tag, existingStyle, `${beforeClass}${middleClass}${afterClass}`, 'width: 100vw; height: 100vh');
      }
    );

    // Handle background color
html = html.replace(/(<\w+)\s*(style="([^"]*)")?\s*class="([^"]*)bg-\[{{ (\w+) }}\]([^"]*)"/g,
    (match, tag, styleAttr, existingStyle, beforeClass, placeholderName, afterClass) => {
      return updateStyle(match, tag, existingStyle, `${beforeClass}${afterClass}`, `background-color: {{ ${placeholderName} }}`);
    }
  );
  
  // Handle text color
  html = html.replace(/(<\w+)\s*(style="([^"]*)")?\s*class="([^"]*)text-\[{{ (\w+) }}\]([^"]*)"/g,
    (match, tag, styleAttr, existingStyle, beforeClass, placeholderName, afterClass) => {
      return updateStyle(match, tag, existingStyle, `${beforeClass}${afterClass}`, `color: {{ ${placeholderName} }}`);
    }
  );
  
    /*// Handle background color
    html = html.replace(/(<\w+)\s*(style="([^"]*)")?\s*class="([^"]*)bg-\[{{ color }}\]([^"]*)"/g,
      (match, tag, styleAttr, existingStyle, beforeClass, afterClass) => {
        return updateStyle(match, tag, existingStyle, `${beforeClass}${afterClass}`, 'background-color: {{ color }}');
      }
    );
  
    // Handle text color
    html = html.replace(/(<\w+)\s*(style="([^"]*)")?\s*class="([^"]*)text-\[{{ color }}\]([^"]*)"/g,
      (match, tag, styleAttr, existingStyle, beforeClass, afterClass) => {
        return updateStyle(match, tag, existingStyle, `${beforeClass}${afterClass}`, 'color: {{ color }}');
      }
    );*/
  
    // Handle font size
    html = html.replace(/(<\w+)\s*(style="([^"]*)")?\s*class="([^"]*)text-\[(\d+)px\]([^"]*)"/g,
      (match, tag, styleAttr, existingStyle, beforeClass, fontSize, afterClass) => {
        return updateStyle(match, tag, existingStyle, `${beforeClass}${afterClass}`, `font-size: ${fontSize}px`);
      }
    );
  
    // Replace placeholders with actual values
    Object.entries(pdfData).forEach(([key, value]) => {
      const regex = new RegExp(`{{\\s*${key}\\s*}}`, 'g');
      html = html.replace(regex, value);
    });
    console.log('html2:', html);
  
    setRenderedHtml(DOMPurify.sanitize(html));
  }, [templateCode, pdfData]);

  useEffect(() => {
    const updateScale = () => {
      if (containerRef.current && templateWidth && templateHeight) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        
        const widthScale = containerWidth / templateWidth;
        const heightScale = containerHeight / templateHeight;
        
        const newScale = Math.min(widthScale, heightScale);
        setScale(newScale);
      }
    };

    updateScale();
    window.addEventListener('resize', updateScale);
    return () => window.removeEventListener('resize', updateScale);
  }, [renderedHtml, templateWidth, templateHeight]);

  const containerStyle = {
    width: '100%',
    height: '0',
    paddingBottom: templateWidth && templateHeight ? `${(templateHeight / templateWidth) * 100}%` : '56.25%',
    position: 'relative',
    overflow: 'hidden',
  };

  const contentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: templateWidth,
    height: templateHeight,
    transform: `translate(-50%, -50%) scale(${scale})`,
  };

  return (
    <div ref={containerRef} style={containerStyle} className="border border-gray-300 rounded-md">
      <div style={contentStyle}>
        <div dangerouslySetInnerHTML={{ __html: renderedHtml }} />
      </div>
    </div>
  );
};

export default TemplatePreview;