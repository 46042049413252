import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';

const TermsOfService = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="w-full max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">Terms of Service</CardTitle>
        </CardHeader>
        <CardContent className="prose">
          <h2 className="text-xl font-semibold mt-4">1. Acceptance of Terms</h2>
          <p>
            By accessing or using Flow Orchestra ("the App"), you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use the App.
          </p>

          <h2 className="text-xl font-semibold mt-4">2. Use of the App</h2>
          <p>
            You agree to use the App only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the App.
          </p>

          <h2 className="text-xl font-semibold mt-4">3. User Accounts</h2>
          <p>
            You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account or password.
          </p>

          <h2 className="text-xl font-semibold mt-4">4. Intellectual Property</h2>
          <p>
            The App and its original content, features, and functionality are owned by GAIM Network LLC and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
          </p>

          <h2 className="text-xl font-semibold mt-4">5. Termination</h2>
          <p>
            We may terminate or suspend your account and bar access to the App immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
          </p>

          <h2 className="text-xl font-semibold mt-4">6. Limitation of Liability</h2>
          <p>
            In no event shall GAIM Network LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the App.
          </p>

          <h2 className="text-xl font-semibold mt-4">7. Changes to Terms</h2>
          <p>
            We reserve the right to modify or replace these Terms at any time. It is your responsibility to check the Terms periodically for changes.
          </p>

          <h2 className="text-xl font-semibold mt-4">8. Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at support@gaimnetwork.com.
          </p>
        </CardContent>
      </Card>
    </div>
  );
};

export default TermsOfService;