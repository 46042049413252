import React, { useState } from 'react';
import { Button } from './ui/button';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from './ui/alert-dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWordpress } from '@fortawesome/free-brands-svg-icons';
import { faSave, faShoppingBasket, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { postToInstagram, postToWordpress, saveAsset, postProductToWordpress, postCourseToTutorLMS } from '../services/api';
import { ActionStepType } from '../pages/FlowFormComponents/stepTypes';
import { useToast } from './ui/use-toast';
import WordPressPostForm from './ActionButtonComponents/WordPressPostForm';
import WordPressProductForm from './ActionButtonComponents/WordPressProductForm';
import TutorLMSCourseForm from './ActionButtonComponents/TutorLMSCourseForm';

const ActionButton = ({ actionType, id, blogPost, product, savedCourse }) => {
  const { toast } = useToast();
  const [isExpanded, setIsExpanded] = useState(false);
  const [wordpressSiteUrl, setWordpressSiteUrl] = useState('https://thatnurselife.com');
  const [productWordpressSiteUrl, setProductWordpressSiteUrl] = useState('https://aheadofthegap.com');
  const [postStatus, setPostStatus] = useState('draft');
  const [publishDate, setPublishDate] = useState(null);
  const [postTitle, setPostTitle] = useState(blogPost?.title || '');
  const [postContent, setPostContent] = useState(blogPost?.content || '');
  const [postExcerpt, setPostExcerpt] = useState(blogPost?.excerpt || '');
  const [featuredImage, setFeaturedImage] = useState(blogPost?.featuredImageUrl || '');

  // State for WordPress Product
  const [productName, setProductName] = useState(product?.name || '');
  const [productType, setProductType] = useState(product?.type || 'simple');
  const [regularPrice, setRegularPrice] = useState(product?.regular_price || '');
  const [salePrice, setSalePrice] = useState(product?.sale_price || '');
  const [longDescription, setLongDescription] = useState(product?.long_description || '');
  const [shortDescription, setShortDescription] = useState(product?.short_description || '');
  const [manageStock, setManageStock] = useState(product?.manage_stock || false);
  const [stockQuantity, setStockQuantity] = useState(product?.stock_quantity || 0);
  const [status, setStatus] = useState(product?.status || 'draft');
  const [downloadable, setDownloadable] = useState(product?.downloadable);
  const [virtual, setVirtual] = useState(product?.virtual);
  const [forTutorLMS, setForTutorLMS] = useState(
    product?.meta_data?.some(item => item.key === "_tutor_product" && item.value === "yes") || false
  );
  const [externalUrl, setExternalUrl] = useState(product?.external_url || '');
  const [images, setImages] = useState(product?.images || []);
  const [categories, setCategories] = useState(product?.categories || []);
  const [tags, setTags] = useState(product?.tags || []);
  const [upsellIds, setUpsellIds] = useState(product?.upsell_ids || []);
  const [crossSellIds, setCrossSellIds] = useState(product?.cross_sell_ids || []);
  const [downloads, setDownloads] = useState(product?.downloads || []);
  const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState(false);

  // State for TutorLMS Course
  const [courseTutorLMSSiteUrl, setCourseTutorLMSSiteUrl] = useState('https://aheadofthegap.com');
  const [courseTitle, setCourseTitle] = useState(savedCourse?.course?.post_title || '');
  const [courseContent, setCourseContent] = useState(savedCourse?.course?.post_content || '');
  const [courseExcerpt, setCourseExcerpt] = useState(savedCourse?.course?.post_excerpt || '');
  const [courseCategories, setCourseCategories] = useState(savedCourse?.course?.course_categories || []);
  const [courseTags, setCourseTags] = useState(savedCourse?.course?.course_tags || []);
  const [courseLevel, setCourseLevel] = useState(savedCourse?.course?.course_level || 'beginner');
  const [courseStatus, setCourseStatus] = useState(savedCourse?.course?.post_status || 'draft');
  const [courseThumbnail, setCourseThumbnail] = useState(savedCourse?.course?.thumbnail_id || '');
  const [additionalContent, setAdditionalContent] = useState(savedCourse?.additional_content || '');
  const [courseVideo, setCourseVideo] = useState(savedCourse?.course?.video_url || '');

  const getActionConfig = () => {
    switch (actionType) {
      case ActionStepType.POST_TO_INSTAGRAM:
        return {
          icon: faInstagram,
          text: 'Post to Instagram',
          color: 'bg-pink-500 hover:bg-pink-600',
          action: () => postToInstagram(id)
        };
      case ActionStepType.POST_TO_WORDPRESS:
        return {
          icon: faWordpress,
          text: 'Post to WordPress',
          color: 'bg-green-500 hover:bg-green-600',
          action: () => handleWordPressPost()
        };
      case ActionStepType.POST_PRODUCT_TO_WORDPRESS:
        return {
          icon: faShoppingBasket,
          text: 'Post Product to WordPress',
          color: 'bg-blue-500 hover:bg-blue-600',
          action: () => handleProductWordPressPost(),
        };
      case ActionStepType.SAVE_ASSET:
        return {
          icon: faSave,
          text: 'Save Asset',
          color: 'bg-green-500 hover:bg-green-600',
          action: () => saveAsset(id)
        };
      case ActionStepType.POST_COURSE_TO_TUTORLMS:
        return {
          icon: faGraduationCap,
          text: 'Post Course to TutorLMS',
          color: 'bg-purple-500 hover:bg-purple-600',
          action: () => setIsExpanded(true),
        };
      default:
        return null;
    }
  };

  const config = getActionConfig();

  if (!config) return null;

  const handleWordPressPost = async () => {
    try {
      await postToWordpress(id, {
        wordpressSiteUrl,
        postStatus,
        date: publishDate ? publishDate.toISOString() : null,
        title: postTitle,
        content: postContent,
        excerpt: postExcerpt,
        featured_image: featuredImage
      });
      toast({
        title: "Success!",
        description: `Post submitted to WordPress successfully.`,
        variant: "success",
      });
      setIsExpanded(false);
    } catch (error) {
      console.error('Error posting to WordPress:', error);
      toast({
        title: "Error",
        description: `Failed to post to WordPress. Please try again.`,
        variant: "destructive",
      });
    }
  };

  const handleProductWordPressPost = async () => {
    try {
      const listingDetails = {
        name: productName,
        type: productType,
        regular_price: regularPrice,
        sale_price: salePrice,
        longDescription: longDescription,
        short_description: shortDescription,
        categories: categories,
        tags: tags,
        images: images,
        manage_stock: manageStock,
        stock_quantity: stockQuantity,
        status: status,
        downloadable: downloadable,
        virtual: virtual,
        external_url: externalUrl,
        upsell_ids: upsellIds,
        cross_sell_ids: crossSellIds,
        downloads: downloads
      };
  
      // Add meta_data if forTutorLMS is true
      if (forTutorLMS) {
        listingDetails.meta_data = [
          {
            key: "_tutor_product",
            value: "yes"
          }
        ];
      }

      await postProductToWordpress(id, {
        wordpressSiteUrl: productWordpressSiteUrl,
        listingDetails: listingDetails,
      });

      toast({
        title: 'Success!',
        description: `Product submitted to WordPress successfully.`,
        variant: 'success',
      });
      setIsExpanded(false);
    } catch (error) {
      console.error('Error posting product to WordPress:', error);
      toast({
        title: 'Error',
        description: `Failed to post product to WordPress. Please try again.`,
        variant: 'destructive',
      });
    }
  }; 

  const handleCourseTutorLMSPost = async () => {
    try {
      const courseDetails = {
        post_title: courseTitle,
        post_content: courseContent,
        post_excerpt: courseExcerpt,
        course_categories: courseCategories,
        course_tags: courseTags,
        course_level: courseLevel,
        post_status: courseStatus,
        thumbnail_id: courseThumbnail,
        additional_content: additionalContent,
        video: courseVideo,
      };
  
      await postCourseToTutorLMS(id, {
        wordpressSiteUrl: courseTutorLMSSiteUrl,
        courseDetails: courseDetails,
      });
  
      toast({
        title: 'Success!',
        description: `Course submitted to TutorLMS successfully.`,
        variant: 'success',
      });
      setIsExpanded(false);
    } catch (error) {
      console.error('Error posting course to TutorLMS:', error);
      toast({
        title: 'Error',
        description: `Failed to post course to TutorLMS. Please try again.`,
        variant: 'destructive',
      });
    }
  };

  const handleAction = async () => {
    if (actionType === ActionStepType.POST_TO_WORDPRESS || actionType === ActionStepType.POST_PRODUCT_TO_WORDPRESS || actionType === ActionStepType.POST_COURSE_TO_TUTORLMS) {
      setIsExpanded(true);
    } else {
      try {
        await config.action();
        toast({
          title: "Success!",
          description: `${config.text} action completed successfully.`,
          variant: "success",
        });
      } catch (error) {
        console.error('Error performing action:', error);
        toast({
          title: "Error",
          description: `Failed to ${config.text.toLowerCase()}. Please try again.`,
          variant: "destructive",
        });
      }
    }
  };

  const renderForm = () => {
    switch (actionType) {
      case ActionStepType.POST_TO_WORDPRESS:
        return (
          <WordPressPostForm
            wordpressSiteUrl={wordpressSiteUrl}
            setWordpressSiteUrl={setWordpressSiteUrl}
            postStatus={postStatus}
            setPostStatus={setPostStatus}
            publishDate={publishDate}
            setPublishDate={setPublishDate}
            handleWordPressPost={handleWordPressPost}
            config={config}
            setIsExpanded={setIsExpanded}
            postTitle={postTitle}
            setPostTitle={setPostTitle}
            postContent={postContent}
            setPostContent={setPostContent}
            postExcerpt={postExcerpt}
            setPostExcerpt={setPostExcerpt}
            featuredImage={featuredImage}
            setFeaturedImage={setFeaturedImage}
          />
        );
      case ActionStepType.POST_PRODUCT_TO_WORDPRESS:
        return (
          <WordPressProductForm
            productWordpressSiteUrl={productWordpressSiteUrl}
            setProductWordpressSiteUrl={setProductWordpressSiteUrl}
            productName={productName}
            setProductName={setProductName}
            status={status}
            setStatus={setStatus}
            regularPrice={regularPrice}
            setRegularPrice={setRegularPrice}
            salePrice={salePrice}
            setSalePrice={setSalePrice}
            longDescription={longDescription}
            setLongDescription={setLongDescription}
            shortDescription={shortDescription}
            setShortDescription={setShortDescription}
            downloadable={downloadable}
            setDownloadable={setDownloadable}
            virtual={virtual}
            setVirtual={setVirtual}
            forTutorLMS={forTutorLMS}
            setForTutorLMS={setForTutorLMS}
            externalUrl={externalUrl}
            setExternalUrl={setExternalUrl}
            isMoreDetailsOpen={isMoreDetailsOpen}
            setIsMoreDetailsOpen={setIsMoreDetailsOpen}
            productType={productType}
            setProductType={setProductType}
            manageStock={manageStock}
            setManageStock={setManageStock}
            stockQuantity={stockQuantity}
            setStockQuantity={setStockQuantity}
            handleProductWordPressPost={handleProductWordPressPost}
            config={config}
            setIsExpanded={setIsExpanded}
            images={images}
            setImages={setImages}
            categories={categories}
            setCategories={setCategories}
            tags={tags}
            setTags={setTags}
            upsellIds={upsellIds}
            setUpsellIds={setUpsellIds}
            crossSellIds={crossSellIds}
            setCrossSellIds={setCrossSellIds}
            downloads={downloads}
            setDownloads={setDownloads}
          />
        );
      case ActionStepType.POST_COURSE_TO_TUTORLMS:
        return (
          <TutorLMSCourseForm
            courseTutorLMSSiteUrl={courseTutorLMSSiteUrl}
            setCourseTutorLMSSiteUrl={setCourseTutorLMSSiteUrl}
            courseTitle={courseTitle}
            setCourseTitle={setCourseTitle}
            courseContent={courseContent}
            setCourseContent={setCourseContent}
            courseExcerpt={courseExcerpt}
            setCourseExcerpt={setCourseExcerpt}
            courseLevel={courseLevel}
            setCourseLevel={setCourseLevel}
            courseStatus={courseStatus}
            setCourseStatus={setCourseStatus}
            handleCourseTutorLMSPost={handleCourseTutorLMSPost}
            config={config}
            setIsExpanded={setIsExpanded}
            savedCourse={savedCourse}
            courseThumbnail={courseThumbnail}
            setCourseThumbnail={setCourseThumbnail}
            courseCategories={courseCategories}
            setCourseCategories={setCourseCategories}
            courseTags={courseTags}
            setCourseTags={setCourseTags}
            additionalContent={additionalContent}
            setAdditionalContent={setAdditionalContent}
            courseVideo={courseVideo}
            setCourseVideo={setCourseVideo}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {!isExpanded ? (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button
              onClick={handleAction}
              className={`${config.color} text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105`}
            >
              <FontAwesomeIcon icon={config.icon} className="mr-2 h-5 w-5" />
              {config.text}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent className="z-[80]">
            <AlertDialogHeader>
              <AlertDialogTitle>Confirm Action</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to {config.text.toLowerCase()}? This action cannot be undone.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleAction}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      ) : (
        renderForm()
      )}
    </div>
  );
};

export default ActionButton;