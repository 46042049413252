import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../services/api';

export const fetchSchedules = createAsyncThunk('schedules/fetchSchedules', async () => {
  const response = await api.fetchSchedules();
  return response.data;
});

export const createSchedule = createAsyncThunk(
  'schedules/createSchedule',
  async (scheduleData, { getState }) => {
    const response = await api.createSchedule(scheduleData);
    const newSchedule = response.data;
    
    // Fetch the full flow object from the state
    const state = getState();
    const flow = state.flows.flows.find(f => f._id === newSchedule.flow);

    // Attach the full flow object to the new schedule
    return { ...newSchedule, flow };
  }
);

export const updateSchedule = createAsyncThunk('schedules/updateSchedule', async ({ id, scheduleData }) => {
  const response = await api.updateSchedule(id, scheduleData);
  return response.data;
});

export const deleteSchedule = createAsyncThunk('schedules/deleteSchedule', async (id) => {
  await api.deleteSchedule(id);
  return id;
});

export const activateSchedule = createAsyncThunk('schedules/activateSchedule', async (id) => {
  const response = await api.activateSchedule(id);
  return response.data;
});

export const deactivateSchedule = createAsyncThunk('schedules/deactivateSchedule', async (id) => {
  const response = await api.deactivateSchedule(id);
  return response.data;
});

export const fetchSchedulesByFlow = createAsyncThunk('schedules/fetchSchedulesByFlow', async (flowId) => {
  const response = await api.fetchSchedulesByFlow(flowId);
  return response.data;
});

const schedulesSlice = createSlice({
  name: 'schedules',
  initialState: {
    schedules: [],
    selectedSchedule: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setSelectedSchedule: (state, action) => {
      state.selectedSchedule = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchedules.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSchedules.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.schedules = action.payload;
      })
      .addCase(fetchSchedules.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createSchedule.fulfilled, (state, action) => {
        state.schedules.push(action.payload);
      })
      .addCase(updateSchedule.fulfilled, (state, action) => {
        const index = state.schedules.findIndex(schedule => schedule._id === action.payload._id);
        if (index !== -1) {
          state.schedules[index] = action.payload;
        }
        if (state.selectedSchedule && state.selectedSchedule._id === action.payload._id) {
          state.selectedSchedule = action.payload;
        }
      })
      .addCase(deleteSchedule.fulfilled, (state, action) => {
        state.schedules = state.schedules.filter(schedule => schedule._id !== action.payload);
        if (state.selectedSchedule && state.selectedSchedule._id === action.payload) {
          state.selectedSchedule = null;
        }
      })
      .addCase(activateSchedule.fulfilled, (state, action) => {
        const updatedSchedule = action.payload;
        state.schedules = state.schedules.map(schedule => 
          schedule._id === updatedSchedule._id ? updatedSchedule : schedule
        );
        if (state.selectedSchedule && state.selectedSchedule._id === updatedSchedule._id) {
          state.selectedSchedule = updatedSchedule;
        }
      })
      .addCase(deactivateSchedule.fulfilled, (state, action) => {
        const updatedSchedule = action.payload;
        state.schedules = state.schedules.map(schedule => 
          schedule._id === updatedSchedule._id ? updatedSchedule : schedule
        );
        if (state.selectedSchedule && state.selectedSchedule._id === updatedSchedule._id) {
          state.selectedSchedule = updatedSchedule;
        }
      })
      .addCase(fetchSchedulesByFlow.fulfilled, (state, action) => {
        state.schedules = action.payload;
      });
  },
});

export const { setSelectedSchedule } = schedulesSlice.actions;

export default schedulesSlice.reducer;