// src/pages/FlowFormComponents/RenderFields/ManualQuizzesInput.js

import React, { useState, useEffect } from "react";
import { Label } from "../../../../components/ui/label";
import { Textarea } from "../../../../components/ui/textarea";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { Tooltip } from "../../../../components/ui/tooltip";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../../../components/ui/select";
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "../../../../components/ui/collapsible";

// Import FontAwesomeIcon and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPlus,
  faTrash,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

const feedbackModeOptions = [
  { value: "IMMEDIATE", label: "Immediate" },
  { value: "DELAYED", label: "Delayed" },
  { value: "NONE", label: "None" },
];

const ManualQuizzesInput = ({ quizzes, topics, handleFieldChange, onStepChange }) => {
  const [localQuizzes, setLocalQuizzes] = useState(quizzes || []);
  const [collapsedQuizzes, setCollapsedQuizzes] = useState({});

  useEffect(() => {
    // Include quiz_id in quizzes before passing to parent
    const quizzesWithIds = localQuizzes.map((quiz, index) => ({
      ...quiz,
      quiz_id: index + 1,
    }));
    handleFieldChange('courseData.quizzes', quizzesWithIds, onStepChange);
  }, [localQuizzes]);
  

  const addQuiz = () => {
    const newQuizzes = [
      ...localQuizzes,
      {
        topic_id: "",
        quiz_title: "",
        quiz_description: "",
        quiz_options: {
          time_limit: {
            time_value: "",
          },
          feedback_mode: "IMMEDIATE",
          question_layout_view: "",
          attempts_allowed: "",
          passing_grade: "",
          max_questions_for_answer: "",
          questions_order: "",
          short_answer_characters_limit: "",
          open_ended_answer_characters_limit: "",
        },
      },
    ];
    setLocalQuizzes(newQuizzes);
  };

  const removeQuiz = (index) => {
    const newQuizzes = [...localQuizzes];
    newQuizzes.splice(index, 1);
    setLocalQuizzes(newQuizzes);
  };

  const handleQuizChange = (index, field, value) => {
    setLocalQuizzes((prevQuizzes) => {
      // Create a new array of quizzes
      const newQuizzes = prevQuizzes.map((quiz, i) => {
        if (i === index) {
          // Return a new quiz object with the updated field
          return { ...quiz, [field]: value };
        }
        return quiz;
      });
      return newQuizzes;
    });
  };
  

  const handleQuizOptionsChange = (index, field, value) => {
    setLocalQuizzes((prevQuizzes) => {
      const newQuizzes = prevQuizzes.map((quiz, i) => {
        if (i === index) {
          return {
            ...quiz,
            quiz_options: {
              ...quiz.quiz_options,
              [field]: value,
            },
          };
        }
        return quiz;
      });
      return newQuizzes;
    });
  };
  

  const toggleCollapse = (index) => {
    setCollapsedQuizzes({
      ...collapsedQuizzes,
      [index]: !collapsedQuizzes[index],
    });
  };

  return (
    <div className="space-y-6">
      {localQuizzes.map((quiz, index) => {
        // Automatically set quiz_id
        const updatedQuiz = { ...quiz, quiz_id: index + 1 };
  
        return (
          <Collapsible
            key={index}
            open={!collapsedQuizzes[index]}
            className="bg-gray-200 p-4 rounded-md shadow-sm"
          >
            <CollapsibleTrigger className="flex justify-between items-center w-full py-2">
              <Label className="text-inter font-bold">
                Quiz {index + 1}: ID {updatedQuiz.quiz_id}
              </Label>
              <div className="flex items-center space-x-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => toggleCollapse(index)}
                >
                  <FontAwesomeIcon
                    icon={collapsedQuizzes[index] ? faChevronDown : faChevronUp}
                  />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeQuiz(index)}
                >
                  <FontAwesomeIcon icon={faTrash} className="text-red-500" />
                </Button>
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div className="space-y-4 mt-4">
                {/* Display Quiz ID
                <div>
                  <Label className="text-inter font-bold">
                    Quiz ID: {updatedQuiz.quiz_id}
                  </Label>
                </div> */}
                {/* Topic ID Select and Quiz Title */}
                <div className="flex space-x-4">
                  {/* Topic ID Select */}
                  <div className="w-1/2">
                    <Label className="text-inter font-bold">
                      Topic ID
                      <Tooltip content="Select the associated topic ID">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="inline-block w-4 h-4 ml-1 text-gray-400"
                        />
                      </Tooltip>
                    </Label>
                    <Select
                      value={updatedQuiz.topic_id}
                      onValueChange={(value) =>
                        handleQuizChange(index, 'topic_id', value)
                      }
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select topic ID" />
                      </SelectTrigger>
                      <SelectContent>
                        {topics.map((topic) => (
                          <SelectItem key={topic.topic_id} value={topic.topic_id}>
                            {topic.topic_id}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  {/* Quiz Title */}
                  <div className="w-1/2">
                    <Label
                      htmlFor={`quiz-title-${index}`}
                      className="text-inter font-bold"
                    >
                      Quiz Title
                      <Tooltip content="Enter the title of the quiz">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="inline-block w-4 h-4 ml-1 text-gray-400"
                        />
                      </Tooltip>
                    </Label>
                    <Input
                      id={`quiz-title-${index}`}
                      placeholder="Enter quiz title"
                      value={updatedQuiz.quiz_title}
                      onChange={(e) =>
                        handleQuizChange(index, 'quiz_title', e.target.value)
                      }
                    />
                  </div>
                </div>
                {/* Quiz Description */}
                <div>
                  <Label
                    htmlFor={`quiz-description-${index}`}
                    className="text-inter font-bold"
                  >
                    Quiz Description
                    <Tooltip content="Provide a description for the quiz">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                  <Textarea
                    id={`quiz-description-${index}`}
                    placeholder="Enter quiz description"
                    value={updatedQuiz.quiz_description}
                    onChange={(e) =>
                      handleQuizChange(index, 'quiz_description', e.target.value)
                    }
                  />
                </div>
                {/* Quiz Options */}
                <div className="space-y-4">
                  <Label className="text-inter font-bold">
                    Quiz Options
                    <Tooltip content="Configure the quiz options">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="inline-block w-4 h-4 ml-1 text-gray-400"
                      />
                    </Tooltip>
                  </Label>
                  {/* Time Limit */}
                  <div>
                    <Label
                      htmlFor={`time-limit-${index}`}
                      className="text-inter"
                    >
                      Time Limit (minutes)
                      <Tooltip content="Set the time limit for the quiz in minutes">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="inline-block w-4 h-4 ml-1 text-gray-400"
                        />
                      </Tooltip>
                    </Label>
                    <Input
                      id={`time-limit-${index}`}
                      placeholder="Enter time limit"
                      value={updatedQuiz.quiz_options?.time_limit?.time_value || ''}
                      onChange={(e) =>
                        handleQuizOptionsChange(index, 'time_limit', {
                          ...updatedQuiz.quiz_options?.time_limit,
                          time_value: e.target.value,
                        })
                      }
                    />
                  </div>
                  {/* Feedback Mode */}
                  <div>
                    <Label className="text-inter">Feedback Mode</Label>
                    <Select
                      value={updatedQuiz.quiz_options?.feedback_mode || ''}
                      onValueChange={(value) =>
                        handleQuizOptionsChange(index, 'feedback_mode', value)
                      }
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select feedback mode" />
                      </SelectTrigger>
                      <SelectContent>
                        {feedbackModeOptions.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  {/* Attempts Allowed */}
                  <div>
                    <Label
                      htmlFor={`attempts-allowed-${index}`}
                      className="text-inter"
                    >
                      Attempts Allowed
                      <Tooltip content="Specify the number of attempts allowed">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="inline-block w-4 h-4 ml-1 text-gray-400"
                        />
                      </Tooltip>
                    </Label>
                    <Input
                      id={`attempts-allowed-${index}`}
                      placeholder="Enter attempts allowed"
                      value={updatedQuiz.quiz_options?.attempts_allowed || ''}
                      onChange={(e) =>
                        handleQuizOptionsChange(
                          index,
                          'attempts_allowed',
                          e.target.value
                        )
                      }
                    />
                  </div>
                  {/* Passing Grade */}
                  <div>
                    <Label
                      htmlFor={`passing-grade-${index}`}
                      className="text-inter"
                    >
                      Passing Grade (%)
                      <Tooltip content="Set the passing grade percentage">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="inline-block w-4 h-4 ml-1 text-gray-400"
                        />
                      </Tooltip>
                    </Label>
                    <Input
                      id={`passing-grade-${index}`}
                      placeholder="Enter passing grade"
                      value={updatedQuiz.quiz_options?.passing_grade || ''}
                      onChange={(e) =>
                        handleQuizOptionsChange(
                          index,
                          'passing_grade',
                          e.target.value
                        )
                      }
                    />
                  </div>
                  {/* Additional quiz options can be added similarly */}
                </div>
              </div>
            </CollapsibleContent>
          </Collapsible>
        );
      })}
      <Button
        type="button"
        onClick={addQuiz}
        variant="outline"
        className="mt-2 bg-green-100 border-green-500 text-gray-900 hover:bg-green-200 hover:border-green-600 hover:text-green-600"
      >
        <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add Quiz
      </Button>
    </div>
  );
  
  
};

export default ManualQuizzesInput;
